import { memo, useEffect, useMemo, useRef, useState } from "react";
import { Dialog } from "@mui/material";
import { CellClickedEvent, ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import { Box, HStack, useGetCompanyName } from "../../components/utils";
import { tableCellStyle } from "../../components/TableComponent";
import SearchInput from "../../components/shared/SearchInput";
import GenericTableHeader from "../../shared/TableHeader";
import {
  exportCaptableSummaryReport,
  exportRoundReport,
  exportSecurityBasedReport,
  exportRoundSummaryReport,
  exportSecondarySummaryReport,
  exportSharecertificateReport,
  exportShareholderReport,
  exportIndividualSecuritySummaryReport,
  exportSecuritySummaryReport,
  exportAOCReport,
  exportMGT7Report,
  exportFla,
} from "../../api/Report";
import {
  ReportsComponent,
  DownloadComponent,
  ReportNameComponent,
  DateComponent,
  DownloadIconComponent,
} from "./ReportsComponent";
import { downloadExcel } from "../../utils/DownloadFile";
import {
  getOwnershipOverViewReport,
  getScenarioCaptableSummaryReport,
} from "../../api/Reports";
import { useAuthStore } from "../../store/useAuthStore";

import ExportMultidateSheetDetails from "../../modals/ExportMultidateSheetDetails";
import useIsMobile from "../../utils/detectDevice";
import ExportSecurityTypeReport from "./ExportSecurityTypeReport";
import ExportTransactionTypeReport from "./ExportTransactionTypeReport";
import EmptyTableIllustration from "../../shared/EmptyTableIllustration";
import { getCurrencyType } from "../../utils/currencyFormatter";

export interface ReportsTableModelProps {
  id: string;
  from?: string;
  reportName: string;
  key: string;
  reportType: string;
  reportDescription: string;
  date?: string | null;
  disabled: boolean;
}

function ReportsAgTable() {
  const isMobile = useIsMobile();
  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: string;
    type?: string;
  }>({
    open: false,
  });
  const companyName = useGetCompanyName();
  let fileName;
  const { companyId: _companyId, user } = useAuthStore();
  const companyId = _companyId || "";
  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const selectedRowIndex = cellParams.node.rowIndex || 0;
      const template = rowData[selectedRowIndex];
      handleActions(template);
    }
  };
  async function handleActions(action: ReportsTableModelProps) {
    if (action.disabled) return;

    if (action.key === "Security Based Report") {
      await exportSecurityBasedReport("").then((res) => {
        fileName = `Security Based Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.key === "AOC4 Report") {
      exportAOCReport().then((res) => {
        const fileName = `AOC4 Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.key === "MGT7 Report") {
      exportMGT7Report().then((res) => {
        const fileName = `MGT7 Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.key === "Foreign Liabilities And Assets") {
      exportFla().then((res) => {
        const fileName = `Forign Liabilities And Assets_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.key === "Round Based Report") {
      await exportRoundReport("").then((res) => {
        fileName = `Round Based Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.key === "Export Summary Report") {
      setDialog({
        open: true,
        data: "Export Summary Report",
        type: "Export Summary Report",
      });
    } else if (action.key === "Allotment Window Report") {
      setDialog({
        open: true,
        data: "Allotment Window Report",
        type: "Allotment Window Report",
      });
    } else if (action.key === "Ownership Detailed Report") {
      await getOwnershipOverViewReport("").then((res) => {
        fileName = `Ownership Detailed Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.key === "Round Summary Report") {
      exportRoundSummaryReport("").then((res) => {
        const fileName = `Round Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.key === "Secondary Summary Report") {
      exportSecondarySummaryReport().then((res) => {
        const fileName = `Secondary Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.key === "All Transaction Summary Report") {
      setDialog({
        open: true,
        data: "All Transaction Summary Report",
        type: "All Transaction Summary Report",
      });
    } else if (action.key === "Export Share Certificate Data") {
      exportSharecertificateReport().then((res) => {
        const fileName = `Sharecertificate Report_${companyName}`;
        downloadExcel(res.base64, fileName);
      });
    } else if (action.key === "Export Shareholder Cap Table") {
      exportShareholderReport().then((res) => {
        const fileName = `Shareholder Cap Table Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.key === "Export Security Summary Report") {
      exportSecuritySummaryReport().then((res) => {
        const fileName = `Security Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.key === "Export Details Report") {
      exportSecurityBasedReport("").then((res) => {
        const fileName = `Security Details Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.key === "Export Security Type Report") {
      setDialog({
        open: true,
        data: "Export Security Type Report",
        type: "Export Security Type Report",
      });
    } else if (action.key === "Export PAS4 Report") {
      setDialog({
        open: true,
        data: "Export PAS4 Report",
        type: "Export PAS4 Report",
      });
    } else if (action.key === "Export Specific Round Summary Report") {
      setDialog({
        open: true,
        data: "Export Specific Round Summary Report",
        type: "Export Specific Round Summary Report",
      });
    }
  }

  const gridApi = useRef<any>(null);
  const [displayedRowCount, setDisplayedRowCount] = useState(0);
  useEffect(() => {
    const displayedRow = gridApi.current?.api.getDisplayedRowCount();
    setDisplayedRowCount(displayedRow);
  }, [gridApi.current?.api.getDisplayedRowCount()]);

  const componentsRegistery = useMemo(
    () => ({
      reportName: memo(ReportNameComponent),
      reportType: memo(ReportsComponent),
      date: memo(DateComponent),
      link: isMobile.isMobile
        ? memo(DownloadIconComponent)
        : memo(DownloadComponent),
    }),
    []
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 100,
      filter: true,
      resizable: true,
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        getQuickFilterText: (params) => params.data.reportDescription,
        headerName: "",
        field: "reportType",
        pinned: "left",
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        hide: isMobile.isMobile,
        flex: 1,
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { paddingTop: "10px", lineHeight: "20px" },
        resizable: false,
        sortable: false,
        cellRenderer: "reportType",
      },
      {
        headerName: "Report Name",
        field: "reportName",
        filter: "agMultiColumnFilter",
        cellRenderer: "reportName",
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        flex: 1,
      },
      {
        headerName: `Date`,
        field: "date",
        filter: "agDateColumnFilter",
        cellRenderer: "date",
        cellStyle: tableCellStyle,
        sortable: true,
        pinned: "right",
        flex: 1,
        hide: isMobile.isMobile,
        initialWidth: 150,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: `Action`,
        field: "link",
        pinned: "right",
        filter: "agStringColumnFilter",
        cellRenderer: "link",
        cellStyle: tableCellStyle,
        flex: 1,
        width: isMobile.isMobile ? 80 : 150,
        maxWidth: isMobile.isMobile ? 80 : 150,
        menuTabs: ["filterMenuTab"],
      },
    ],
    []
  );
  const currencyType = getCurrencyType();
  const rowData: ReportsTableModelProps[] = useMemo(
    () => [
      {
        id: "999",
        reportName: "AOC4 Report",
        reportType: "excel",
        from: "captable",
        key: "AOC4 Report",
        reportDescription: "A report on the AOC4 Report",
        date: null,
        disabled: currencyType !== "en-IN",
      },
      {
        id: "998",
        reportName: "MGT7 Report",
        reportType: "excel",
        from: "captable",
        key: "MGT7 Report",
        reportDescription: "A report on the MGT7 Report",
        date: null,
        disabled: false,
      },
      {
        id: "1",
        reportName: "Security Based Report",
        reportType: "excel",
        from: "captable",
        key: "Security Based Report",
        reportDescription: "A report on the Security Based Report",
        date: null,
        disabled: false,
      },
      //Dirty Commit {
      //   id: "2",
      //   reportName: "Round Based Report",
      //   key: "Round Based Report",
      //   reportType: "excel",
      //   from: "captable",
      //   reportDescription: "A report on the rounds",
      //   date: null,
      //   disabled: false,
      // },
      {
        id: "3",
        reportName: "Export Summary Report",
        key: "Export Summary Report",
        reportType: "excel",
        from: "captable",
        reportDescription: "A report on the captable summary",
        date: null,
        disabled: false,
      },
      {
        id: "4",
        reportName: "Allotment Window Report",
        key: "Allotment Window Report",
        reportType: "excel",
        from: "captable",
        reportDescription: "A report on the Allotment Window",
        date: null,
        disabled: false,
      },
      {
        id: "4",
        reportName: "Ownership Detailed Report",
        key: "Ownership Detailed Report",
        reportType: "excel",
        from: "captable",
        reportDescription: "A report on the ownership detailed",
        date: null,
        disabled: false,
      },
      {
        id: "5",
        reportName: "Round Summary Report",
        key: "Round Summary Report",
        reportType: "excel",
        from: "transactions",
        reportDescription: "A report on the round summary",
        date: null,
        disabled: false,
      },
      {
        id: "6",
        reportName: "Secondary Summary Report",
        key: "Secondary Summary Report",
        reportType: "excel",
        from: "transactions",
        reportDescription: "A report on the secondary summary",
        date: null,
        disabled: false,
      },
      {
        id: "7",
        reportName: "All Transaction Summary Report",
        key: "All Transaction Summary Report",
        reportType: "excel",
        from: "transactions",
        reportDescription: "A report on the all transaction summary",
        date: null,
        disabled: false,
      },
      {
        id: "8",
        reportName: "Export Share Certificate Data",
        key: "Export Share Certificate Data",
        reportType: "excel",
        from: "shareholder",
        reportDescription: "A report on the  share certificate Data",
        date: null,
        disabled: false,
      },
      {
        id: "9",
        reportName: "Export Shareholder Cap Table",
        key: "Export Shareholder Cap Table",
        reportType: "excel",
        from: "shareholder",
        reportDescription: "A report on the shareholder cap table",
        date: null,
        disabled: false,
      },

      {
        id: "10",
        reportName: "Export Security Summary Report",
        key: "Export Security Summary Report",
        reportType: "excel",
        from: "security",
        reportDescription: "A report on the security summary report",
        date: null,
        disabled: false,
      },
      {
        id: "11",
        reportName: "Export Security Details Report",
        key: "Export Shareholder Cap Table",
        reportType: "excel",
        from: "security",
        reportDescription: "A report on the security details",
        date: null,
        disabled: false,
      },
      {
        id: "12",
        reportName: "Export Security Type Report",
        key: "Export Security Type Report",
        reportType: "excel",
        from: "security",
        reportDescription: "A report on the Security Type",
        date: null,
        disabled: false,
      },
      {
        id: "12",
        reportName: "Export PAS4 Report",
        key: "Export PAS4 Report",
        reportType: "excel",
        from: "round",
        reportDescription: "A report on the PAS4",
        date: null,
        disabled: false,
      },
      {
        id: "12",
        reportName: "Export Specific Round Summary Report",
        key: "Export Specific Round Summary Report",
        reportType: "excel",
        from: "round",
        reportDescription: "A report on the specific round summary report",
        date: null,
        disabled: false,
      },
      // Prod Hide
      //  {
      //   id: "13",
      //   reportName: "Foreign Liabilities And Assets",
      //   key: "Foreign Liabilities And Assets",
      //   reportType: "excel",
      //   from: "captable",
      //   reportDescription: "A report on Foreign Liabilities and Assets",
      //   date: null,
      //   disabled: false,
      // },
    ],
    []
  );

  return (
    <div className="bg-white border rounded-md border-borderColor shadow-box">
      <Dialog open={dialog.open} maxWidth="md">
        {dialog.type === "Allotment Window Report" && (
          <ExportMultidateSheetDetails
            reportName={dialog.data || ""}
            companyId={companyId}
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        )}
        {dialog.type === "All Transaction Summary Report" && (
          <ExportMultidateSheetDetails
            reportName={"All Transaction Summary Report" || ""}
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        )}
        {dialog.type === "Export Security Type Report" && (
          <ExportSecurityTypeReport
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => setDialog({ open: false })}
            reportName={"Export Security Type Report"}
          />
        )}
        {dialog.type === "Export PAS4 Report" && (
          <ExportTransactionTypeReport
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => setDialog({ open: false })}
            reportName={"Export PAS4 Report"}
          />
        )}
        {dialog.type === "Export Summary Report" && (
          <ExportMultidateSheetDetails
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => setDialog({ open: false })}
            reportName={"Export Summary Report"}
          />
        )}
        {dialog.type === "Export Specific Round Summary Report" && (
          <ExportTransactionTypeReport
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => setDialog({ open: false })}
            reportName={"Export Specific Round Summary Report"}
          />
        )}
      </Dialog>
      <HStack className="flex-col items-start justify-start py-4 md:flex-row md:justify-between md:items-center ">
        <GenericTableHeader heading={"Reports"} subHeading={""} />
        <HStack className="items-center justify-between w-full gap-4 px-6 mt-2 md:w-auto lg:justify-end md:px-8 ">
          {rowData.length > 0 && (
            <SearchInput
              placeholder={`Search`}
              onChange={(e: any) => {
                gridApi.current.api.setQuickFilter(e.target.value);
              }}
            />
          )}
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: rowData.length * 85,
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material"
        >
          {rowData.length > 0 ? (
            <AgGridReact
              sideBar={agConfigDashboardSideBar}
              onGridReady={(params) => {
                // Store the grid API referen
                gridApi.current = params;
              }}
              suppressScrollOnNewData={true}
              alwaysShowHorizontalScroll={false}
              alwaysShowVerticalScroll={false}
              components={componentsRegistery}
              alwaysMultiSort
              animateRows={true}
              defaultColDef={defaultColDef}
              rowData={rowData}
              columnDefs={columnDefs}
              pagination={false}
              onCellClicked={handleCellClick}
              suppressRowTransform={true}
              suppressCopyRowsToClipboard={true}
              suppressCopySingleCellRanges={true}
              suppressCellFocus={true}
              suppressMenuHide={false}
              tooltipShowDelay={1000}
              tooltipInteraction={true}
              rowClass={"border-t border-dashed "}
              getRowStyle={(params) => {
                if (params.rowIndex % 2 === 0) {
                  return { background: "#f8f8f8" };
                } else {
                  return { background: "#ffffff" };
                }
              }}
            ></AgGridReact>
          ) : (
            <EmptyTableIllustration text={"No Reports available to display"} />
          )}
        </Box>
      </HStack>
    </div>
  );
}

export default ReportsAgTable;
