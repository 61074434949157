import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import {
  CellClickedEvent,
  ColDef,
  SideBarDef,
  GridApi,
} from "ag-grid-community";
import { Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { AllTransactionModel } from "../../types/AllTransactionModel";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  TransactionNameRender,
  SecurityTypeRender,
  SecurityClassRender,
  DateOfTransactionRender,
  LeadInvestorRender,
  NumberOfSharesRender,
  PPSRender,
  PaymentTypeRender,
  AmountRender,
  SplitRatioRender,
  BonusRatioRender,
  RenderCount,
  CompanyNameRender,
  ValuationRender,
  DilutionRender,
  TransactionPriceRender,
} from "./TransactionAGComponent";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import {
  useGetOnFilterState,
  useGetTableFilterState,
  usePostOnFilterState,
  usePostTableFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { useAuthStore } from "../../store/useAuthStore";
import { Box, HStack, VStack, useGetCompanyName } from "../../components/utils";
import { checkTypeOfRound } from "./RoundsUtility";
import QuickSearch from "./QuickSearch";
import "../../styles/reset.css";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import GenericTableHeader from "../../shared/TableHeader";
import { tableCellStyle } from "../../components/TableComponent";
import {
  exportRoundSummaryReport,
  exportSecondarySummaryReport,
} from "../../api/Report";
import { downloadExcel } from "../../utils/DownloadFile";
import { ExportImport } from "../Utility/GrantsTable";
import ExportMultidateSheetDetails from "../../modals/ExportMultidateSheetDetails";
import { useAgGridTableState } from "../../store/agGridTableStore";
import SearchInput from "../../components/shared/SearchInput";
import { handleEventForTracking } from "../../amplitudeAnalytics";
import { compareTransactions } from "../../utils/transactionUtils";
import { ValuationPPSTooltip } from "../dashboardPage/customTooltip";
import UpdateConversionPopUp from "./UpdateConversionPopUp";
import { formatDisplayDate } from "../../utils/date";
import { TransactionDocPopup } from "../newQuickTransaction/TransactionDocPopup";
import { useGetTransactionDocuments } from "../../queries/transactionRound";
import { TableId } from "../../constants/TableIdConstants";
import { FilterConditionModel } from "../dashboardPage/AgGridCache";
import { useTableStateManagement } from "./TableHook";

function AGGridTransactionTable({
  cid,
  token,
  transactionTableData,
}: {
  cid: string;
  token: string;
  transactionTableData: AllTransactionModel[];
}) {
  const currencySymbol = getCurrencySymbol();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const companyName = useGetCompanyName();
  const [dialog, setDialog] = useState<{
    open: boolean;
    name?:
      | "Update Conversion Details"
      | "All Transaction Summary Report"
      | "Transaction Documents"
      | "";
    data?: string;
  }>({
    name: "",
    open: false,
  });

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();
  // const {
  //   filterCondition,
  //   setFilterCondition,
  //   isSaving,
  //   uploadFilter,
  //   uploadColumn,
  // } = useTableStateManagement(gridApi, TableId.allTansactionTable);

  const componentsRegistery = useMemo(
    () => ({
      companyName: memo(CompanyNameRender),
      renderCount: memo(RenderCount),
      transactionName: memo(TransactionNameRender),
      dateOfTransaction: memo(DateOfTransactionRender),
      splitRatio: memo(SplitRatioRender),
      bonusRatio: memo(BonusRatioRender),
      securityClass: memo(SecurityClassRender),
      securityType: memo(SecurityTypeRender),
      numberOfShares: memo(NumberOfSharesRender),
      pps: memo(PPSRender),
      amount: memo(AmountRender),
      paymentType: memo(PaymentTypeRender),
      leadInvestor: memo(LeadInvestorRender),
      valuation: memo(ValuationRender),
      dilution: memo(DilutionRender),
      transactionPrice: memo(TransactionPriceRender),
    }),
    []
  );

  const navigate = useNavigate();

  const onClickTransactionName = (transactionType: string, id: string) =>
    transactionType.toLocaleLowerCase() !== "conversion" &&
    transactionType.toLocaleLowerCase() !== "redemption" &&
    transactionType.toLocaleLowerCase() !== "update_class" &&
    transactionType.toLocaleLowerCase() !== "esoppool" &&
    transactionType.toLocaleLowerCase() !== "forfeiture"
      ? cid
        ? navigate(`/inv/transaction/${transactionType}/${id}/${cid}/${token}`)
        : navigate(`/transactions/${transactionType}/${id}`)
      : null;

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Name",
        field: "transactionName",
        filter: "agMultiColumnFilter",
        getQuickFilterText: (params) => params.value.name,
        comparator(valueA, valueB, ..._: any[]) {
          return valueA.name > valueB.name ? 1 : -1;
        },
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Round Name",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.name,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) =>
                  params.getValue("transactionName").name,
              },
            },
            {
              title: "Round Type",
              filter: "agSetColumnFilter",
              display: "subMenu",
              buttons: ["reset"],
              filterParams: {
                buttons: ["reset"],
                keyCreator: (params: any) => {
                  const transactionType = params.value.type;
                  return transactionType;
                },
                valueFormatter: (params: any) => {
                  const transactionType = params.value.type;
                  return transactionType;
                },
              },
            },
          ],
        },
        pinned: "left",
        cellRenderer: "transactionName",
        initialWidth: 300,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipTransactionName",
      },

      {
        headerName: "Date",
        field: "dateOfTransaction",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "dateOfTransaction",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Security Type",
        field: "securityType",
        cellRenderer: "securityType",
        cellStyle: tableCellStyle,
        sortable: false,
        valueGetter: (params) =>
          params.data.securityType?.map((value: any) => value.securityType),
        valueFormatter: (params) =>
          params.data.securityType?.map((value: any) => value.securityType),
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: "Security Class",
        field: "securityClass",
        filter: "agSetColumnFilter",
        cellRenderer: "securityClass",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipSecurityClass",
        hide: true,
      },
      {
        headerName: "No. of Shares",
        field: "numberOfShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberOfShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipNoOfShares",
      },
      {
        headerName: `Transaction Price (${currencySymbol})`,
        field: "transactionPrice",
        filter: "agNumberColumnFilter",
        cellRenderer: "transactionPrice",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipTransactionPrice",
      },
      {
        headerName: `Amount (${currencySymbol})`,
        field: "amount",
        filter: "agMultiColumnFilter",
        cellRenderer: "amount",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAmount",
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Amount",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.amount,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) => params.getValue("amount").name,
              },
            },
            {
              title: "Amount in Round Currency",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.type,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) => params.getValue("amount").type,
              },
            },
          ],
        },
      },

      {
        headerName: "Payment Type",
        field: "paymentType",
        filter: "agSetColumnFilter",
        cellRenderer: "paymentType",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: `Valuation (${currencySymbol})`,
        field: "valuation",
        filter: "agNumberColumnFilter",
        cellRenderer: "valuation",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipValuation",
        tooltipComponent: ValuationPPSTooltip,
      },
      {
        headerName: "Dilution",
        field: "dilution",
        filter: "agNumberColumnFilter",
        cellRenderer: "dilution",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipDilution",
      },
      {
        headerName: "Split Ratio",
        field: "splitRatio",
        filter: "agSetColumnFilter",
        cellRenderer: "splitRatio",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        hide: true,
      },
      {
        headerName: "Bonus Ratio",
        field: "bonusRatio",
        filter: "agSetColumnFilter",
        cellRenderer: "bonusRatio",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        hide: true,
      },
      {
        headerName: "Lead Investor",
        field: "leadInvestor",
        filter: "agTextColumnFilter",
        cellRenderer: "leadInvestor",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipLeadInvestor", // Specify the field for tooltip content
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "12px", "line-height": "18px" },
        resizable: false,
        sortable: false,
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: CTADropdown,
      },
    ],
    []
  );

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };

  async function handleAction(action: Action) {
    if (action.disabled) return;

    if (action.name === "Round Summary Report") {
      exportRoundSummaryReport("").then((res) => {
        const fileName = `Round Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "Secondary Summary Report") {
      exportSecondarySummaryReport().then((res) => {
        const fileName = `Secondary Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "All Transaction Summary Report") {
      setDialog({
        open: true,
        name: "All Transaction Summary Report",
      });
    }
  }

  const currencyType = getCurrencyType();

  // const [dialogs, setDialogs] = useState<{
  //   open: boolean;
  //   name: "Update Conversion Details";
  //   data?: any;

  // }>({
  //   open: false,
  //   name: "",
  // });

  function handleModifyAction(action: string, data: any, index: number) {
    if (action === "Update Conversion Details") {
      setDialog({
        open: true,
        name: "Update Conversion Details",
        data,
      });
    } else if (action === "Transaction Documents") {
      setDialog({
        open: true,
        name: "Transaction Documents",
        data: data.id,
      });
    }
  }

  const rowData = useMemo(
    () =>
      transactionTableData.sort(compareTransactions).map((template, index) => {
        let actions = null;
        // Conditionally render actions only for "conversion" transaction type
        if (template.transactionType === "conversion") {
          actions = (
            <CTADropdown
              actions={[
                {
                  name: "Update Conversion Details",
                  disabled: Boolean(cid),
                },
                {
                  name: "Transaction Documents",
                  disabled: false,
                },
              ]}
              onAction={(action) => {
                handleModifyAction(action.name, template, index);

                // handle action logic here if needed
              }}
            />
          );
        }
        if (template.transactionType === "bonus" || template.transactionType === "split" || template.transactionType === "buyback" || template.transactionType === "redemption") {
          actions = (
            <CTADropdown
              actions={[
                {
                  name: "Transaction Documents",
                  disabled: false,
                },
              ]}
              onAction={(action) => {
                handleModifyAction(action.name, template, index);
                // handle action logic here if needed
              }}
            />
          );
        }

        return {
          id: template.id,
          transactionType: template.transactionType,
          issuanceType: template.issuanceType,
          transactionName: {
            name: template.name,
            type: template.transactionType,
          },
          tooltipTransactionName: template.name,
          splitRatio: template.splitRatio,
          bonusRatio: template.bonusRatio,
          securityClass: template.securityClass,
          tooltipSecurityClass: template.securityClass,
          dateOfTransaction: template.dateOfTransaction,
          securityType: template.securityTypes,
          numberOfShares: template.noOfShares,
          tooltipNoOfShares: template.noOfShares.toLocaleString(currencyType),
          pps:
            template.transactionType !== "split"
              ? template.roundPPS && template.debtSecurityForPPS
                ? template.roundPPS
                : template.pps
              : "",
          roundPPS: template.roundPPS,
          tooltiPPS:
            template.transactionType !== "split"
              ? template.roundPPS && template.debtSecurityForPPS
                ? template.roundPPS.toLocaleString(currencyType)
                : template.pps.toLocaleString(currencyType)
              : "",
          amount: {
            name: template.amount,
            type: template.amountInvestedInCurrency,
            roundCurrency: template.currency,
          },
          tooltipAmount:
            template.transactionType.toLowerCase() === "forfeiture" &&
            template?.amount === 0
              ? ""
              : template.amount.toLocaleString(currencyType),
          paymentType: template.paymentType,
          leadInvestor: template.leadInvestor,
          tooltipLeadInvestor:
            template.transactionType === "round" ? template.leadInvestor : "",
          valuation: template.valuation,
          tooltipValuation: template.valuation.toLocaleString(currencyType),
          dilution:
            template.dilution !== null
              ? Number((Math.abs(template.dilution) * 100).toFixed(2))
              : null,
          tooltipDilution: Math.abs(template.dilution * 100),
          debtSecurityForPPS: template.debtSecurityForPPS,
          isPricedRound: template.isPricedRound,
          transactionPrice: template.transactionPrice,
          tooltipTransactionPrice:
            _.uniq(template.transactionPrice)
              ?.map((value) => value.toLocaleString(currencyType))
              .join("-") || "",
          currencySymbol,
          currencyType,
          actions, // Include the conditional actions here
        };
      }),
    [transactionTableData]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.78) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.94) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.75) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.7) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.55) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.35) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.28) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.22) * 60;
    } else {
      return 10 * 60;
    }
  }

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (
        cellParams.value.name !== "Total" &&
        cellParams.column.getColId() === "transactionName"
      ) {
        if (cid) handleEventForTracking({ eventName: "Inv Transaction Name" });
        else handleEventForTracking({ eventName: "Transaction Name" });
        onClickTransactionName(template.transactionType, template.id);
      }
    }
  };
  const contextRef = useRef<HTMLDivElement>(null);

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const gridContainerRef = useRef(null);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key);
        } else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );
    const totalShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.numberOfShares,
      0
    );
    const totalAmount = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.amount.name,
      0
    );
    api.setPinnedBottomRowData([
      {
        transactionName: {
          name: "Total",
          type: null,
        },
        transactionType: null,
        numberOfShares: totalShares,
        splitRatio: null,
        bonusRatio: null,
        securityClass: null,
        dateOfTransaction: null,
        securityType: null,
        tooltipNoOfShares: totalShares.toLocaleString(currencyType),
        pps: null,
        roundPPS: null,
        tooltiPPS: null,
        amount: { name: totalAmount, type: "-" },
        tooltipAmount: totalAmount.toLocaleString(currencyType),
        paymentType: null,
        leadInvestor: null,
        tooltipLeadInvestor: null,
        valuation: null,
        dilution: null,
        tooltipValuationOrDilution: null,
        transactionPrice: null,
        currencyType,
      },
    ]);
  }

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);
    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    setPinnedBottomRowData(grid);
    // uploadFilter();
  };

  return (
    <>
      <HStack className="flex-col items-start justify-start py-2 bg-white border rounded-md lg:flex-row lg:justify-between lg:items-center border-borderColor shadow-box">
        <GenericTableHeader
          heading={"Transaction Summary"}
          tableId={TableId.allTansactionTable}
          // isLoading={isSaving}
          // setFilterCondition={setFilterCondition}
          // filterCondition={filterCondition}
          showCloud={true}
          subHeading={"Transactions"}
          count={
            isFilterApplied === true ? filteredRowData.length : rowData.length
          }
          lastUpdatedDetailsContent={(() => {
            const transactions = transactionTableData.sort(compareTransactions);
            if (transactions.length > 0 && transactions[0].lastUpdatedDate)
              return `| Last Updated: ${formatDisplayDate(
                transactions[0].lastUpdatedDate
              )}`;
            return "";
          })()}
        />
        <HStack className="items-center justify-between w-full gap-4 px-8 py-2 lg:w-auto lg:justify-end">
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />

          <HStack className="gap-4">
            <ExportImport
              actions={[
                {
                  name: "Round Summary Report",
                },
                {
                  name: "Secondary Summary Report",
                },
                {
                  name: "All Transaction Summary Report",
                },
              ]}
              onAction={(action) => handleAction(action)}
            />
            <IconCTAButton
              value={"Columns"}
              onClick={() => openToolPanel("columns")}
              iconName={"fluent:column-triple-edit-20-regular"}
              className={`px-4 font-medium items-center flex flex-row ${
                isColumnOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
            <IconCTAButton
              value={"Filters"}
              onClick={() => openToolPanel("filters")}
              iconName={"material-symbols:filter-alt"}
              className={`px-4 font-medium items-center flex flex-row ${
                isFilterOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
          </HStack>
        </HStack>
      </HStack>
      <Dialog open={dialog.open} maxWidth="md">
        {dialog.name === "Update Conversion Details" && (
          <UpdateConversionPopUp
            data={dialog.data}
            onPrimaryAction={() => {
              setDialog({
                open: true,
                name: "Update Conversion Details",
                data: dialog.data,
              });
            }}
            onSecondaryAction={() => {}}
            onClose={() =>
              setDialog({
                open: false,
                name: "Update Conversion Details",
              })
            }
          />
        )}
        {dialog.name === "Transaction Documents" && (
          <TransactionDocPopup
            eventId={dialog?.data || ""}
            onPrimaryAction={() => {
              setDialog({
                open: true,
                name: "Transaction Documents",
                data: dialog.data,
              });
            }}
            onSecondaryAction={() => {
              setDialog({
                open: false,
                name: "Transaction Documents",
              });
            }}
          />
        )}
        {dialog.name === "All Transaction Summary Report" && (
          <ExportMultidateSheetDetails
            reportName={dialog.name || ""}
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        )}
      </Dialog>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            onRowDataUpdated={setPinnedBottomRowData}
            onFilterChanged={onAgGridFilterChanged}
            // onColumnEverythingChanged={uploadColumn}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            enableCharts={true}
            enableRangeSelection={true}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            // onColumnResized={uploadColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed"}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </>
  );
}

export default AGGridTransactionTable;
