import { ShareholderDetails } from "./SecurityModel";

export interface ModelingListModel {
  modelId: string;
  modelName: string;
  updatedAt: string;
  createdAt: string;
  tab1Details: ModelOverviewDetails;
  isPublic?: boolean;
}

export interface ModelOverviewDetails {
  roundName: string;
  roundIdentifier: string;
  pricedRound: boolean;
  postEsopPercentage: number;
  roundSize: number;
  preMoney: number;
  baseValuation: number;
  fdbSharePrice: number;
  postMoney: number;
  dilution: number;
  moneyRaised: number;
  leftToRaise: number;
}

export enum ModelState {
  ToBeSaved = "ToBeSaved",
  ToBeDiscarded = "ToBeDiscarded",
  RetrieveModelData = "RetrieveModelData",
}

export enum IndividualConvertiblesState {
  UpdateCaptable = "UpdateCaptable",
  SettleConvertible = "SettleConvertible",
  Reset = "Reset",
  UpdateValuation = "UpdateValuation",
}

export interface ConvertibleInvestorDetails {
  investorId: string;
  preNumberOfShares: number;
  postNumberOfShares: number;
  preFdbShares: number;
  postFdbShares: number;
  type: string;
}

export interface IRoundDetail {
  roundId?: string;
  roundType?: string;
  roundName?: string;
  roundIdentifier?: string;
}

export enum ConvertibleType {
  PreMoney = "Pre-Money",
  PostMoney = "Post-Money",
  InvestmentMethod = "Investment Method",
}

export enum ConvertiblesState {
  ToBeUpdated = "ToBeUpdated",
  ToBeSaved = "ToBeSaved",
}

export interface ConvertibleDetails {
  instrumentClassId: string;
  securityName: string;
  valuationCap: number;
  discount: number;
  method: ConvertibleType;
  valuationApplied: number;
  roundPPS: number;
  conversionRatio: number;
  amountInvested: number;
  numberOfShares: number;
  fdbShares: number;
  holdingPercentage: number;
  roundIds: IRoundDetail[];
  baseValuation: number;
  currentValuation: number;
  capPPS: number;
  discountPPS: number;
  minPPS: number;
  conversionDetails?: {
    newConversionRatio?: number;
    newConversionSecurityId?: string;
    updateConversionRatio: boolean;
  };

  finalConversionPPS: number;

  investors: ConvertibleInvestorDetails[];

  conversionDate: string;
  isConverted: boolean;

  newConversionRatio: string;

  postDiscountValuation: number;
  state?: IndividualConvertiblesState;
}

export interface OutStandingConvertibleDetails {
  convertibles: ConvertibleDetails[];
  convertiblesCaptable: OngoingCapTable;
  newPreMoneyValuation: number;
  newPostMoneyValuation: number;
  remainingHoldingForNewInvestor: number;
  remainingConvertibles: number;
  state?: ConvertiblesState;
  newPPS?: number;
}

export interface ModelBuilder {
  modelId?: string;
  companyId: string;
  baseCaptableId?: string;
  currentTab?: number;
  highestVisitedTabLevel?: number;
  modelState?: ModelState;
  notificationMessage?: string;
  errorMessage?: string;
  isNavigating: boolean; // Indicates whether navigation is in progress
  preCaptableHeaders?: PreCaptableHeaders;
  onGoingRoundHeaders?: ModelHeaders;
  roundDetailsTab?: RoundInputDetails; // Tab1
  convertibles?: OutStandingConvertibleDetails;
  tab2?: any;
  existingInvestorDetails?: ExistingInvestorDetails;
  newInvestorDetails?: NewInvestorDetails;
  onGoingCaptable?: OngoingCapTable;
  secondaries?: SecondaryDetails;
  isTermsheet?: boolean;
  isPublic?: boolean;
}

export interface SecondaryDetails {
  ongoingSecondaryAllotmentDetails?: OngoingSecondaryAllotmentDetails;
  ongoingBlendedSecondaryAllotmentDetails?: OngoingBlendedSecondaryAllotmentDetails;
  secondaryAllotmentDetails: SecondaryAllotmentDetails[];
  listOfSellers: SecInvestorDetails[]; //ListOfSellers[];
  listOfBuyers: SecInvestorDetails[];
}

export interface OngoingBlendedSecondaryAllotmentDetails {
  id: string;
  buyerId: string;
  buyerName: string;
  sharePrice: number;
  postHoldingPercentage: number;
  totalAmountToBeInvested: number;
  secondaryHoldingPercentage: number;
  totalFdbSharesToBeBought: number;
  remainingInvestmentAmount: number;
  state: InvestorState;
  sellerDetails: SellerDetails[];
}

export interface SellerDetails {
  id: string;
  sellerId?: string;
  sellerName?: string;
  fdbShares: number;
  amount: number;
  holdingPercentage: number;
}

export interface OngoingSecondaryAllotmentDetails {
  id: string;
  sellerId?: string;
  buyerId?: string;
  sellerName?: string;
  buyerName?: string;
  fdbShares: number;
  amountInvested: number;
  sharePrice: number;
  state: InvestorState;
}
export interface SecondaryAllotmentDetails {
  id: string;
  sellerId?: string;
  buyerId?: string;
  sellerName?: string;
  buyerName?: string;
  fdbShares: number;
  amountInvested: number;
  sharePrice: number;
  secondaryHoldingPercentage: number;
  postHoldingPercentage: number;
  isBlendedSecondary: boolean;
  sellerDetails?: SellerDetails[];
}

export interface SecInvestorDetails {
  investorId: string;
  investorName: string;
  totalFdbShares: number;
}

export interface OngoingCapTable {
  investors: OngoingCaptableInvestor[];
  preCaptable: InvestorHoldingInfo[];
}

export interface OngoingCaptableInvestor {
  id: string;
  investorId: string;
  investorName: string;
  investorType: string;
  preHoldingFdbShares: number;
  preHoldingPercentage: number;
  postHoldingFdbShares: number;
  postHoldingPercentage: number;
  totalAmountInvested: number;
  investmentAmount: number;
}

export interface InvestorHoldingInfo {
  investorId: string;
  investorName: string;
  fdbShares: number;
  holdingPercentage: number;
  investmentAmount: number;
  investorType: string;
}

export interface PreCaptableHeaders {
  moneyRaised: number;
  pps: number;
  numberOfRounds: number;
  equityShares: number;
  prefShares: number;
  esopShares: number;
  fdbShares: number;
  valuation: number;
  lastRoundAmountRaised: number;
  lastTransactionDate: string;
}

export interface ModelHeaders {
  roundSize: number;
  valuation: number;
  fdbSharePrice: number;
  moneyRaised: number;
  leftToRaise: number;
  leadInvestor: string;
  convertiblePps: number;
  postMoneyValuation: number;
  dilution: number;
  postEsopPercentage: number;
  roundName: string;
}

export interface RoundInputDetails {
  roundName?: string;
  roundIdentifier?: string;
  pricedRound?: boolean;
  autoDiluteEsop?: boolean;
  postRoundEsopPercentage?: number;
  roundSize?: number;
  preMoneyValuation?: number;
  fdbSharePrice?: number;
  postMoneyValuation?: number;
  dilution?: number;
  postDiluatedEsopHoldingPercentage?: number;
  hasToCalculatePps?: boolean;
  hasToRecalculateOtherTabs?: boolean;
}

export interface SharesPerSecurity {
  investorId: string;
  instrumentId: string;
  instrumentName: string;
  noOfShares: number;
}

export interface ExistingInvestorDetails {
  investorDetails: InvestorDetails[];
  ongoingExistingInvestorDetails?: OngoingInvestorDetails;
  listOfInvestor: ListOfExistingInvestors[];
}

export interface NewInvestorDetails {
  investorDetails: InvestorDetails[];
  ongoingExistingInvestorDetails?: OngoingInvestorDetails;
}

export interface InvestorDetails {
  id: string;
  investorId: string;
  investorName: string;
  investorType?: string;
  optedInvestorRightType?: InvestorRightsType;
  amountToInvest: number;
  postHoldingPercentage: number;
  preHoldingPercentage: number;
  unLockedInputFeild: UnLockedInputFeild;
  hasBlendedSecondaries: boolean;
  blendedSecondaries?: BlendedSecondaries;
  fdbShares: number;
}

export interface OngoingInvestorDetails {
  investorId: string;
  investorName?: string;
  investorType?: string;
  optedInvestorRightType?: string;
  amountToInvest: number;
  postHoldingPercentage: number;
  unLockedInputFeild: UnLockedInputFeild;
  state: InvestorState;
  hasBlendedSecondaries: boolean;
  blendedSecondaries?: BlendedSecondaries;
}

export enum InvestorRightsType {
  MaintainCapital = "Maintain Capital",
  ProRataInvestment = "Pro Rata Investment",
  None = "None",
}
export interface UnLockedInputFeild {
  hasToCalculateAmt: boolean;
  value: number;
}
export enum InvestorState {
  Update = "ToBeUpdated",
  ToBeSaved = "ToBeSaved",
  ToBeDeleted = "ToBeDeleted",
}

export interface ListOfExistingInvestors {
  investorId: string;
  investorName: string;
  investorType?: string;
  holdingPercentageWithDilution: number;
  holdingPercentageWithoutDilution: number;
}

export interface BlendedSecondaries {
  targetHoldingPercentage: number;
  primaryInvestmentAmount: number;
  primaryInvestmentHoldingPercentage: number;
  secondaryInvestmentAmount: number;
  secondaryInvestmentHoldingPercentage: number;
  finalInvestmentAmount: number;
  finalInvestmentHoldingPercentage: number;
  sharePrice: number;
  discountPercentage: number;
}

export interface OutstandingConvertible {
  investorId: string;
  investorName?: string;
  investorType?: string;
  instrumentClassId: string;
  securityName: string;
  securityType: string;
  numberOfShares: number;
  fdbShares: number;
  amountInvested: number;

  discountedValuation: number;
  discountedPPS: number;
}

export interface QuickRoundOutstandingConvertible {
  instrumentClassId: string;
  securityName: string;
  securityType: string;
  shareholders: ShareholderDetails[];
  method: string;
  valuationApplied: number;
  newConversionRatio: string;
  conversionPrice: number;
}

export interface NonPricedRoundModel {
  convertibles: ConvertibleModel[];

  roundSize: number;
  autoDiluteEsop: boolean;
  esopPercentage: number;
  preMoneyValuation: number;
  conversionMethod: string;

  investorHolding: InvestorHolding;
  modelName: string;
  preFdbShares: number;
  preEsop: number;
  totalNoteFdb: number;

  newPostMoneyValuation?: number;
  newPreMoneyValuation?: number;
  pps?: number;
}

export interface ConvertibleModel {
  uuid?: string;
  securityType: string;
  securityName: string;
  investment: number;
  conversionRatio?: number;
  parValue?: number;
  valuationCap: number;
  discount: number;
  method?: "Pre-Money" | "Post-Money" | "";
  baseValuation: number;
  excluded: boolean;
  isModel?: boolean;
}

export interface ConvertibleHoldings {
  category: string;
  percentage: number;
  fdbShares: number;
  conversionPrice?: number;
}

export interface InvestorHolding {
  preMoneyValuation: number;
  preRoundInvestorHolding: number;
  preRoundInvestorFdbShares: number;
  convertibleInvestorHolding: {
    name: string;
    holdingPercentage: number;
    fdbShares: number;
    noteValue: number;
    conversionPrice?: number;
    valuationApplied?: number;
  }[];
  newInvestorHolding: number;
  newInvestorFdbShares: number;
  esopPercentage: number;
  esopShares: number;
}

export interface NonPricedRoundCardModel {
  id: string;
  draftData: NonPricedRoundModel;
  type: "NonPricedRound" | "PricedRound";
  state: string;
  isPublic?: boolean;
}
