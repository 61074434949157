import {
  Combobox,
  ComboboxOption,
  ComboboxOptions,
  Field,
  Label,
} from "@headlessui/react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { VStack, HStack } from "../../components/utils";

import { classNames } from "../../utils/string";
import EmptyTableIllustration from "../reports/EmptyTableIllustration";
import Avatar from "../Utility/Avatar";
import {
  ButtonSize,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import GiveModelAccessSelect from "./GiveModelAccessSelectPop";
import {
  useDeleteUserAccess,
  useGetListOfUsersAccess,
  useShareUserAccess,
  useUpdateModelAccess,
} from "../../queries/modeling";
import { useCompanyUsers } from "../../queries/newCapTable/userRoles";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { useRoundModelStore } from "../../store/roundModelingStore";

type Options = { label: string; value: any };

type UserAccess = {
  userId: number;
  accessId: string;
  emailId: string;
  role: string;
};

export default function GiveModelAccess({ modelId }: { modelId: string }) {
  const roundModelStore = useRoundModelStore();

  const { data: listOfUsers } = useGetListOfUsersAccess(modelId);
  const { data: _users, isFetching } = useCompanyUsers();

  const { mutate: giveUserAccess } = useShareUserAccess();
  const { mutate: updateModelAccess } = useUpdateModelAccess();

  const [options, setOptions] = useState<Options[]>([]);
  const [userAccessList, setUserAccessList] = useState<
    { userId: string; role: string }[]
  >([]);
  const [selectUserAccessList, setSelectUserAccessList] = useState<
    {
      label: string;
      value: any;
    }[]
  >([]);

  useEffect(() => {
    const listOfuserOption: Options[] =
      _users?.map((user) => ({
        label: user.emailId,
        value: user.userId,
      })) || [];
    setOptions(listOfuserOption);

    const userAccess: { userId: string; role: string }[] =
      selectUserAccessList?.map((user) => ({
        userId: user.value,
        role: "Admin",
      })) || [];

    setUserAccessList(userAccess);
  }, [listOfUsers, selectUserAccessList]);

  const initialValues: any = {
    giveUserAccessList: [],
    isPublic: roundModelStore.roundCreation.isPublic || false,
  };

  const filteredOptions = options.filter(
    (option) => !listOfUsers?.some((user: any) => user.userId === option.value)
  );

  return (
    <Formik
      key="shareAccess"
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        resetForm();
      }}
    >
      {(formik) => (
        <>
          <Form key="shareAccess">
            <Combobox>
              <VStack className="w-full px-2 gap-9">
                <VStack className="">
                  <HStack className="px-2 py-1.5">
                    <div className="relative w-full">
                      <div className="flex flex-wrap items-center gap-2 border focus-within:border-secondary/50">
                        <Select
                          isMulti
                          {...formik.getFieldProps("giveUserAccessList")}
                          placeholder="Add email..."
                          className="flex-1 text-sm border-none rounded-none focus:outline-none"
                          options={filteredOptions}
                          onChange={(selectedOptions: any) => {
                            setSelectUserAccessList(selectedOptions);

                            formik.setFieldValue(
                              "giveUserAccessList",
                              selectedOptions
                            );
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: "30px",

                              borderRadius: 0,
                              boxShadow: "none",
                              border: "none",
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="pl-2">
                      <PrimaryCTAButton
                        buttonSize={ButtonSize.MEDIUM}
                        className="items-center px-4"
                        disabled={formik.values.isPublic}
                        onClick={() => {
                          giveUserAccess(
                            { modelId, userAccessList },
                            {
                              onSuccess: (data) => {
                                toast(
                                  "You have successfully given access to user ",
                                  {
                                    type: "success",
                                    autoClose: 2000,
                                  }
                                );
                                setSelectUserAccessList([]);
                                formik.setFieldValue("giveUserAccessList", []);
                              },
                              onError: (err: any) => {
                                toast(err.response.data.errorMessage, {
                                  type: "error",
                                  autoClose: 2000,
                                });
                              },
                            }
                          );
                        }}
                      >
                        Add User
                      </PrimaryCTAButton>
                    </div>
                  </HStack>
                  <p className="px-2 text-gray-400 text-xxs ">
                    Please press enter or space after entering email
                  </p>

                  <VStack>
                    <div className="flex flex-row-reverse items-center justify-end gap-4 text-sm font-medium text-gray-600">
                      <SwitchButton
                        className="items-center px-2 text-sm font-medium whitespace-nowrap"
                        value={formik.values.isPublic}
                        label="Is Public"
                        onClick={() => {
                          formik.setFieldValue(
                            "isPublic",
                            !formik.values.isPublic
                          );
                          updateModelAccess({
                            modelId,
                            isPublic: String(!formik.values.isPublic),
                          });

                          roundModelStore.setRoundCreation({
                            ...roundModelStore.roundCreation,
                            isPublic: !formik.values.isPublic,
                          });
                        }}
                      />
                    </div>
                  </VStack>

                  {!formik.values.isPublic ? (
                    <h2 className="px-2 mt-4 mb-1 text-xs font-semibold text-gray-500">
                      User&apos;s having access
                    </h2>
                  ) : (
                    <h2 className="px-2 mt-4 mb-1 text-xs font-semibold text-gray-500">
                      Users of this company will have access to this model.
                    </h2>
                  )}

                  <ComboboxOptions
                    static
                    as="ul"
                    className="overflow-y-auto divide-y divide-gray-100 min-h-[200px] max-h-[500px] max-h-144"
                  >
                    {!formik.values.isPublic ? (
                      <li className="p-2">
                        <ul className="text-sm text-gray-700">
                          {listOfUsers?.length > 0 ? (
                            listOfUsers?.map((user: UserAccess) => (
                              <ComboboxOption
                                as="li"
                                key={user.userId}
                                value={user}
                                className={({ focus }: any) =>
                                  classNames(
                                    "flex cursor-default select-none items-center rounded-md px-3 py-2",
                                    focus && "bg-secondary/5 text-secondary"
                                  )
                                }
                              >
                                {({ focus }) => (
                                  <>
                                    <Avatar name={user?.emailId} />
                                    <span className="flex-auto ml-3 truncate">
                                      {user.emailId}
                                    </span>

                                    <GiveModelAccessSelect
                                      userDetails={user}
                                      modelId={modelId}
                                    />
                                  </>
                                )}
                              </ComboboxOption>
                            ))
                          ) : (
                            <VStack>
                              <div className="">
                                <EmptyTableIllustration
                                  text={"Not Shared with anyone yet"}
                                />
                              </div>
                            </VStack>
                          )}
                        </ul>
                      </li>
                    ) : (
                      <li></li>
                    )}
                  </ComboboxOptions>
                </VStack>
              </VStack>
            </Combobox>
          </Form>
        </>
      )}
    </Formik>
  );
}
