import { useMutation, useQuery } from "react-query";
import {
  deleteTableState,
  getOnFilterState,
  getTableFilterState,
  postOnFilterState,
  postTableFilterState,
} from "./AgGridCache";
import { queryClient } from "../../queries";

export function useGetOnFilterState(userId: string, companyId: string) {
  return useQuery({
    queryKey: ["getFilterState", userId, companyId],
    queryFn: getOnFilterState,
    // select: (data) => data.data,
    enabled: false,
  });
}

export function usePostOnFilterState() {
  return useMutation({
    mutationKey: "postFilterState",
    mutationFn: postOnFilterState,
    onSuccess: () => {},
    onError: () => {},
  });
}

export function useGetTableFilterState(tableId: string) {
  return useQuery({
    queryKey: ["getTableFilterState", tableId],
    queryFn: getTableFilterState,
  });
}

export function usePostTableFilterState() {
  return useMutation({
    mutationKey: "postTableFilterState",
    mutationFn: postTableFilterState,
  });
}

export function useDeleteTableState() {
  return useMutation({
    mutationKey: "deleteTableState",
    mutationFn: deleteTableState,
    onSuccess: () => {
      queryClient.invalidateQueries("getTableFilterState");
    },
  });
}
