import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { Box, HStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import BeneficiariesTable from "./BeneficiariesTable";
import ShareholderSecuritiesTable from "./ShareholderSecuritiesTable";
import ShareholderTransactionDetailsTable from "./ShareholderTransactionDetailsTable";
import { useGetIndividualShareholderOverview } from "../../queries/shareholders";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { useAuthStore } from "../../store";
import IndividualShareholderCard from "./IndividualShareholderCard";
import ShareholderRightsTable from "./ShareholderRights";
import { useAllIndividualShareHolderRight } from "../../queries/shareHolderRights";
import { useInternalSwitchStateStore } from "../../store/searchDropDown";
import { SwitchDropDownModel } from "../rounds/RoundsAllTransactions";
import SearchAutoCompleteDropDown from "../../components/shared/SearchAutoCompleteDropDown";
import { useCanUserAccess } from "../../store/useRoleGrantStore";
import AgGridShareholderTransactionTable from "./ShareholderAGTransactionDetailsTable";
import AgGridShareholderSecuritiesTable from "./ShareholderAgSecuritiesTable";
import AgGridBeneficiariesTable from "./BeneficiariesAgTable";
import { useGetCompanyDetails } from "../../queries/termsheet";
import {
  FirstCardComponent,
  SecondCardComponent,
} from "../../components/shared/InnerPecentageCardDesign";
import AddShareholderProfile from "../../components/shared/ShareholderProfile";
import { limitString } from "../../utils/string";
import { handleEventForTracking } from "../../amplitudeAnalytics";
import {
  ButtonSize,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import LinkGlobalAccount from "../../modals/LinkGlobalAccount";
import OnboardInvestor from "../../modals/OnboardInvestor";
import AgGridEsopPlanDetailsTable from "./EsopPlanDetailsAgTable";

export default function IndividualShareholder() {
  const { id } = useParams();
  const _id = id || "";
  const currencySymbol = getCurrencySymbol();
  const currencyType = getCurrencyType();
  const canViewRights = useCanUserAccess("view", "rights");
  const companyId = useAuthStore.getState().companyId;
  const navigate = useNavigate();
  const { data: nodeCompanyDetail } = useGetCompanyDetails();
  const isAdmin = useCanUserAccess("*", "linkInvestor");
  const cin = nodeCompanyDetail?.companyDetails.cinNumber || "";

  const { isPlaceholderData, data: _shareholderDetails } =
    useGetIndividualShareholderOverview(_id);

  const { refetch: getShareHolderRightsData, data: shareHolderData } =
    useAllIndividualShareHolderRight(
      companyId || "",
      _shareholderDetails?.shareholderDetails.id || "",
      cin
    );

  const transactionSwitchStore = useInternalSwitchStateStore();
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    mode?: "Link Global Account";
  }>({
    open: false,
  });

  const [approvedDialog, setApprovedDialog] = useState<{
    open: boolean;
    message?: string;
    mode?: "Onboard Investor";
  }>({
    open: false,
  });
  const [selectedTransaction, setSelectedTransaction] =
    useState<SwitchDropDownModel>({ name: "", id: "", type: "" });

  function getSelectedValue(transaction?: SwitchDropDownModel) {
    if (transaction) {
      handleEventForTracking({ eventName: "Shareholder Search" });
      setSelectedTransaction(transaction);
      navigate(`/shareholders/individual/${transaction?.id}`);
    }
  }

  useEffect(() => {
    const _allAvailableTabs = allAvailableTabs;
    if (!isPlaceholderData && _shareholderDetails) {
      if (
        _shareholderDetails.shareholderSecurities &&
        _shareholderDetails.shareholderSecurities.length > 0 &&
        !allAvailableTabs.includes("Share Certificates")
      ) {
        _allAvailableTabs.push("Share Certificates");
      }
      if (
        _shareholderDetails.beneficiaries &&
        _shareholderDetails.beneficiaries.length > 0 &&
        !allAvailableTabs.includes("Beneficiary")
      ) {
        _allAvailableTabs.push("Beneficiary");
      }
      if (
        !allAvailableTabs.includes("Profile") &&
        _shareholderDetails.shareholderProfile.type !== "Options"
      ) {
        _allAvailableTabs.push("Profile");
      }
      if (
        !allAvailableTabs.includes("ESOP Plan Details") &&
        _shareholderDetails.shareholderProfile.type === "Options"
      ) {
        _allAvailableTabs.push("ESOP Plan Details");
      }
      setSelectedTransaction({
        name: _shareholderDetails.shareholderDetails?.name,
        id: _shareholderDetails.shareholderDetails.id,
        type: _shareholderDetails.shareholderDetails?.type,
      });
      if (canViewRights) getShareHolderRightsData();
      setAllAvailableTabs(_allAvailableTabs);
    }
  }, [_shareholderDetails]);

  useEffect(() => {
    if (
      shareHolderData &&
      canViewRights &&
      shareHolderData.length > 0 &&
      !allAvailableTabs.includes("Rights")
    ) {
      setAllAvailableTabs([...allAvailableTabs, "Rights"]);
    }
  }, [shareHolderData]);

  const [selectedTab, setSelectedTab] = useState("Transaction");
  const [allAvailableTabs, setAllAvailableTabs] = useState(["Transaction"]);
  return (
    <div className={isPlaceholderData ? "loading" : "min-h-full"}>
      <HStack className="items-center justify-between pb-4">
        <BCHeader
          className="items-baseline "
          bcTitle="Shareholders"
          bcSubTitle={
            limitString(
              _shareholderDetails?.shareholderDetails.name
                ?.toLowerCase()
                ?.includes("esop")
                ? _shareholderDetails?.shareholderDetails.name?.replace(
                    /esop/gi,
                    "ESOP"
                  )
                : _shareholderDetails?.shareholderDetails.name ?? "",
              40
            ) ?? "Specific Shareholder"
          }
        />
        <HStack className="flex-col items-center gap-4 md:flex-row">
          {(_shareholderDetails?.shareholderProfile.globalInvestorStatus ===
            "rejected" ||
            _shareholderDetails?.shareholderProfile.globalInvestorStatus ===
              undefined) && (
            <>
              <Dialog open={dialog.open} maxWidth="md">
                {dialog.mode === "Link Global Account" ? (
                  <LinkGlobalAccount
                    shareHolderName={
                      _shareholderDetails?.shareholderDetails.name
                    }
                    companyInvestorId={id}
                    onPrimaryAction={() => {
                      setDialog({ open: false });
                    }}
                    onSecondaryAction={() => {
                      setDialog({ open: false });
                    }}
                    mode="Add"
                  />
                ) : (
                  <div></div>
                )}
              </Dialog>

              {_shareholderDetails?.shareholderDetails.type !== "Options" && (
                <SecondaryCTAButton
                  buttonSize={ButtonSize.SMALL}
                  onClick={() => {
                    setDialog({
                      open: true,
                      mode: "Link Global Account",
                    });
                  }}
                  disabled={!isAdmin}
                >
                  Link Global Account
                </SecondaryCTAButton>
              )}
            </>
          )}
          {_shareholderDetails?.shareholderProfile.globalInvestorStatus ===
            "approved" &&
            _shareholderDetails?.shareholderProfile.isCredentialGenerated ===
              false && (
              <>
                <Dialog open={approvedDialog.open} maxWidth="xs">
                  {approvedDialog.mode === "Onboard Investor" ? (
                    <OnboardInvestor
                      shareHolderName={
                        _shareholderDetails?.shareholderDetails.name!
                      }
                      adminEmail={
                        _shareholderDetails?.shareholderProfile
                          .globalAccountEmail!
                      }
                      globalAccountId={
                        _shareholderDetails?.shareholderProfile.globalAccountId!
                      }
                      globalInvestorId={
                        _shareholderDetails?.shareholderProfile
                          .globalInvestorId!
                      }
                      shareHolderId={_shareholderDetails.shareholderDetails.id}
                      onPrimaryAction={() => {
                        setApprovedDialog({ open: false });
                      }}
                      onSecondaryAction={() => {
                        setApprovedDialog({ open: false });
                      }}
                      mode="Add"
                    />
                  ) : (
                    <div></div>
                  )}
                </Dialog>

                <SecondaryCTAButton
                  buttonSize={ButtonSize.SMALL}
                  onClick={() => {
                    setApprovedDialog({
                      open: true,
                      mode: "Onboard Investor",
                    });
                  }}
                >
                  Onboard Investor
                </SecondaryCTAButton>
              </>
            )}

          {selectedTransaction ? (
            <Box className="w-48 mb-8 bg-black rounded-md">
              <SearchAutoCompleteDropDown
                placeholder={"Switch Shareholder"}
                data={
                  transactionSwitchStore.shareholders as SwitchDropDownModel[]
                }
                getSelectedValue={getSelectedValue}
                selectedOption={selectedTransaction as SwitchDropDownModel}
                field={"name" as keyof SwitchDropDownModel}
              />
            </Box>
          ) : (
            ""
          )}
        </HStack>
      </HStack>
      <div className="gap-4">
        {_shareholderDetails && (
          <HStack className="flex-col w-full pb-8 overflow-auto md:flex-row scrollbar-hide">
            <HStack className="justify-center flex-1">
              <FirstCardComponent
                key={_shareholderDetails?.shareholderDetails.id}
                data={{
                  name: _shareholderDetails?.shareholderDetails.name,
                  type: _shareholderDetails?.shareholderDetails.type,
                  percentage:
                    _shareholderDetails?.shareholderDetails.holdingPercent,
                  isInstitutional:
                    _shareholderDetails?.shareholderProfile.isInstitutional ??
                    false,
                  isPromoter:
                    _shareholderDetails?.shareholderProfile.isPromoter ?? false,
                }}
              />
              <SecondCardComponent
                isInvestorPortal={!!companyId}
                cardDetails={_shareholderDetails}
                showCertificateNumber={
                  !_shareholderDetails.shareholderDetails.hasDematShares ??
                  false
                }
                cardType="shareholder"
                globalInvestorStatus={
                  _shareholderDetails?.shareholderProfile.globalInvestorStatus
                }
                globalInvestorId={
                  _shareholderDetails?.shareholderProfile.globalInvestorId
                }
              />
            </HStack>
          </HStack>
        )}
        <HStack className="items-center bg-white rounded-md flex-0">
          {allAvailableTabs.map((tabs) => (
            <p
              onClick={() => {
                setSelectedTab(tabs);
              }}
              key={tabs}
              className={`sm:px-2 md:px-4 cursor-pointer py-3 text-sm tracking-wide font-medium ${
                selectedTab === tabs
                  ? "text-orange-501 border-b-2 border-orange-501"
                  : ""
              }`}
            >
              {tabs}
            </p>
          ))}
        </HStack>
        {selectedTab === "Rights" && canViewRights && (
          <ShareholderRightsTable shareHolderData={shareHolderData ?? []} />
        )}
        {/*Dirty commit {selectedTab === "Transaction" && _shareholderDetails && (
          <ShareholderTransactionDetailsTable
            isPreviewOnly={true}
            transactions={_shareholderDetails.transactions}
            currencyType={currencyType}
          />
        )} */}
        {selectedTab === "Transaction" && _shareholderDetails && (
          <AgGridShareholderTransactionTable
            isPreviewOnly={true}
            hideShareNumbers={
              !_shareholderDetails.shareholderDetails
                .hasToShowDistinctiveNumber ?? true
            }
            transactions={_shareholderDetails.transactions}
          />
        )}
        {_shareholderDetails &&
          _shareholderDetails.beneficiaries &&
          _shareholderDetails.beneficiaries.length > 0 &&
          selectedTab === "Beneficiary" && (
            <AgGridBeneficiariesTable
              beneficiaries={_shareholderDetails.beneficiaries}
              currencySymbol={currencySymbol}
              currencyType={currencyType}
              isPreviewOnly={true}
            />
          )}
        {selectedTab === "Share Certificates" && _shareholderDetails && (
          <AgGridShareholderSecuritiesTable
            shareholderSecurities={_shareholderDetails.shareholderSecurities}
            currencyType={currencyType}
            shareholderId={id || ""}
          />
        )}
        {selectedTab === "Profile" && (
          <AddShareholderProfile
            initialValue={_shareholderDetails?.shareholderProfile}
            mode="Edit"
          />
        )}
        {selectedTab === "ESOP Plan Details" && (
          <AgGridEsopPlanDetailsTable
            esopPLanDetailsList={_shareholderDetails?.esopPlanDetailsList || []}
            shareholderId={id || ""}
            currencyType={currencyType}
          />
        )}
      </div>
    </div>
  );
}
