import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { getNames } from "country-list";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { HStack, VStack, Error } from "../components/utils";
import {
  Input,
  Label,
  Required,
  TextArea,
} from "../components/shared/InputField";
import { SwitchButton } from "../components/shared/SwitchButton";
import {
  useAddNewShareHolder,
  useEditShareholder,
  useGetShareholderGroups,
} from "../queries/shareholders";
import { useAuthStore } from "../store";
import {
  ShareholderSubTypes,
  shareholderTypeLabelValue,
} from "../constants/ShareholderConstants";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import { queryClient } from "../queries";
import {
  DocumentType,
  TransactionDocumentInput,
} from "../types/AllTransactionModel";
import { useAddTransactionDocument } from "../queries/transactionRound";

type AddShareholderProfileProps = {
  initialValue?: TransactionDocumentInput;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  mode: "Add" | "Edit" | "View";
  eventId: string;
};

const AddTransactionDocument = ({
  initialValue,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
  eventId,
}: AddShareholderProfileProps) => {
  const companyId = useAuthStore.getState().companyId || "";

  const [loading, setLoading] = useState(false);
  const viewOnly = mode === "View";
  const [file, setFile] = useState<File>();
  const fileRef2 = useRef() as React.MutableRefObject<HTMLInputElement>;

  const { mutate: uploadTransactionDocument } = useAddTransactionDocument();

  const initialValues: TransactionDocumentInput = {
    documentType: DocumentType.document,
    cin: initialValue?.cin || "",
    name: initialValue?.name || "",
    key: initialValue?.key || "",
    size: initialValue?.size || 0,
    tags: initialValue?.tags || [],
    eventId: initialValue?.eventId || "",
  };

  const transactionDocSchema = Yup.object({
    documentType: Yup.string()
      .required()
      .label(DocumentType.document as unknown as string),
    name: Yup.string().required().label("Name"),
    key: Yup.string().when(["documentType"], {
      is: (documentType: string) => Number(documentType) !== 0,
      then: Yup.string().required().label("Link"),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const formik = useFormik<TransactionDocumentInput>({
    initialValues,
    validationSchema: transactionDocSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      if (file) formData.append("file", file);
      formData.append("name", values.name);
      formData.append(
        "key",
        values.documentType === 0 ? values.name : values.key
      );
      formData.append("eventId", eventId || "");
      formData.append("size", file?.size.toString() || "0");
      formData.append(
        "documentType",
        values.documentType === 0 ? "document" : "link"
      );

      uploadTransactionDocument(
        { companyId, formData },
        {
          onSuccess: () => {
            queryClient.invalidateQueries("getTransactionDocuments");
            toast("Document Added Successfully ", {
              type: "success",
              autoClose: 2000,
            });
            onPrimaryAction();
            setLoading(false);
          },
          onError: (e: any) => {
            toast(
              `${e?.response?.data?.errorMessage || "Something went wrong"}`,
              { type: "error", autoClose: 2000 }
            );
            onPrimaryAction();
            setLoading(false);
          },
        }
      );
    },
  });

  const documentTypeList = [
    {
      label: "File",
      value: DocumentType.document,
    },
    {
      label: "Link",
      value: DocumentType.link,
    },
  ];

  const onClickFileFunction = () => {
    fileRef2.current.click();
  };

  function updateFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    formik.setFieldValue("name", e.target.files[0].name);
    setFile(e.target.files[0]);
  }

  return (
    <div>
      <Box className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          {"Add Transaction Document"}
          <XMarkIcon
            className="h-5 mt-1 font-medium cursor-pointer"
            onClick={() => onSecondaryAction()}
          />
        </h6>
      </Box>
      <VStack className="w-full px-10 py-7 gap-9">
        <>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className="px-0 form"
            key="addShareholderProfile"
          >
            <VStack className="w-128 h-64 gap-4">
              <div className="">
                <Label className="text-sm font-normal ">
                  Select Upload Type <Required />
                </Label>
                <Select
                  options={documentTypeList}
                  placeholder="Select Upload Type"
                  className={`w-full pt-1.5 ${
                    viewOnly ? "cursor-not-allowed" : ""
                  }`}
                  onChange={(e: any) => {
                    formik.setFieldValue("documentType", e?.value);
                    formik.setFieldValue("name", "");
                    setFile(undefined);
                  }}
                  name="documentType"
                  value={documentTypeList[formik.values.documentType]}
                />
                {formik.touched.documentType && formik.errors.documentType && (
                  <Error text={formik.errors.documentType} />
                )}
              </div>
              {formik.values.documentType === DocumentType.document && (
                <div className="flex-1 mt-6 ">
                  <Label className="text-sm font-normal mb-1 ">
                    Upload File <Required />
                  </Label>
                  <input
                    ref={fileRef2}
                    onChange={updateFile}
                    multiple={false}
                    type="file"
                    hidden
                  />
                  <HStack
                    onClick={onClickFileFunction}
                    className="items-center border cursor-pointer border-gray-201 w-full rounded-md "
                  >
                    <div className="h-10 px-4 font-bold bg-gray-200 rounded-md place-content-center">
                      Choose File
                    </div>
                    <div
                      className="text-center truncate pl-4"
                      style={{ maxWidth: "350px" }}
                    >
                      {formik.values.name || "No File Chosen"}
                    </div>
                    {file && (
                      <XMarkIcon
                        className="h-5 mt-1 font-medium cursor-pointer ml-auto mr-2 "
                        onClick={(e: any) => {
                          e.stopPropagation();
                          formik.setFieldValue("name", "");
                          setFile(undefined);
                        }}
                      />
                    )}
                  </HStack>
                  {formik.touched.name && formik.errors.name && (
                    <Error text={"Please select a file"} />
                  )}
                </div>
              )}

              {formik.values.documentType === DocumentType.link && (
                <div className="flex-1 mt-4 ">
                  <Label className="text-sm font-normal ">
                    Name
                    <Required />
                  </Label>
                  <Input
                    type="text"
                    placeholder="Enter Name"
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <Error text={formik.errors.name} />
                  )}
                  <Label className="text-sm font-normal mt-2 ">
                    Link
                    <Required />
                  </Label>
                  <Input
                    type="text"
                    placeholder="Enter Link"
                    {...formik.getFieldProps("key")}
                  />
                  {formik.touched.key && formik.errors.key && (
                    <Error text={formik.errors.key} />
                  )}
                </div>
              )}
            </VStack>
            <HStack className="justify-between mt-10">
              <SecondaryCTAButton
                event-name="Back Shareholder Profile"
                buttonSize={ButtonSize.SMALL}
                type="reset"
                onClick={onSecondaryAction}
              >
                Back
              </SecondaryCTAButton>
              {!viewOnly && (
                <PrimaryCTAButton
                  event-name="Save Shareholder Profile"
                  buttonSize={ButtonSize.SMALL}
                  type="submit"
                  loading={loading}
                >
                  Submit
                </PrimaryCTAButton>
              )}
            </HStack>
          </form>
        </>
      </VStack>
    </div>
  );
};

export default AddTransactionDocument;
