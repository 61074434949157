import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { HStack, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { useAuthStore } from "../../store";
import {
  ButtonSize,
  IconButtonType,
  IconCTAButton,
  IconPlacement,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import {
  useGetIndividualNonPricedRoundModel,
  useGetListOfModeling,
  useGetNonPricedRoundModelDetails,
  useListOfNonPricedRoundModel,
} from "../../queries/modeling";
import { useCanUserAccess } from "../../store/useRoleGrantStore";
import {
  ModelState,
  ModelingListModel,
  NonPricedRoundCardModel,
  NonPricedRoundModel,
} from "../../types/Modeling";
import ModelCardDesign from "../../components/ModelCard";
import { randomModelingData } from "../../queries/randomPlaceholderData";
import { useGetAllEvents } from "../../queries/captable";
import { useRoundModelStore } from "../../store/roundModelingStore";
import GenericTableHeader from "../../shared/TableHeader";
import EmptyPageIllustration from "../../shared/EmptyPageIllustration";
import EmptyTableIllustration from "../../shared/EmptyTableIllustration";
import { handleEventForTracking } from "../../amplitudeAnalytics";
import { CTADropdown } from "../../components/shared/Dropdown";
import { RoundModel } from "../../types/CapTable";
import NonPricedModelCardDesign from "../../components/NonPricedModelCard";

export default function ModelingDashboardPage() {
  const companyId = useAuthStore.getState().companyId || "";
  const { isPlaceholderData, data: _modelingData2 } = useGetListOfModeling();
  const roundCreationStore = useRoundModelStore();
  const _modelingData = randomModelingData();
  const navigate = useNavigate();
  const canUserCreateModeling = useCanUserAccess("create", "roundModeling");
  const currencySymbol = getCurrencySymbol();
  const currencyType = getCurrencyType();
  const { data: allEventsDetail } = useGetAllEvents(companyId);
  const isAuthenticated = useAuthStore().isAuthenticated;

  useEffect(() => {
    if (isAuthenticated) {
      roundCreationStore.connect();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    roundCreationStore.reset1();
  }, [allEventsDetail]);
  const [showMorePrivate, setShowMorePrivate] = useState(false);
  const [showMorePublic, setShowMorePublic] = useState(false);
  const [isPublicModelAvailable, setIsPublicModelAvailable] = useState(false);

  const { refetch: fetchModelingDetails, data: listOfnonPricedModelDetail } =
    useListOfNonPricedRoundModel();

  let _modelingPrivateList: ModelingListModel[] = [];
  _modelingPrivateList = useMemo(() => {
    try {
      if (!_modelingData2) return [];
      _modelingData2.sort((a: any, b: any) => b - a);
      if (!showMorePrivate) {
        // If the flag is true, return only the top 3 values
        return _modelingData2.slice(0, 3) ?? [];
      } else {
        // If the flag is false, return the entire array
        return _modelingData2 ?? [];
      }
    } catch (e) {
      return _modelingData2 ?? [];
    }
  }, [showMorePrivate, _modelingData2]);

  //Dirty Commit _modelingPublicList = useMemo(() => {
  //   try {
  //     if (!_modelingData2) return [];
  //     _modelingData2.sort((a: any, b: any) => {
  //       return b - a;
  //     });
  //     if (!showMorePrivate) {
  //       // If the flag is true, return only the top 3 values
  //       return _modelingData2.slice(0, 3);
  //     } else {
  //       // If the flag is false, return the entire array
  //       return _modelingData2;
  //     }
  //   } catch (e) {
  //     return _modelingData2;
  //   }
  // }, [showMorePrivate, _modelingData2]);

  return (
    <div className={isPlaceholderData ? "loading min-h-full" : "min-h-full"}>
      {(_modelingData?.length || 0) > 0 && (
        <VStack className="gap-8">
          <HStack className="items-center justify-between">
            <VStack>
              <BCHeader className="items-baseline" bcTitle="Round Modeling" />
              <p className="ml-3 -mt-4 font-medium sm:mt-0 w-150 sm:text-xs1 text-xxs text-descriptionColor md:mr-12">
                Build custom round models and analyze your Cap Table
                effortlessly. Get clear insights into how funding rounds change
                your company&apos;s equity distribution.
              </p>
            </VStack>

            {((canUserCreateModeling && (_modelingData2 ?? []).length > 0) ||
              listOfnonPricedModelDetail?.length > 0) && (
              <CreateNewModel
                actions={[
                  {
                    name: "Priced Round Model",
                    disabled: false,
                  },

                  {
                    name: "Non Priced Round Model",
                    disabled: false,
                  },
                ]}
                onAction={(action: any) => {
                  if (action.name === "Priced Round Model") {
                    roundCreationStore.reset1();
                    roundCreationStore.sendMessage({
                      companyId,
                      isNavigating: true,
                      currentTab: 1,
                      highestVisitedTabLevel: 0,
                    });
                    navigate("/modeling/roundmodel");
                  } else if (action.name === "Non Priced Round Model") {
                    navigate("/modeling/nonpricedroundmodel");
                  }
                }}
              />
            )}
          </HStack>

          <HStack className="items-baseline justify-between pb-1 border-b border-descriptionColor">
            <GenericTableHeader
              className="px-0"
              heading={"Round Models"}
              subHeading={`${
                _modelingPrivateList.length +
                (listOfnonPricedModelDetail?.length || 0)
              } round model`}
            />
            <VStack
              onClick={() => setShowMorePrivate(!showMorePrivate)}
              className="justify-between w-fit"
            >
              <div></div>
              <HStack className="text-xs font-semibold cursor-pointer text-descriptionColor">
                {showMorePrivate ? <p>Show Less</p> : <p>Show More</p>}

                <ChevronUpIcon
                  className={`w-4 h-4 ${
                    showMorePrivate ? "rotate-90" : "rotate-180"
                  }`}
                />
              </HStack>
            </VStack>
          </HStack>
          {(_modelingData2 ?? []).length === 0 &&
          (listOfnonPricedModelDetail ?? []).length === 0 ? (
            <VStack className="items-center">
              <HStack className="justify-center">
                <EmptyTableIllustration
                  text={
                    "Start modeling funding rounds and get clear Cap Table insights."
                  }
                />
              </HStack>
              <HStack>
                <div className="pr-5">
                  {canUserCreateModeling && (
                    <SecondaryCTAButton
                      event-name="Create New Model Button"
                      className="h-8 mt-4 text-xs2 leading w-fit"
                      buttonSize={ButtonSize.SMALL}
                      onClick={() => {
                        roundCreationStore.reset1();
                        roundCreationStore.sendMessage({
                          companyId,
                          isNavigating: true,
                          currentTab: 1,
                          highestVisitedTabLevel: 0,
                        });
                        navigate("/modeling/roundmodel");
                      }}
                    >
                      Create Priced Model
                    </SecondaryCTAButton>
                  )}
                </div>
                {canUserCreateModeling && (
                  <SecondaryCTAButton
                    event-name="Create New Model Button"
                    className="h-8 mt-4 text-xs2 leading w-fit"
                    buttonSize={ButtonSize.SMALL}
                    onClick={() => {
                      navigate("/modeling/nonpricedroundmodel");
                    }}
                  >
                    Create Non Priced Model
                  </SecondaryCTAButton>
                )}
              </HStack>
            </VStack>
          ) : (
            <HStack className="flex-col flex-wrap justify-start gap-4 overflow-auto scrollbar-hide sm:gap-8 sm:flex-row">
              {_modelingPrivateList.map((model: ModelingListModel) => (
                <ModelCardDesign
                  key={model.modelId}
                  data={model}
                  onClick={() => {
                    roundCreationStore.sendMessage({
                      companyId,
                      modelId: model.modelId,
                      modelState: ModelState.RetrieveModelData,
                      isNavigating: true,
                      currentTab: 1,
                    });
                    handleEventForTracking({
                      eventName: `Modeling Private Card`,
                    });
                    navigate(`/modeling/roundmodel`);
                  }}
                  currencySymbol={currencySymbol}
                  currencyType={currencyType}
                />
              ))}
              {listOfnonPricedModelDetail?.map(
                (model: NonPricedRoundCardModel) => (
                  <>
                    <NonPricedModelCardDesign
                      key={model.id}
                      data={model}
                      onClick={() => {
                        navigate(`/modeling/nonpricedroundmodel/${model.id}`);
                      }}
                      currencySymbol={currencySymbol}
                      currencyType={currencyType}
                    />
                  </>
                )
              )}
            </HStack>
          )}
          {isPublicModelAvailable && (
            <>
              <HStack
                className="items-center justify-between border-b border-descriptionColor pb-"
                onClick={() => setShowMorePublic(!showMorePublic)}
              >
                <p className="text-base font-semibold text-headerColor">
                  Shared Models
                </p>
                <HStack className="items-center text-xs font-semibold cursor-pointer text-descriptionColor">
                  {showMorePublic ? <p>Show Less</p> : <p>Show More</p>}

                  <ChevronUpIcon
                    className={`w-4 h-4 ${
                      showMorePublic ? "rotate-90" : "rotate-180"
                    }`}
                  />
                </HStack>
              </HStack>
              <HStack className="flex-wrap gap-8 pb-1 overflow-auto scrollbar-hide">
                {(_modelingData2 ?? []).map((model: ModelingListModel) => (
                  <ModelCardDesign
                    key={model.modelId}
                    data={model}
                    onClick={() => {
                      roundCreationStore.sendMessage({
                        companyId,
                        modelId: model.modelId,
                        modelState: ModelState.RetrieveModelData,
                        isNavigating: true,
                        currentTab: 1,
                      });
                      handleEventForTracking({
                        eventName: `Modeling Shared Card`,
                      });
                      navigate(`/modeling/roundmodel`);
                    }}
                    currencySymbol={currencySymbol}
                    currencyType={currencyType}
                  />
                ))}
              </HStack>
            </>
          )}
        </VStack>
      )}
    </div>
  );
}

export type Action = {
  name: string;
  disabled?: boolean;
};

function CreateNewModel({
  actions,
  onAction,
}: {
  actions: Action[];
  onAction: (action: Action) => void;
}) {
  return (
    <CTADropdown
      className="items-center"
      dropdownClassName="bottom-auto"
      actions={actions}
      onAction={onAction}
      render={() => (
        <div className="h-8 pt-[1px] bg-primary rounded-sm">
          <IconCTAButton
            value={"Create New Model"}
            buttonType={IconButtonType.FILLED}
            iconName={"ri:arrow-drop-down-line"}
            buttonSize={ButtonSize.SMALL}
            iconPlacement={IconPlacement.RIGHT}
          />
        </div>
      )}
    />
  );
}
