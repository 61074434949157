import { QueryFunctionContext } from "react-query";
import api from "../../api/capTableApi";
import { AgGridStateModel } from "./AgGridCaptable";

export interface FilterConditionModel {
  isFilterSave: boolean;
  isColumnSave: boolean;
}

export async function getOnFilterState(
  context: QueryFunctionContext
): Promise<any> {
  const userId = context.queryKey[1];
  const companyId = context.queryKey[2];

  // return api
  //   .get(
  //     `v1/utils/tableState?key=filter&userId=${userId}&companyId=${companyId}`
  //   )
  //   .then((res) => res.data.data);
}

export async function postOnFilterState({
  userId,
  companyId,
  filterData,
}: {
  userId: string;
  companyId: string;
  filterData: AgGridStateModel;
}): Promise<any> {
  // return api
  //   .put(
  //     `v1/utils/tableState?key=filter&userId=${userId}&companyId=${companyId}`,
  //     filterData
  //   )
  //   .then((res) => res.data);
}

export interface TableStateModel {
  filterState: any;
  columnState: any;
  isFilterSave: boolean;
  isColumnSave: boolean;
}

export async function getTableFilterState(
  context: QueryFunctionContext
): Promise<any> {
  const tableId = context.queryKey[1];
  return api
    .get(`v1/utils/tableState?id=${tableId}&platform=CAPTABLE`)
    .then((res) => res.data.data);
}

export async function postTableFilterState({
  tableId,
  companyId,
  filterData,
}: {
  tableId: string;
  companyId: string;
  filterData: TableStateModel;
}): Promise<any> {
  const postData = {
    id: tableId,
    data: filterData,
    companyId,
    platform: "CAPTABLE",
  };
  return api.put(`v1/utils/tableState`, postData).then((res) => res.data);
}

export async function deleteTableState(tableId: string): Promise<any> {
  return api
    .delete(`v1/utils/tableState?id=${tableId}&platform=CAPTABLE`)
    .then((res) => res.data);
}
