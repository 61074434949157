import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import _ from "lodash";
import { HStack, VStack, Error } from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
} from "../../utils/currencyRoboto";
import GenericTableHeader from "../../shared/TableHeader";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import {
  convertToNumber,
  filterOnlyNumbers,
  numberWithCommas,
} from "../../utils/numUtils";
import SecurityChart from "../modeling/SecurityChart";
import { Select } from "../../components/shared/Select";
import { PieChartSlider } from "../modeling/PieChartSlider";
import ConvertibleModelAgGridTable from "./ConvertibleModelAgGridTable";
import AgGridConvertibleHoldings from "./ConvertiblesHoldingAgGridTable";
import {
  ButtonSize,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { useSaveNonPricedRoundModel } from "../../queries/modeling";
import {
  ConvertibleHoldings,
  ConvertibleModel,
  NonPricedRoundModel,
} from "../../types/Modeling";
import { queryClient } from "../../queries/client";
import { useNewConvertibleModelStore } from "./ConvertibleModelStore";
import { SwitchButton } from "../../components/shared/SwitchButton";
import SharedPage from "../share/SharedPage";
import AlertMessage from "../../shared/AlertMessage";

export type NonPricedDatamodel = {
  companyId: string;
  id: string;
  baseCapTableId: string;
  state: string;
  eventId: null;
  createdAt: string;
  updatedAt: string;
  isDeleted: false;
  draftData: NonPricedRoundModel;
  type: "NonPricedRound";
};

export default function NextPriceRoundComponent({
  nonPricedModel,
  modelId,
  modelName,
  convertibleTableDetails,
  convertiblesDataChanged,
  setConvertiblesDataChanged,
  sharedPage,
}: {
  nonPricedModel: NonPricedDatamodel;
  modelId: string;
  modelName: string;
  convertibleTableDetails: ConvertibleModel[];
  convertiblesDataChanged: boolean;
  setConvertiblesDataChanged: Function;
  sharedPage: boolean;
}) {
  const symbol = getCurrencySymbolRoboto();
  const navigate = useNavigate();

  const currencyType = getCurrencyType();

  const [nonPricedRoundModel, setNonPricedRoundModel] =
    useState<NonPricedRoundModel>(nonPricedModel?.draftData);

  const { mutate: saveModeling } = useSaveNonPricedRoundModel();

  const [onHoverRoundSize, setOnHoverRoundSize] = useState(false);
  const [onHoverPreMoneyValuation, setOnHoverPreMoneyValuation] =
    useState(false);

  const [onHoverEsopPercentage, setOnHoverEsopPercentage] = useState(false);

  const [formatEsopPercentage, setFormatEsopPercentage] = useState("");
  const [formatRoundSize, setFormatRoundSize] = useState("");

  const [formatPreMoneyValuation, setformatPreMoneyValuation] = useState("");

  const initialValues = {
    roundSize: nonPricedModel?.draftData?.roundSize || 0,
    esopPercentage: nonPricedModel?.draftData?.esopPercentage || 0,
    preMoneyValuation: nonPricedModel?.draftData?.preMoneyValuation || 0,
    conversionMethod: nonPricedModel?.draftData?.conversionMethod || "",
    autoDiluteEsop: nonPricedModel?.draftData?.autoDiluteEsop || false,
  };

  const convertibleModelStore = useNewConvertibleModelStore();

  useEffect(() => {
    if (initialValues.roundSize) {
      setFormatRoundSize(`${initialValues.roundSize}`);
    }
    if (initialValues.esopPercentage) {
      setFormatEsopPercentage(`${initialValues.esopPercentage}`);
    }
    if (initialValues.preMoneyValuation) {
      setformatPreMoneyValuation(`${initialValues.preMoneyValuation}`);
    }
    if (initialValues.conversionMethod) {
      formik.setFieldValue("conversionMethod", initialValues.conversionMethod);
    }
    if (initialValues.autoDiluteEsop) {
      formik.setFieldValue("autoDiluteEsop", initialValues.autoDiluteEsop);
    }
  }, [
    initialValues.roundSize,
    initialValues.esopPercentage,
    initialValues.preMoneyValuation,
    initialValues.conversionMethod,
    initialValues.autoDiluteEsop,
  ]);

  const validationSchema = Yup.object().shape({
    roundSize: Yup.number().required().label("Round Size"),
    esopPercentage: Yup.number().required().label("EOP Percentage"),
    preMoneyValuation: Yup.number().required().label("Pre Money Valuation"),
    conversionMethod: Yup.string().required().label("Conversion Method"),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {},
  });

  const [holdingData, setHoldingData] = useState<ConvertibleHoldings[]>([]);

  const [sliderValue, setSliderValue] = useState(0);

  useEffect(() => {
    setSliderValue(formik.values.preMoneyValuation);
  }, [formik.values.preMoneyValuation]);

  useEffect(() => {
    calculateHoldings();
  }, [
    sliderValue,
    formik.values.roundSize,
    formik.values.esopPercentage,
    formik.values.autoDiluteEsop,
    formik.values.conversionMethod,
    convertiblesDataChanged,
    convertibleTableDetails,
  ]);

  function calculateHoldings() {
    const model = {
      ...nonPricedRoundModel,
      preMoneyValuation: formik.values.preMoneyValuation,
      roundSize: formik.values.roundSize,
      autoDiluteEsop: formik.values.autoDiluteEsop,
      esopPercentage: formik.values.esopPercentage,
      conversionMethod: formik.values.conversionMethod,
      convertibles: convertibleTableDetails,
    };
    const updatedModel = calculateConvertibleHoldings(model, sliderValue);
    setNonPricedRoundModel(updatedModel);

    const investorHolding = updatedModel.investorHolding;
    const holdingDataValues: ConvertibleHoldings[] = [];
    holdingDataValues.push({
      category: "Existing Investors",
      percentage: investorHolding?.preRoundInvestorHolding ?? 0,
      fdbShares: investorHolding?.preRoundInvestorFdbShares ?? 0,
    });
    holdingDataValues.push({
      category: "New Investors",
      percentage: investorHolding?.newInvestorHolding ?? 0,
      fdbShares: investorHolding?.newInvestorFdbShares ?? 0,
    });
    investorHolding?.convertibleInvestorHolding?.forEach((convertible) => {
      holdingDataValues.push({
        category: `Convertible (${convertible.name})`,
        percentage: convertible?.holdingPercentage ?? 0,
        fdbShares: convertible?.fdbShares ?? 0,
        conversionPrice: convertible?.conversionPrice ?? 0,
      });
    });
    holdingDataValues.push({
      category: "ESOP",
      percentage: investorHolding?.esopPercentage ?? 0,
      fdbShares: investorHolding?.esopShares ?? 0,
    });
    setHoldingData(holdingDataValues);
    setConvertiblesDataChanged(false);
  }

  const validHoldingData = holdingData.filter((holding) =>
    Number.isNaN(holding.percentage)
  );
  return (
    <>
      <div className="py-4 bg-white rounded-lg ">
        <GenericTableHeader
          heading={"Next Priced Round Parameters"}
          subHeading={"Edit the values of the parameter to change the graph"}
        />
      </div>
      <div className="justify-between w-full px-4 py-4 bg-white shadow-box">
        <div className="border-b border-solid border-[#EBEBEB] mb-8"></div>
        <HStack className="grid w-full gap-8 pl-2 mx-auto md:grid-cols-3 sm:grid-cols-1">
          <VStack className="flex-1">
            <Label className="w-48 text-sm font-medium whitespace-nowrap">
              Round Size ({symbol})
            </Label>
            <VStack>
              <Input
                onMouseEnter={() => {
                  setOnHoverRoundSize(true);
                }}
                onMouseLeave={() => {
                  setOnHoverRoundSize(false);
                }}
                className="w-64 text-sm font-medium whitespace-nowrap"
                type="text"
                placeholder="Enter the round size"
                {...formik.getFieldProps("roundSize")}
                onChange={(e: any) => {
                  const filteredValue = filterOnlyNumbers(e.target.value);
                  setFormatRoundSize(filteredValue);
                  const values = convertToNumber(filteredValue);

                  formik.setFieldValue("roundSize", values);
                  formik.handleChange("roundSize");
                }}
                value={numberWithCommas(formatRoundSize, currencyType)}
              />
              {onHoverRoundSize && (
                <ShowNumberInWords
                  value={formik.values.roundSize}
                  currency={currencyType}
                />
              )}
              {formik.touched?.roundSize && formik.errors?.roundSize && (
                <Error text={formik.errors?.roundSize} />
              )}
            </VStack>
          </VStack>

          <VStack className="flex-1">
            <Label className="w-48 text-sm font-medium whitespace-nowrap">
              Pre Money Valuation ({symbol})
            </Label>
            <VStack>
              <Input
                onMouseEnter={() => {
                  setOnHoverPreMoneyValuation(true);
                }}
                onMouseLeave={() => {
                  setOnHoverPreMoneyValuation(false);
                }}
                className="w-64 text-sm font-medium whitespace-nowrap"
                type="text"
                placeholder="Enter Pre Money Valuation"
                {...formik.getFieldProps("preMoneyValuation")}
                onChange={(e: any) => {
                  const filteredValue = filterOnlyNumbers(e.target.value);
                  setformatPreMoneyValuation(filteredValue);
                  const values = convertToNumber(filteredValue);

                  formik.setFieldValue("preMoneyValuation", values);
                  formik.handleChange("preMoneyValuation");
                }}
                value={numberWithCommas(formatPreMoneyValuation, currencyType)}
              />

              {onHoverPreMoneyValuation && (
                <ShowNumberInWords
                  value={formik.values.preMoneyValuation}
                  currency={currencyType}
                />
              )}
              {formik.touched?.preMoneyValuation &&
                formik.errors?.preMoneyValuation && (
                  <Error text={formik.errors?.preMoneyValuation} />
                )}
            </VStack>
          </VStack>

          <VStack className="flex-1">
            <VStack>
              <Label className="text-[#464E5F] font-medium whitespace-nowrap pb-2">
                Conversion Method
              </Label>
              <Select
                className="lg:w-64 sm:w-64"
                options={["Pre Money", "Post Money", "Investment Method"]}
                {...formik.getFieldProps("conversionMethod")}
                onChange={(e: any) => {
                  formik.setFieldValue("conversionMethod", e.target.value);
                  formik.handleChange("conversionMethod");
                }}
              />
              {formik.touched?.conversionMethod &&
                formik.errors?.conversionMethod && (
                  <Error text={formik.errors?.conversionMethod} />
                )}
            </VStack>
          </VStack>

          <VStack>
            <div className="flex flex-row-reverse items-center justify-end gap-4 mt-6 text-sm font-medium text-gray-600">
              <SwitchButton
                className="items-center m-1 text-sm font-medium whitespace-nowrap"
                value={formik.values.autoDiluteEsop}
                label="Auto Dilute ESOP"
                onClick={() => {
                  formik.setFieldValue(
                    "autoDiluteEsop",
                    !formik.values.autoDiluteEsop
                  );
                }}
              />
            </div>
          </VStack>

          {!formik.values.autoDiluteEsop && (
            <VStack className="flex-1">
              <Label className="w-48 text-sm font-medium whitespace-nowrap">
                ESOP Percentage (%)
              </Label>
              <VStack>
                <Input
                  onMouseEnter={() => {
                    setOnHoverEsopPercentage(true);
                  }}
                  onMouseLeave={() => {
                    setOnHoverEsopPercentage(false);
                  }}
                  className="w-64 text-sm font-medium whitespace-nowrap"
                  type="text"
                  placeholder="Enter Esop Percentage (%)"
                  onChange={(e: any) => {
                    const filteredValue = filterOnlyNumbers(e.target.value);
                    setFormatEsopPercentage(filteredValue);
                    const values = convertToNumber(filteredValue);

                    formik.setFieldValue("esopPercentage", values);
                    formik.handleChange("esopPercentage");
                  }}
                  value={numberWithCommas(formatEsopPercentage, currencyType)}
                />
                {onHoverEsopPercentage && (
                  <ShowNumberInWords
                    value={formik.values.esopPercentage}
                    currency={currencyType}
                  />
                )}
                {formik.touched?.esopPercentage &&
                  formik.errors?.esopPercentage && (
                    <Error text={formik.errors?.esopPercentage} />
                  )}
              </VStack>
            </VStack>
          )}
        </HStack>
        <HStack className="grid justify-between w-full md:grid-cols-2 sm:grid-cols-1">
          {sliderValue >= 0 &&
            validHoldingData.length === 0 &&
            nonPricedRoundModel?.pps !== Infinity &&
            (nonPricedRoundModel?.pps ?? 0) > 0 && (
              <>
                <div className="w-full py-10">
                  <PieChartSlider
                    securityData={formik.values}
                    holdingData={holdingData}
                    sliderValue={sliderValue}
                    setSliderValue={setSliderValue}
                    currencyType={currencyType}
                    pps={nonPricedRoundModel?.pps || 0}
                  />
                </div>
                <div className="w-3/4 py-10">
                  <AgGridConvertibleHoldings
                    convertibleHoldingsTableData={holdingData}
                  />
                </div>
              </>
            )}
        </HStack>
        <div className="flex justify-end py-2">
          {(sliderValue < 0 ||
            validHoldingData.length > 0 ||
            nonPricedRoundModel?.pps === Infinity ||
            nonPricedRoundModel.pps! < 0) && (
            <AlertMessage
              heading="Invalid Result Values Detected"
              data="The result contains invalid values. Please check your input and try again."
            />
          )}
        </div>

        {!sharedPage && (
          <div className="flex justify-end pb-4 pr-4 bg-white">
            <PrimaryCTAButton
              className="h-8"
              event-name="Save Model Button"
              onClick={() => {
                setTimeout(() => {
                  queryClient.refetchQueries("getListOfNonPricedRoundModel");
                }, 1000);

                toast(`Saved Non Priced round model successfully`, {
                  type: "success",
                  autoClose: 2000,
                });
                saveModeling({
                  nonPricedRoundModel: { ...nonPricedRoundModel, modelName },
                  modelId,
                });
                navigate(`/modeling/overview`);
              }}
              buttonSize={ButtonSize.SMALL}
              disabled={
                modelName === "" ||
                sliderValue < 0 ||
                validHoldingData.length > 0 ||
                nonPricedRoundModel?.pps === Infinity ||
                nonPricedRoundModel.pps! < 0
              }
            >
              Save Model
            </PrimaryCTAButton>
          </div>
        )}
      </div>
    </>
  );
}

export function calculateConvertibleHoldings(
  nonPricedmodel: NonPricedRoundModel,
  adjustedPreMoneyValuation: number
) {
  let newPreMoney = 0;
  let postMoney = 0;

  if (nonPricedmodel?.convertibles?.length === 0) return nonPricedmodel;

  const preMoney =
    adjustedPreMoneyValuation || nonPricedmodel.preMoneyValuation;

  const existingEsop = nonPricedmodel.preEsop;

  const totalNoteFdb = nonPricedmodel.totalNoteFdb;

  const noteAmount =
    nonPricedmodel?.convertibles && nonPricedmodel?.convertibles.length > 0
      ? _.sumBy(
          nonPricedmodel?.convertibles.filter(
            (convertible) => !convertible.excluded
          ),
          (convertible) => convertible.investment
        )
      : 0;

  const roundSize = nonPricedmodel.roundSize;

  let allNoteValue = 0;

  for (const convertible of nonPricedmodel.convertibles || []) {
    if (!convertible.excluded) {
      const { noteCurrentValue: noteValue } = getNoteValueFromSecurity(
        nonPricedmodel,
        convertible.securityName
      );
      allNoteValue += noteValue;
    }
  }

  const postEsop = nonPricedmodel.autoDiluteEsop
    ? 0
    : (nonPricedmodel.esopPercentage || 0) / 100;

  if (nonPricedmodel.conversionMethod === "Pre Money") {
    postMoney = preMoney + (roundSize || 0) + allNoteValue;
    newPreMoney = preMoney;
  } else if (nonPricedmodel.conversionMethod === "Post Money") {
    postMoney = preMoney + (roundSize || 0);
    newPreMoney = postMoney - (roundSize || 0) - allNoteValue;
  } else if (nonPricedmodel.conversionMethod === "Investment Method") {
    postMoney = preMoney + (roundSize || 0) + (noteAmount || 0);
    newPreMoney = postMoney - (roundSize || 0) - allNoteValue;
  }
  const preFdbShares = nonPricedmodel.preFdbShares;

  const fdbShares = preFdbShares - totalNoteFdb; //Exclude note fdb in pre money fdb shares

  let pps = newPreMoney / (fdbShares || 1);

  const fdbWithoutEsops = fdbShares - existingEsop;
  const totalEsopShares = Math.floor(
    (fdbWithoutEsops /
      (1 - roundSize / postMoney - postEsop - allNoteValue / postMoney)) *
      postEsop
  );
  const newEsopShares = totalEsopShares - existingEsop;

  pps = newPreMoney / ((fdbShares || 1) + newEsopShares);

  let otherNoteFdb = 0;

  for (const convertible of nonPricedmodel.convertibles || []) {
    if (!convertible.excluded) {
      const { noteFdb } = getNoteValueFromSecurity(
        nonPricedmodel,
        convertible.securityName,
        pps
      );
      otherNoteFdb += noteFdb || 0;
    }
  }

  let totalFdb = (fdbShares || 0) + otherNoteFdb;

  totalFdb += newEsopShares + Math.floor((roundSize || 0) / pps);

  nonPricedmodel.newPostMoneyValuation = postMoney;
  nonPricedmodel.newPreMoneyValuation = newPreMoney;

  nonPricedmodel.investorHolding = {
    preMoneyValuation: preMoney,
    preRoundInvestorHolding: (fdbWithoutEsops / totalFdb) * 100,
    preRoundInvestorFdbShares: fdbWithoutEsops,
    convertibleInvestorHolding: [],
    newInvestorHolding:
      (Math.floor(nonPricedmodel.roundSize / pps) / totalFdb) * 100,
    newInvestorFdbShares: Math.floor(nonPricedmodel.roundSize / pps),
    esopPercentage: (totalEsopShares / totalFdb) * 100,
    esopShares: totalEsopShares,
  };

  for (const convertible of nonPricedmodel.convertibles || []) {
    if (!convertible.excluded) {
      const { noteCurrentValue, noteFdb, conversionPrice } =
        getNoteValueFromSecurity(nonPricedmodel, convertible.securityName, pps);

      nonPricedmodel.investorHolding.convertibleInvestorHolding.push({
        name: convertible.securityName,
        holdingPercentage: ((noteFdb || 0) / totalFdb) * 100,
        fdbShares: noteFdb || 0,
        noteValue: noteCurrentValue,
        conversionPrice: conversionPrice || 0,
      });
    }
  }

  nonPricedmodel.pps = pps;

  return nonPricedmodel;
}

function getNoteValueFromSecurity(
  nonPricedmodel: NonPricedRoundModel,
  securityName: string,
  newPPS?: number
) {
  const security = nonPricedmodel.convertibles.find(
    (convertible) => convertible.securityName === securityName
  );

  if (!security) return { noteCurrentValue: 0 };

  const noteAmount = security?.investment || 0;
  const preMoneyValuation = nonPricedmodel.preMoneyValuation;

  const valuationApplied = Math.min(
    (1 - (security.discount || 0) / 100) * preMoneyValuation,
    security.valuationCap || 0
  );

  const noteCurrentValue =
    preMoneyValuation * ((noteAmount || 0) / valuationApplied);

  const conversionPrice = newPPS
    ? Math.min(
        newPPS,
        newPPS * (1 - (security.discount || 0) / 100),
        newPPS * ((security.valuationCap || 0) / preMoneyValuation)
      )
    : 0;

  const noteFdb = conversionPrice
    ? Math.floor(noteAmount / conversionPrice)
    : 0;

  return { noteCurrentValue, conversionPrice, noteFdb };
}
