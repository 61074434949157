import { memo, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import {
  CellClickedEvent,
  ColDef,
  SideBarDef,
  GridApi,
} from "ag-grid-community";
import { Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { AllTransactionModel } from "../../types/AllTransactionModel";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  getCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { useAuthStore } from "../../store/useAuthStore";
import { Box, HStack, VStack, useGetCompanyName } from "../../components/utils";
import { checkTypeOfRound } from "./RoundsUtility";
import QuickSearch from "./QuickSearch";
import "../../styles/reset.css";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import GenericTableHeader from "../../shared/TableHeader";
import { tableCellStyle } from "../../components/TableComponent";
import {
  exportRoundSummaryReport,
  exportSecondarySummaryReport,
} from "../../api/Report";
import { downloadExcel } from "../../utils/DownloadFile";
import ExportMultidateSheetDetails from "../../modals/ExportMultidateSheetDetails";
import { useAgGridTableState } from "../../store/agGridTableStore";
import {
  PrimaryTransactionDataModel,
  SecondaryTransactionDataModel,
} from "../../types/quickRound";
import {
  BuyerNameRender,
  SellerNameRender,
  SecondarySecurityTypeRender,
  SecondarySharePriceRender,
  SecondaryAmountInvestedRender,
  SecondaryNumberOfSharesRender,
  SecondaryDateRender,
  SecondaryAmountInInvestmentRender,
} from "./RoundsTransactionAgComponent";
import SearchInput from "../../components/shared/SearchInput";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { handleEventForTracking } from "../../amplitudeAnalytics";

function AGGridSecondaryRoundTransactionTable({
  isInvestorPortal = false,
  isPreviewOnly = false,
  primaryTransactionProps,
  secondaryTransactionProps,
  isModeling = false,
}: {
  isPreviewOnly: boolean;
  isInvestorPortal?: boolean;
  primaryTransactionProps: PrimaryTransactionDataModel[];
  secondaryTransactionProps: SecondaryTransactionDataModel[];
  isModeling?: boolean;
}) {
  const currencySymbol = getCurrencySymbol();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const companyName = useGetCompanyName();
  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: string;
  }>({
    open: false,
  });
  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.roundSecondarytransactionColumnModel);
      setFilterSetting(data.data?.roundSecondarytransactionFilterModel);
    });
  }, []);
  const { mutate: postOnFilter } = usePostOnFilterState();
  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }
  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }
  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };
  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };
  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        roundSecondarytransactionColumnModel: columnState,
        roundSecondarytransactionFilterModel: filterState,
      },
    });
  };
  const componentsRegistery = useMemo(
    () => ({
      buyerName: memo(BuyerNameRender),
      sellerName: memo(SellerNameRender),
      securityType: memo(SecondarySecurityTypeRender),
      sharePrice: memo(SecondarySharePriceRender),
      amountInvested: memo(SecondaryAmountInvestedRender),
      amountInInvestment: memo(SecondaryAmountInInvestmentRender),
      numberOfShares: memo(SecondaryNumberOfSharesRender),
      dateOfTransaction: memo(SecondaryDateRender),
    }),
    []
  );
  const navigate = useNavigate();
  const onClickBuyerName = (id: string) => {
    if (!isInvestorPortal) {
      handleEventForTracking({ eventName: "Buyer Name" });
      navigate(`/shareholders/individual/${id}`);
    }
  };
  const onClickSellerName = (id: string) => {
    if (!isInvestorPortal) {
      handleEventForTracking({ eventName: "Seller Name" });
      navigate(`/shareholders/individual/${id}`);
    }
  };
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Buyer Name",
        field: "buyerName",
        filter: "agMultiColumnFilter",
        getQuickFilterText: (params) => params.value.name,
        comparator(valueA, valueB, ..._: any[]) {
          return valueA.name > valueB.name ? 1 : -1;
        },
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Name",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.name,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) => params.getValue("buyerName").name,
              },
            },
            {
              title: "Type",
              filter: "agSetColumnFilter",
              display: "subMenu",
              buttons: ["reset"],
              filterParams: {
                buttons: ["reset"],
                keyCreator: (params: any) => {
                  const type = params.value.type;
                  return type;
                },
                valueFormatter: (params: any) => {
                  const type = params.value.type;
                  return type;
                },
              },
            },
          ],
        },
        pinned: "left",
        cellRenderer: "buyerName",
        initialWidth: 250,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipBuyerName",
      },
      {
        headerName: "Seller Name",
        field: "sellerName",
        cellRenderer: "sellerName",
        cellStyle: tableCellStyle,
        sortable: false,
        filter: "agTextColumnFilter",
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipSellerName",
      },
      {
        headerName: "Security Type",
        field: "securityType",
        cellRenderer: "securityType",
        cellStyle: tableCellStyle,
        sortable: false,
        // valueGetter: (params) => params.data.securityType,
        // valueFormatter: (params) => params.data.securityType,
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Securities",
        field: "numberOfShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberOfShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipNumberOfShares",
      },
      {
        headerName: `Transaction Price (${currencySymbol})`,
        field: "sharePrice",
        filter: "agNumberColumnFilter",
        cellRenderer: "sharePrice",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tootipSharePrice",
      },
      {
        headerName: `Transaction Amount (Company Currency)(${currencySymbol})`,
        minWidth: 250,
        field: "amountInvested",
        filter: "agNumberColumnFilter",
        cellRenderer: "amountInvested",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAmountInvested",
      },
      {
        headerName: `Amount Invested`,
        field: "amountInInvestment",
        filter: "agMultiColumnFilter",
        cellRenderer: "amountInInvestment",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAmountInInvestment",
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Amount in Transaction Currency",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.investmentAmount,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) =>
                  params.getValue("amountInInvestment").investmentAmount,
              },
            },
            {
              title: "Amount in Company Currency",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.amountInvested,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) =>
                  params.getValue("amountInInvestment").amountInvested,
              },
            },
          ],
        },
      },
      {
        headerName: "Date",
        field: "dateOfTransaction",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "dateOfTransaction",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
    ],
    []
  );

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };
  async function handleAction(action: Action) {
    if (action.disabled) return;
    if (action.name === "Round Summary Report") {
      exportRoundSummaryReport("").then((res) => {
        const fileName = `Round Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "Secondary Summary Report") {
      exportSecondarySummaryReport().then((res) => {
        const fileName = `Secondary Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "All Transaction Summary Report") {
      setDialog({
        open: true,
        data: "All Transaction Summary Report",
      });
    }
  }
  const shareholderInstruments = useShareholderInstrumentStore();

  const currencyType = getCurrencyType();
  const rowData = useMemo(
    () =>
      secondaryTransactionProps?.map((template) => ({
        buyerName: {
          name: template.buyerName,
          type:
            shareholderInstruments.shareholders.find(
              (shareholder) => shareholder.id === template.buyerInvestorId
            )?.type ?? "Others",
        },
        id:
          (template.buyerInvestorId ?? "") +
          (template.sellerInvestorId ?? "") +
          (template.instrumentClassId ?? ""),
        buyerId: template.buyerInvestorId,
        sellerId: template.sellerInvestorId,
        tooltipBuyerName: template.buyerName,
        sellerName: template.sellerName,
        tooltipSellerName: template.sellerName,
        securityType: template.security,
        numberOfShares: template.numberOfShares,
        tooltipNumberOfShares:
          template.numberOfShares?.toLocaleString(currencyType),
        sharePrice: template.sharePrice,
        tootipSharePrice: (
          (template.amountInvested || 0) / (template.numberOfShares || 0)
        )?.toLocaleString(currencyType),
        tooltipAmountInvested:
          template.amountInvested?.toLocaleString(currencyType),
        dateOfTransaction: template.date,
        amountInvested: template.amountInvested,
        amountInInvestment: {
          investmentAmount: template.amountInCurrency,
          amountInvested: template.amountInvested,
          investmentCurrency: template.transactionCurrency,
          exchangeRate: template.exchangeRate,
        },
        tooltipAmountInInvestment:
          currencyType ===
          getCurrency(template.transactionCurrency ?? currencyType)
            ? template.amountInvested.toLocaleString(currencyType)
            : "-----",
        par: template.par,
        conversionRatio: template.conversionRatio,
        currencyType,
        currencySymbol,
      })),
    [secondaryTransactionProps]
  );
  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (
        cellParams.value.name !== "Total" &&
        cellParams.column.getColId() === "buyerName"
      ) {
        onClickBuyerName(template.buyerId);
      }
      if (cellParams.column.getColId() === "sellerName") {
        onClickSellerName(template.sellerId);
      }
    }
  };
  const contextRef = useRef<HTMLDivElement>(null);
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const gridContainerRef = useRef(null);
  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key);
        } else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };
  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );
    const totalShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.numberOfShares,
      0
    );
    const totalAmount = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.amountInvested,
      0
    );
    api.setPinnedBottomRowData([
      {
        buyerName: {
          name: "Total",
          type: null,
        },
        securityType: null,
        amountInvested: totalAmount,
        amountInInvestment: {
          investmentAmount: null,
          amountInvested: null,
          investmentCurrency: null,
          exchangeRate: null,
        },
        tooltipAmountInvested: totalAmount.toLocaleString(currencyType),
        tootipSharePrice: null,
        sharePrice: null,
        investorType: null,
        par: null,
        conversionRatio: null,
        numberOfShares: totalShares,
        tooltipNumberOfShares: totalShares.toLocaleString(currencyType),
        sellerName: null,
        dateOfTransaction: null,
        currencyType,
        currencySymbol,
      },
    ]);
  }
  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);
    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    setPinnedBottomRowData(grid);
    uploadFilterAndColumn();
  };
  return (
    <>
      <HStack className="items-center justify-end py-2 bg-white rounded-md">
        <HStack className="gap-4 py-2 ">
          {/* <QuickSearch
            placeholder={`Search`}
            onChange={(e) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          /> */}
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${
              (rowData.length >= 10 ? 10 : rowData.length + 3) * 58
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            onRowDataUpdated={setPinnedBottomRowData}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            enableCharts={true}
            enableRangeSelection={true}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed"}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </>
  );
}
export default AGGridSecondaryRoundTransactionTable;
