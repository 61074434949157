import React, { useState, useRef, useEffect } from "react";

const EmailInputBox = ({
  input,
  onChange,
}: {
  input: string[];
  onChange: (data: any) => void;
}) => {
  const [emailInput, setEmailInput] = useState("");
  const [emails, setEmails] = useState<string[]>([]);
  useEffect(() => {
    setEmails(input);
  }, [input]);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      // Update input box width dynamically based on content
      inputRef.current.style.width = "auto";
      inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
    }
  }, [emailInput]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.key === "," || event.key === " ") {
      event.preventDefault();
      const newEmail = emailInput.trim();
      if (newEmail && !emails.includes(newEmail) && validateEmail(newEmail)) {
        setEmails([newEmail, ...emails]);
        onChange([newEmail, ...emails]);
        setEmailInput("");
      }
    }
  };

  function validateEmail(email: any) {
    // Regular expression pattern for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the pattern
    return emailPattern.test(email);
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.includes(" ")) {
      const newEmail = event.target.value.trim();
      if (newEmail && !emails.includes(newEmail)) {
        setEmails([newEmail, ...emails]);
        onChange([newEmail, ...emails]);
        setEmailInput("");
      }
    } else setEmailInput(event.target.value);
  };

  const handleRemoveEmail = (index: number) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  return (
    <div className="relative w-full">
      <div className="flex flex-wrap items-center gap-2 border focus-within:border-secondary/50">
        {emails.map((email, index) => (
          <span
            key={index}
            className="inline-flex items-center px-2.5 ml-2 my-0.5 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800"
          >
            {email}
            <button
              type="button"
              className="ml-1 focus:outline-none hover:text-red-600"
              onClick={() => handleRemoveEmail(index)}
              aria-label={`Remove ${email}`}
            >
              &times;
            </button>
          </span>
        ))}
        <input
          ref={inputRef}
          type="text"
          placeholder="Add email..."
          className="flex-1 px-3 py-2 text-sm focus:outline-none"
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
          value={emailInput}
          style={{
            border: "none",
            outline: "none",
            boxShadow: "none", // Remove any box shadows
          }}
        />
      </div>
    </div>
  );
};

export default EmailInputBox;
