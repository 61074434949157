import _ from "lodash";
import { RoundModel } from "../types/CapTable";
import { CompanyDetailsGetModel } from "../types/CompanyDetail";
import {
  CumulativeHolding,
  DashBoardChartModel,
  DashBoardModel,
  CapTable as DashBoardCaptable,
  Transactions as DashboardTransactions,
  InvestmentRecord,
  Info2,
  InstrumentClass,
  Info,
  RoundSummary,
  SecurityDetails,
} from "../types/DashboardModel";
import {
  DashboardCardDetails,
  InstrumentClassSubTypes,
  Shareholder,
  ShareholderInstrumentModel,
} from "../types/quickRound";
import {
  LiquidationStack,
  SecurityDataModel,
  SecurityDetail,
  SecurityTableDetail,
  SecurityTransaction,
} from "../types/SecurityModel";
import {
  BeneficiaryModel,
  IndividualShareholderModel,
  ShareholderDataModel,
  ShareholderRangeModel,
  ShareholderSecurityModel,
  ShareholderTransactionModel,
} from "../types/Shareholder";
import {
  AllTransactionModel,
  SecurityTypeModel,
  IndividualBuybackCapTable,
  IndividualBuybackModel,
  IndividualRoundCapTable,
  IndividualRoundsModel,
  PrimaryTransactionDataModel,
  SecondaryTransactionDataModel,
  Transactions,
  IndividualBonusCapTable,
  IndividualBonusModel,
  RoundDetailModel,
  IndividualSplitCapTable,
  IndividualSplitModel,
  IndividualSecondaryCapTable,
  IndividualSecondaryModel,
  SecondaryInvestors,
  DocumentType,
} from "../types/AllTransactionModel";
import {
  RoundModelCaptableInvestor,
  RoundModelEvent,
  SecondaryRoundModelEvent,
} from "../types/RoundModel";
import {
  AllTermsheetModel,
  IndividualTermSheetModel,
  TermsheetData,
  TermsheetStatus,
} from "../types/TermsheetModel";
import { IndividualConversionModel } from "../types/ConversionRedemptionModel";
import { ModelingListModel } from "../types/Modeling";
import { TransactionDocTypes } from "../pages/newQuickTransaction/TransactionDocumentsAgGridTable";

const shareholderRangeModelData = (): ShareholderRangeModel => ({
  instrumentClasses: [
    {
      instrumentClassId: "x".padStart(_.random(6, 8), "x"),
      numberOfShares: _.random(1000, 2000),
      shareRanges: [],
    },
  ],
  cInvestorId: "x".padStart(_.random(6, 8), "x"),
});

const roundModelData = (): RoundModel => ({
  id: `${_.random(100000, 2000000)}`,
  name: "x".padStart(_.random(6, 8), "x"),
  description: "x".padStart(_.random(6, 8), "x"),
  state: "x".padStart(_.random(6, 8), "x"),
  type: "x".padStart(_.random(6, 8), "x"),
});

const allTermsheetModelData = (): TermsheetData => ({
  id: `${_.random(100000, 2000000)}`,
  termsheetName: "x".padStart(_.random(6, 8), "x"),
  termsheetData: {
    investors: [
      {
        companyInvestorId: `${_.random(1000, 2000)}`,
        shareholderName: `Investor ${_.random(1, 100)}`,
        shareholderType: "VC Firm",
        securityType: "Preferred",
        investmentAmount: _.random(1000000, 5000000),
        securityName: `Series ${String.fromCharCode(
          _.random(65, 90)
        )} Preferred`,
        noOfShares: _.random(1000, 10000),
        fdbShares: _.random(0, 1000),
        percentageOfShares: _.random(1, 10),
        country: "USA",
        dateOfInvestment: new Date(
          _.random(2020, 2024),
          _.random(0, 11),
          _.random(1, 28)
        )
          .toISOString()
          .split("T")[0],
        pricePerShare: _.random(10, 100),
        address: `123 Investor St, City ${_.random(1, 50)}, State ${_.random(
          1,
          50
        )}`,
        conversionRatio: "1:1",
        parValue: _.random(0.01, 1.0),
        votingRatio: _.random(1, 2),
        votingRight: "Yes",
        investmentCurrency: "USD",
        isConvertible: true,
        discount: _.random(10, 30),
        valuationCap: _.random(50000000, 100000000),
        instrumentClassId: `class-${_.random(1000, 2000)}`,
      },
    ],
    roundDetails: {
      roundSize: _.random(5000000, 20000000),
      preMoneyValuation: _.random(30000000, 70000000),
      postMoneyValuation: _.random(40000000, 80000000),
      pricedRound: _.sample([true, false])!,
      closeDate: new Date(
        _.random(2024, 2025),
        _.random(0, 11),
        _.random(1, 28)
      )
        .toISOString()
        .split("T")[0],
      postRoundEsopPercentage: _.random(5, 20),
      roundName: `Series ${String.fromCharCode(_.random(65, 90))}`,
      roundId: `round-${_.random(1000, 2000)}`,
      roundCurrency: _.sample(["USD", "EUR", "GBP"])!,
      exchangeRate: _.random(0.5, 1.5, true),
      autoDiluteEsop: _.sample([true, false])!,
      issuanceType: _.sample(["Equity", "Convertible Note"])!,
      paymentType: _.sample(["Wire Transfer", "Check"])!,
      allotmentType: _.sample(["Shares", "Options"])!,
      liquidationPreference: _.random(1, 2, true),
      liquidationPreferenceParticipation: _.sample([true, false])!,
      liquidationPreferenceDescription: _.sample([
        "Non-participating",
        "Participating",
      ])!,
      antidilution: _.sample(["Full Ratchet", "Weighted Average"])!,
      antidilutionDescription: "Protection against down rounds",
    },
    otherTerms: [
      {
        sectionName: "x".padStart(_.random(6, 8), "x"),
        values: [{ label: "Board Seat", value: "Yes" }],
      },
    ],
  },
  proposalDate: "2022-03-01",
  expiryDate: "2022-03-01",
  documentUrl: "x".padStart(_.random(6, 8), "x"),
  status: TermsheetStatus.active,
});

const randomRoundModelSummaryData = (): RoundModelCaptableInvestor => ({
  id: `${_.random(100000, 2000000)}`,
  preHoldingshares: _.random(1000, 2000),
  preHoldingEquityShares: _.random(1000, 2000),
  preHoldingFdbShares: _.random(1000, 2000),
  preHoldingFdbPercentage: _.random(1000, 2000),
  preHoldingPercentage: _.random(1000, 2000),
  postHoldingshares: _.random(1000, 2000),
  postHoldingEquityShares: _.random(1000, 2000),
  postHoldingFdbShares: _.random(1000, 2000),
  postHoldingFdbPercentage: _.random(1000, 2000),
  postHoldingPercentage: _.random(1000, 2000),
  totalAmountInvested: _.random(1000, 2000),
  differenceAmount: _.random(1000, 2000),
  name: "x".padStart(_.random(6, 8), "x"),
  type: "x".padStart(_.random(6, 8), "x"),
});

export const randomAllRoundModelNames = (): RoundModel[] =>
  Array(10)
    .fill(0)
    .map((_i) => roundModelData());

export const randomRoundModelCaptable = (): CumulativeHolding[] =>
  Array(10)
    .fill(0)
    .map((_i) => cumulativeHoldingDummyData());

export const shareholderInstrumentDummyData =
  (): ShareholderInstrumentModel => ({
    capTable: Array(10)
      .fill(0)
      .map((_i) => cumulativeHoldingDummyData()),
    dashboardCardDetails: dashboardCardDetailsDummyData(),
    instrumentClasses: [],
    shareholders: [],
    instrumentClassSubTypes: instrumentsDummyData(),
    securityDetails: [],
  });

const dashboardCardDetailsDummyData = (): DashboardCardDetails => ({
  currentCapital: _.random(1000000, 2000000),
  currentSharePrice: _.random(1000000, 2000000),
  currentShareholders: _.random(1000000, 2000000),
  currentValutaion: _.random(1000000, 2000000),
  previousCapital: _.random(1000000, 2000000),
  previousShareHolders: _.random(1000000, 2000000),
  previousSharePrice: _.random(1000000, 2000000),
  previousValuation: _.random(1000000, 2000000),
});

const instrumentsDummyData = (): InstrumentClassSubTypes => ({
  Equity: "x".padStart(_.random(6, 8), "x"),
  CCPS: "x".padStart(_.random(6, 8), "x"),
  CCD: "x".padStart(_.random(6, 8), "x"),
  OCD: "x".padStart(_.random(6, 8), "x"),
  Note: "x".padStart(_.random(6, 8), "x"),
  Warrant: "x".padStart(_.random(6, 8), "x"),
  RPS: "x".padStart(_.random(6, 8), "x"),
  NCD: "x".padStart(_.random(6, 8), "x"),
  OCPS: "x".padStart(_.random(6, 8), "x"),
  OCRPS: "x".padStart(_.random(6, 8), "x"),
  Options: "x".padStart(_.random(6, 8), "x"),
});

const cumulativeHoldingDummyData = (): CumulativeHolding => ({
  id: `${_.random(100000, 2000000)}`,
  notes: 1000000,
  shares: 1000000,
  roundIdentifierDetails: [],
  fdbShares: 1000000,
  investedAmount: 1000000,
  equityShares: 1000000,
  prefShares: 1000000,
  debentures: 1000000,
  percentage: 1000000,
  fdbPercentage: 1000000,
  name: "x".padStart(_.random(6, 8), "x"),
  type: "x".padStart(_.random(6, 8), "x"),
  warrants: 1000000,
});

const dashboardCaptableApiDummyData = (): DashBoardModel[] =>
  Array(10)
    .fill(0)
    .map((_i) => dashboardCaptableDummyData());

const dashboardCaptableDummyData = (): DashBoardModel => ({
  companyId: "x".padStart(_.random(6, 8), "x"),
  id: "x".padStart(_.random(6, 8), "x"),
  newCapTableId: "x".padStart(_.random(6, 8), "x"),
  type: "x".padStart(_.random(6, 8), "x"),
  info: dashboardInfoDummyData(),
  state: "x".padStart(_.random(6, 8), "x"),
  eventDate: "2012-03-31T18:30:00.000+0000",
  createdAt: "x".padStart(_.random(6, 8), "x"),
  updatedAt: "x".padStart(_.random(6, 8), "x"),
  transactions: dashboardTransactionsDataDummyData(),
  grade: "x".padStart(_.random(6, 8), "x"),
  parentId: "x".padStart(_.random(6, 8), "x"),
  investmentRecords: Array(10)
    .fill(0)
    .map((_i) => dashboardInvestmentRecordDummyData()),
  capTable: Array(10)
    .fill(0)
    .map((_i) => dashboardCaptableDataDummyData()),
  roundSummary: dashboardRoundSummaryDummyData(),
});

const dashboardInfoDummyData = (): Info => ({
  pps: _.random(1000000, 2000000),
  date: "x".padStart(_.random(6, 8), "x"),
  name: "x".padStart(_.random(6, 8), "x"),
  valuation: _.random(1000000, 2000000),
  pricedRound: false,
  roundIdentifier: "x".padStart(_.random(6, 8), "x"),
});

const dashboardInfo2DummyData = (): Info2 => ({
  pps: _.random(1000000, 2000000),
  date: "x".padStart(_.random(6, 8), "x"),
  name: "x".padStart(_.random(6, 8), "x"),
  valuation: _.random(1000000, 2000000),
  pricedRound: false,
  roundIdentifier: "x".padStart(_.random(6, 8), "x"),
});

const dashboardCaptableDataDummyData = (): DashBoardCaptable => ({
  id: "x".padStart(_.random(6, 8), "x"),
  roundIdentifierDetails: [],
  shares: _.random(1000000, 2000000),
  fdbShares: _.random(1000000, 2000000),
  equityShares: _.random(1000000, 2000000),
  prefShares: _.random(1000000, 2000000),
  debentures: _.random(1000000, 2000000),
  percentage: _.random(1000000, 2000000),
  fdbPercentage: _.random(1000000, 2000000),
  investedAmount: _.random(1000000, 2000000),
  notes: _.random(1000000, 2000000),
  name: "x".padStart(_.random(6, 8), "x"),
  type: "x".padStart(_.random(6, 8), "x"),
});

const dashboardTransactionsDataDummyData = (): DashboardTransactions => ({
  primaryTransactions: [],
  secondaryTransactions: [],
  esopPostRoundPercentage: _.random(1000000, 2000000),
});
const dashboardInvestmentRecordDummyData = (): InvestmentRecord => ({
  companyId: "x".padStart(_.random(6, 8), "x"),
  id: "x".padStart(_.random(6, 8), "x"),
  eventId: "x".padStart(_.random(6, 8), "x"),
  cInvestorId: "x".padStart(_.random(6, 8), "x"),
  type: "x".padStart(_.random(6, 8), "x"),
  amount: _.random(1000000, 2000000),
  state: "x".padStart(_.random(6, 8), "x"),
  noOfShares: _.random(1000000, 2000000),
  createdAt: "x".padStart(_.random(6, 8), "x"),
  updatedAt: "x".padStart(_.random(6, 8), "x"),
  issuances: [],
});

const dashboardRoundSummaryDummyData = (): RoundSummary => ({
  name: "x".padStart(_.random(6, 8), "x"),
  date: "x".padStart(_.random(6, 8), "x"),
  type: "x".padStart(_.random(6, 8), "x"),
  valuation: _.random(1000000, 2000000),
  dilution: _.random(1000000, 2000000),
  info: dashboardInfo2DummyData(),
  investment: _.random(1000000, 2000000),
  pps: _.random(1000000, 2000000),
  investorSummaries: [],
  securities: ["x".padStart(_.random(6, 8), "x")],
});

export const randomIndividualShareholderOverview =
  (): IndividualShareholderModel => ({
    shareholderDetails: {
      id: `${_.random(0, 2000000)}`,
      hasDematShares: false,
      hasToShowDistinctiveNumber: false,
      name: `${_.random(0, 2000000)}`,
      type: "x".padStart(_.random(6, 8), "x"),
      holdingPercentage: _.random(1000000, 2000000),
      shares: _.random(1000000, 2000000),
      fdbShares: _.random(1000000, 2000000),
      equityShares: _.random(1000000, 2000000),
      prefShares: _.random(1000000, 2000000),
      debentures: _.random(1000000, 2000000),
      pricePerShare: _.random(1000000, 2000000),
      roundIdentifierDetails: [],
      currentPrice: _.random(1000000, 2000000),
      gain: _.random(1000000, 2000000),
      networth: _.random(1000000, 2000000),
      netWorthPercentage: _.random(1000000, 2000000),
      shareRanges: [],
      securityTypes: [],
      holdingPercent: _.random(1000000, 2000000),
      netWorth: _.random(1000000, 2000000),
      sharesPerSecurity: [],
    },
    transactions: Array(100)
      .fill(0)
      .map((_i) => randomTransactions()),
    beneficiaries: Array(90)
      .fill(0)
      .map((_i) => randomBeneficiary()),
    shareholderSecurities: Array(80)
      .fill(0)
      .map((_i) => randomShareholderSecurity()),
    shareholderProfile: {
      id: `${_.random(0, 2000000)}`,
      name: "x".padStart(_.random(20, 50), "x"),
      address: "x".padStart(_.random(20, 50), "x"),
      residence: "x".padStart(_.random(20, 50), "x"),
      nationality: "x".padStart(_.random(20, 50), "x"),
      depositoryName: "x".padStart(_.random(20, 50), "x"),
      isPromoter: false,
      type: "x".padStart(_.random(20, 50), "x"),
      groupName: "x".padStart(_.random(20, 50), "x"),
      email: "x".padStart(_.random(20, 50), "x"),
      contactNumber: "x".padStart(_.random(20, 50), "x"),
      dpId: "x".padStart(_.random(20, 50), "x"),
      clientId: "x".padStart(_.random(20, 50), "x"),
      uniqueId: "x".padStart(_.random(20, 50), "x"),
      isRelativeOfDirector: false,
    },
  });

const randomShareholderSecurity = (): ShareholderSecurityModel => ({
  name: "x".padStart(_.random(25, 35), "x"),
  securityType: "x".padStart(_.random(25, 35), "x"),
  securityClass: "x".padStart(_.random(25, 35), "x"),
  certificateUrl: "x".padStart(_.random(25, 35), "x"),
  round: "x".padStart(_.random(25, 35), "x"),
  roundDetails: {
    roundId: "x".padStart(_.random(25, 35), "x"),
    roundType: "x".padStart(_.random(25, 35), "x"),
    roundName: "x".padStart(_.random(25, 35), "x"),
    roundIdentifier: "x".padStart(_.random(25, 35), "x"),
  },
  numberOfShares: _.random(100000, 200000),
  pricePerShare: _.random(100000, 200000),
  demat: false,
  amountInvested: _.random(100000, 200000),
  currentValue: _.random(100000, 200000),
  securityClassId: "x".padStart(_.random(25, 35), "x"),
  certificateId: "x".padStart(_.random(25, 35), "x"),
  certificateName: "x".padStart(_.random(25, 35), "x"),
  roundSecurityClassIds: [],
  fdbShares: _.random(100000, 200000),
  remarks: "",
  ranges: [[]],
});

const randomBeneficiary = (): BeneficiaryModel => ({
  id: "x".padStart(_.random(7, 10), "x"),
  name: "x".padStart(_.random(7, 10), "x"),
  securityClass: "x".padStart(_.random(7, 10), "x"),
  securityType: "x".padStart(_.random(7, 10), "x"),
  numberOfShares: _.random(100000, 200000),
  holdingPercentage: _.random(100000, 200000),
  amountInvested: _.random(100000, 200000),
  beneficialInterest: _.random(100000, 200000),
  certificateId: "x".padStart(_.random(7, 10), "x"),
  shareRanges: "x".padStart(_.random(7, 10), "x"),
  certificateRanges: "x".padStart(_.random(7, 10), "x"),
});

const randomTransactions = (): ShareholderTransactionModel => ({
  id: "x".padStart(_.random(15, 25), "x"),
  type: "x".padStart(_.random(15, 25), "x"),
  numberOfShares: _.random(100000, 200000),
  price: _.random(100000, 200000),
  date: "2012-03-31T18:30:00.000+0000",
  shareRanges: [],
  folioNumber: "x".padStart(_.random(15, 25), "x"),
  certificateId: "x".padStart(_.random(15, 25), "x"),
  preHoldingShares: null,
  postHoldingShares: null,
  securityClass: "x".padStart(_.random(15, 25), "x"),
  secondaryInvestorName: "",
  amount: 0,
  amountInvestedInCurrency: 0,
  currency: "",
  exchangeRate: 1,
  roundCurrency: "",
  exchangeRateForRound: 1,
  isPartlyPaid: false,
  actualSharePrice: 0,
});

const randomSecurityTableDetail = (): SecurityTableDetail => ({
  id: `${_.random(100000, 200000)}`,
  className: `${_.random(100000, 200000)}`,
  icin: `${_.random(100000, 200000)}`,
  type: `${_.random(100000, 200000)}`,
  roundIdentifierDetails: [],
  shareHolder: [],
  noOfShares: _.random(100000, 200000),
  parValue: _.random(100000, 200000),
  amountInvested: _.random(100000, 200000),
});

const randomLiquidationPreferenceTableDetail = (): LiquidationStack => ({
  securityClassId: `${_.random(100000, 200000)}`,
  securityType: `${_.random(100000, 200000)}`,
  securityClassName: `${_.random(100000, 200000)}`,
  rank: _.random(100000, 200000),
  investmentMultiple: _.random(100000, 200000),
  investment: _.random(100000, 200000),
  lpValue: _.random(100000, 200000),
  isParriPassu: false,
  isParticipation: false,
  participationCap: _.random(100000, 200000),
});

const randomSecurityDetails = (): SecurityDetail => ({
  securityType: `${_.random(100000, 200000)}`,
  noOfShares: _.random(15, 25),
  holding: _.random(15, 25),
  parValue: _.random(15, 25),
  capitalValue: _.random(15, 25),
  roundIdentifierDetails: [],
  shareholder: [],
  noOfClassess: _.random(15, 25),
  chartDetails: [],
});

const individualShareholderOverview = (): ShareholderDataModel => ({
  id: `${_.random(0, 2000000)}`,
  hasDematShares: false,
  hasToShowDistinctiveNumber: false,
  name: "x".padStart(_.random(6, 8), "x"),
  type: "x".padStart(_.random(6, 8), "x"),
  holdingPercentage: _.random(100.0, 200.0),
  shares: _.random(1000, 2000),
  fdbShares: _.random(1000, 2000),
  equityShares: _.random(1000, 2000),
  prefShares: _.random(1000, 2000),
  debentures: _.random(1000, 2000),
  pricePerShare: _.random(1000.0, 2000.0),
  roundIdentifierDetails: [],
  currentPrice: _.random(1000.0, 2000.0),
  gain: _.random(1000.0, 2000.0),
  networth: _.random(1000.0, 2000.0),
  netWorthPercentage: _.random(1000.0, 2000.0),
  shareRanges: ["0-500", "500-1000"],
  securityTypes: ["Equity", "Preferred"],
  holdingPercent: _.random(1000.0, 2000.0),
  netWorth: _.random(1000.0, 2000.0),
  sharesPerSecurity: [
    {
      security: "x".padStart(_.random(6, 8), "x"),
      shares: _.random(10000, 20000),
    },
    {
      security: "x".padStart(_.random(6, 8), "x"),
      shares: _.random(10000, 20000),
    },
  ],
});

export const randomShareholderOverview = (): ShareholderDataModel[] =>
  Array(10)
    .fill(0)
    .map((_i) => individualShareholderOverview());

const randomSecurityTransactions = (): SecurityTransaction => ({
  eventId: "xxxxxxx",
  name: "xxxxxxx",
  securityType: "xxxxx",
  transactionType: "update ratio",
  updatedValue: "1:1",
  date: "xxxxxxx",
});

export const randomSecurityOverview = (): SecurityDataModel => ({
  tableDetails: Array(5)
    .fill(0)
    .map((_i) => randomSecurityTableDetail()),
  securityDetails: Array(5)
    .fill(0)
    .map((_i) => randomSecurityDetails()),
  securityTransactions: Array(5)
    .fill(0)
    .map((_i) => randomSecurityTransactions()),
});

export const randomModelingData = (): ModelingListModel[] =>
  Array(5)
    .fill(0)
    .map((i) => ({
      createdAt: "2012-03-31T18:30:00.000+0000",
      modelId: `${_.random(1000000.0, 2000000.0)}`,
      modelName: "x".padStart(_.random(6, 8), "x"),
      updatedAt: "2012-03-31T18:30:00.000+0000",
      tab1Details: {
        roundName: "x".padStart(_.random(6, 8), "x"),
        roundIdentifier: "x".padStart(_.random(6, 8), "x"),
        pricedRound: false,
        leftToRaise: _.random(1000.0, 2000.0),
        moneyRaised: _.random(1000.0, 2000.0),
        postEsopPercentage: _.random(1000.0, 2000.0),
        roundSize: _.random(1000.0, 2000.0),
        preMoney: _.random(1000.0, 2000.0),
        baseValuation: _.random(1000.0, 2000.0),
        fdbSharePrice: _.random(1000.0, 2000.0),
        postMoney: _.random(1000.0, 2000.0),
        dilution: _.random(1000.0, 2000.0),
      },
    }));

export const randomLiquidationPreferenceOverview = (): LiquidationStack[] =>
  Array(5)
    .fill(0)
    .map((_i) => randomLiquidationPreferenceTableDetail());

const securityType1: SecurityTypeModel = {
  securityType: "ccps equity",
  parValue: 100,
  premium: 10,
};

const securityType2: SecurityTypeModel = {
  securityType: "convertible bond",
  parValue: 500,
  premium: 50,
};

const transaction1: AllTransactionModel = {
  id: "1",
  name: "Round 1",
  identifier: "ROUND1",
  transactionType: "Primary",
  issuanceType: "xxxxxx",

  dateOfTransaction: "2022-01-01",
  valuation: 1000000,
  dilution: 0.2,
  securityTypes: [securityType1, securityType2],
  amount: 50000,
  leadInvestor: "Investor A",
  paymentType: "Cash",
  splitRatio: "1:2",
  bonusRatio: "1:10",
  pps: 120,
  securityClass: ["Class A"],
  noOfShares: 500,
  roundPPS: 0,
  debtSecurityForPPS: "",
};

const transaction2: AllTransactionModel[] = [
  {
    id: "1",
    name: "Primary",
    identifier: "SECONDARY1",
    transactionType: "secondary",
    issuanceType: "xxxxxx",
    dateOfTransaction: "2022-02-01",
    valuation: 1500000,
    dilution: 0.1,
    securityTypes: [securityType1],
    amount: 25000,
    leadInvestor: "Investor B",
    paymentType: "Cash",
    splitRatio: "1:1",
    bonusRatio: "1:5",
    pps: 130,
    securityClass: ["Class A"],
    noOfShares: 250,
    roundPPS: 0,
    debtSecurityForPPS: "",
  },
  {
    id: "2",
    name: "Secondary 1",
    identifier: "SECONDARY1",
    transactionType: "round",
    issuanceType: "xxxxxx",

    dateOfTransaction: "2022-02-01",
    valuation: 1500000,
    dilution: 0.1,
    securityTypes: [securityType1],
    amount: 25000,
    leadInvestor: "Investor B",
    paymentType: "Cash",
    splitRatio: "1:1",
    bonusRatio: "1:5",
    pps: 130,
    securityClass: ["Class A"],
    noOfShares: 250,
    roundPPS: 0,
    debtSecurityForPPS: "",
  },
  {
    id: "3",
    name: "Secondary 1",
    identifier: "SECONDARY1",
    transactionType: "round",
    issuanceType: "xxxxxx",

    dateOfTransaction: "2022-02-01",
    valuation: 1500000,
    dilution: 0.1,
    securityTypes: [securityType1],
    amount: 25000,
    leadInvestor: "Investor B",
    paymentType: "Cash",
    splitRatio: "1:1",
    bonusRatio: "1:5",
    pps: 130,
    securityClass: ["Class A"],
    noOfShares: 250,
    roundPPS: 0,
    debtSecurityForPPS: "",
  },
];

export const randomAllTransactions = (): AllTransactionModel[] => transaction2;

const roundDetail: RoundDetailModel = {
  roundId: "123",
  roundType: "round",
  roundName: "Series A",
  roundIdentifier: "SA",
};

const roundsCapTable: IndividualRoundCapTable[] = [
  {
    id: "1",
    rounds: [roundDetail],
    preHoldingFdbShares: 100,
    preHoldingFdbPercentage: 10,
    postHoldingFdbShares: 200,
    postHoldingFdbPercentage: 20,
    amountInvestedInRound: 500000,
    totalAmountInvested: 2000000,
    shareholderName: "John Doe",
    shareholderType: "investor",
  },
  {
    id: "2",
    rounds: [roundDetail],
    preHoldingFdbShares: 50,
    preHoldingFdbPercentage: 5,
    postHoldingFdbShares: 100,
    postHoldingFdbPercentage: 10,
    amountInvestedInRound: 250000,
    totalAmountInvested: 1000000,
    shareholderName: "Jane Smith",
    shareholderType: "founder",
  },
];

const primaryTransactions: PrimaryTransactionDataModel[] = [
  {
    name: "John Doe",
    investorType: "investor",
    security: "common",
    image: "https://example.com/john.jpg",
    sharePrice: 10,
    investorId: "investor-123",
    numberOfShares: 1000,
    amountInvested: 10000,
    par: 1,
    conversionRatio: "1:1",
  },
  {
    name: "Jane Smith",
    investorType: "founder",
    security: "common",
    image: "https://example.com/jane.jpg",
    sharePrice: 10,
    investorId: "founder-123",
    numberOfShares: 2000,
    amountInvested: 20000,
    par: 1,
    conversionRatio: "1:1",
  },
];

const secondaryTransactions: SecondaryTransactionDataModel[] = [
  {
    buyerName: "Acme Inc.",
    sellerName: "John Doe",
    investorType: "investor",
    security: "common",
    image: "https://example.com/acme.jpg",
    sharePrice: 15,
    buyerInvestorId: "acme-123",
    sellerInvestorId: "investor-123",
    numberOfShares: 500,
    amountInvested: 7500,
    par: 1,
    conversionRatio: "1:1",
    date: "2022-03-14",
  },
];

const transactions: Transactions = {
  primaryTransactions: [],
  secondaryTransactions: [],
  esopPostRoundPercentage: 5,
};

const individualRound: IndividualRoundsModel = {
  id: "123",
  name: "Series A",
  identifier: "SA",
  type: "round",
  amountRaised: 500000,
  dateOfTransaction: "2022-03-14",
  postMoney: 2000000,
  preMoney: 1500000,
  numberOfShares: 10000,
  pps: 50,
  securityTypes: ["common"],
  dilution: 10,
  isPricedRound: true,
  issuanceType: "primary",
  newShares: 5000,
  newFdbShares: 1000,
  roundDescription: "Series B funding round",
  capTable: roundsCapTable,
  transactions,
};

export const randomIndividualTransactions = (): IndividualRoundsModel =>
  individualRound;

export const randomIndividualTransactionsList = (): IndividualRoundsModel[] => [
  individualRound,
];

const individualSplitCapTable: IndividualSplitCapTable = {
  id: "1",
  shareholderName: "John Doe",
  shareholderType: "Individual",
  rounds: roundDetail,
  preHoldingFdbShares: 2000,
  postHoldingFdbShares: 3000,
  preHoldingCommonShares: 10000,
  postHoldingCommonShares: 15000,
  preHoldingPrefShares: 8000,
  postHoldingPrefShares: 12000,
};

const individualSplit: IndividualSplitModel = {
  id: "1",
  splitRatio: "1:2",
  dateOfTransaction: "2022-01-01",
  securityTypes: ["Common Shares", "Preferred Shares"],
  roundDescription: "Series B - Secondary round",
  capTable: [individualSplitCapTable],
};

export const randomIndividualSplitTransactions = (): IndividualSplitModel =>
  individualSplit;

const transactionDocument: TransactionDocTypes = {
  id: "123",
  documentType: DocumentType.link,
  cin: "123",
  companyId: "123",
  eventId: "123",
  key: "TEST",
  name: "Document",
  size: "10 KB",
  tags: [""],
  updatedAt: "2024-09-25",
};

export const randomTransactionDocuments = (): TransactionDocTypes[] => [
  transactionDocument,
];

const capTable: IndividualSecondaryCapTable = {
  id: "1",
  shareholderName: "John Doe",
  shareholderType: "Individual",
  rounds: [roundDetail],
  preHoldingFdbShares: 10000,
  preHoldingFdbPercentage: 25,
  postHoldingFdbShares: 15000,
  postHoldingFdbPercentage: 37.5,
  transactionValue: 5000,
  totalAmountInvested: 10000,
  netWorth: 150000,
};

const transactionData: SecondaryTransactionDataModel = {
  buyerName: "Jane Smith",
  sellerName: "John Doe",
  investorType: "Individual",
  security: "Preferred Stock",
  image: "https://example.com/image.jpg",
  sharePrice: 10,
  instrumentClassId: "1",
  buyerInvestorId: "2",
  sellerInvestorId: "1",
  numberOfShares: 500,
  amountInvested: 5000,
  par: 1,
  conversionRatio: "1:1",
  date: "2022-03-01",
};

const individualSecondaryModel: IndividualSecondaryModel = {
  id: "1",
  name: "ABC Company",
  identifier: "ABC",
  type: "private",
  transactionAmount: 10000,
  dateOfTransaction: "2022-03-01",
  numberOfShares: 1000,
  pps: 10,
  securityTypes: ["Preferred Stock"],
  roundDescription: "Series D funding round",
  capTable: [capTable],
  transactions: [],
  numberOfBuyer: 0,
  numberOfSeller: 0,
};

export const randomIndividualSecondaryTransactions =
  (): IndividualSecondaryModel => individualSecondaryModel;

const SharesPerSecurity = {
  investorId: "xxxxxxxxx",
  instrumentId: "xxxxxxxx",
  instrumentName: "ABC",
  noOfShares: 10,
};

const secondaryInvestors = {
  id: "xxxxxxxxxxx",
  name: "ABC",
  type: "VC",
  sharesPerSecurity: [SharesPerSecurity],
};

export const randomSecondaryInvestors = (): SecondaryInvestors[] => [
  secondaryInvestors,
];

const buybackCapTable: IndividualBuybackCapTable[] = [
  {
    id: "1",
    shareholderName: "John Doe",
    shareholderType: "Individual",
    preHoldingFdbShares: 1000,
    preHoldingFdbPercentage: 10,
    postHoldingFdbShares: 900,
    postHoldingFdbPercentage: 9,
    numberOfShares: 100,
  },
  {
    id: "2",
    shareholderName: "Jane Smith",
    shareholderType: "Institutional Investor",
    preHoldingFdbShares: 2000,
    preHoldingFdbPercentage: 20,
    postHoldingFdbShares: 1800,
    postHoldingFdbPercentage: 18,
    numberOfShares: 200,
  },
];

const buybackModel: IndividualBuybackModel = {
  id: "1",
  name: "ABC Company",
  identifier: "ABC",
  type: "Buyback",
  amountRaised: 1000000,
  dateOfTransaction: "2022-01-01",
  numberOfShares: 10000,
  pps: 100,
  securityTypes: ["Common Stock", "Preferred Stock"],
  dilution: 0.1,
  roundDescription: "Series A",
  capTable: buybackCapTable,
};

export const randomConversionData = (): IndividualConversionModel => ({
  id: "12345",
  sourceSecurityName: "Common Stock",
  destinationSecurityName: "Preferred Stock",
  sourceSecurityPar: 1,
  destinationSecurityPar: 1,
  numberOfSharesConverted: 1000,
  finalNoOfShares: 100000,
  amount: 10000,
  shareholderId: "asjdkjadnk",
  dateOfTransaction: "2022-02-01",
  capTable: [],
});

export const randomIndividualBuyBackTransactions = (): IndividualBuybackModel =>
  buybackModel;

const bonusCapTable: IndividualBonusCapTable[] = [
  {
    id: "1",
    rounds: roundDetail,
    preHoldingCommonShares: 1000,
    preHoldingPrefShares: 0,
    preHoldingFdbShares: 500,
    preHoldingDebentures: 0,
    preHoldingFdbPercentage: 20,
    postHoldingCommonShares: 1500,
    postHoldingPrefShares: 0,
    postHoldingFdbShares: 750,
    postHoldingDebentures: 0,
    postHoldingFdbPercentage: 20,
    shareholderName: "John Doe",
    shareholderType: "individual",
  },
  {
    id: "2",
    rounds: roundDetail,
    preHoldingCommonShares: 2000,
    preHoldingPrefShares: 1000,
    preHoldingFdbShares: 0,
    preHoldingDebentures: 0,
    preHoldingFdbPercentage: 0,
    postHoldingCommonShares: 3000,
    postHoldingPrefShares: 1500,
    postHoldingFdbShares: 0,
    postHoldingDebentures: 0,
    postHoldingFdbPercentage: 0,
    shareholderName: "Jane Smith",
    shareholderType: "individual",
  },
];

const bonusModel: IndividualBonusModel = {
  id: "3",
  name: "Bonus Round 2022",
  identifier: "BONUS22",
  type: "bonus",
  amountRaised: 0,
  dateOfTransaction: "2022-01-01",
  numberOfShares: 0,
  pps: 0,
  oldPps: 10,
  newBonusShares: 1000,
  newFdbShares: 500,
  roundDescription:
    "This bonus round is being issued to reward current shareholders for their loyalty and support.",
  capTable: bonusCapTable,
};

export const randomIndividualBonusTransactions = (): IndividualBonusModel =>
  bonusModel;

export const randomShareholderRanges = (): ShareholderRangeModel[] =>
  Array(10)
    .fill(0)
    .map((_i) => shareholderRangeModelData());

export const randomAllTermsheetModel = (): TermsheetData[] =>
  Array(10)
    .fill(0)
    .map((_i) => allTermsheetModelData());

export const randomIndividualTermSheetModel = (): IndividualTermSheetModel =>
  individualTermSheet;

const individualTermSheet: IndividualTermSheetModel = {
  id: "x".padStart(_.random(6, 8), "x"),
  name: "x".padStart(_.random(6, 8), "x"),
  roundSize: 1000000,
  preMoneyValuation: 5000000,
  preMoneyDate: "2022-01-01",
  dilution: 0.1,
  isPricedRound: true,
  postRoundEsop: 0.05,
  termSheetLink: "x".padStart(_.random(6, 8), "x"),
  captable: [
    {
      id: "1",
      rounds: [roundDetail],
      preHoldingShares: 100000,
      postHoldingShares: 200000,
      preHoldingFdbShares: 10000,
      postHoldingFdbShares: 20000,
      preHoldingFdbPercentage: 0.1,
      postHoldingFdbPercentage: 0.15,
      amountInvestedInRound: 50000,
      shareholderName: "x".padStart(_.random(6, 8), "x"),
      shareholderType: "x".padStart(_.random(6, 8), "x"),
    },
    {
      id: "2",
      rounds: [roundDetail],
      preHoldingShares: 50000,
      postHoldingShares: 100000,
      preHoldingFdbShares: 5000,
      postHoldingFdbShares: 10000,
      preHoldingFdbPercentage: 0.05,
      postHoldingFdbPercentage: 0.1,
      amountInvestedInRound: 25000,
      shareholderName: "Jane Smith",
      shareholderType: "Individual",
    },
  ],
};

export const getRandomNonPricedRoundModel = () => ({
  companyId: "x".padStart(_.random(6, 8), "x"),
  id: "x".padStart(_.random(6, 8), "x"),
  baseCapTableId: "x".padStart(_.random(6, 8), "x"),
  state: "x".padStart(_.random(6, 8), "x"),
  eventId: "x".padStart(_.random(6, 8), "x"),
  createdAt: "x".padStart(_.random(6, 8), "x"),
  updatedAt: "x".padStart(_.random(6, 8), "x"),
  isDeleted: false,
  draftData: {
    preEsop: 0,
    modelName: "x".padStart(_.random(6, 8), "x"),
    roundSize: 0,
    convertibles: [],
    preFdbShares: 0,
    totalNoteFdb: 0,
    esopPercentage: 0,
    conversionMethod: "x".padStart(_.random(6, 8), "x"),
    investorHoldings: [],
    preMoneyValuation: 0,
  },
  type: "x".padStart(_.random(6, 8), "x"),
});

export const randomNonPricedRoundModel = getRandomNonPricedRoundModel();
