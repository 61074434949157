import { useState } from "react";
import { toast } from "react-toastify";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

import { useDeleteUserAccess } from "../../queries/modeling";

const people = [{ id: 2, name: "REMOVE" }];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function GiveModelAccessSelect({
  userDetails,
  modelId,
}: {
  userDetails: any;
  modelId: string;
}) {
  const { mutate: deleteUserAccess } = useDeleteUserAccess();

  return (
    <Listbox
      onChange={(data: any) => {
        const userDetail = {
          userId: userDetails.userId,
          modelId,
          accessId: userDetails.accessId,
        };
        deleteUserAccess(userDetail, {
          onSuccess: () => {
            toast("User Removed successfully!", {
              type: "success",
              autoClose: 2000,
            });
          },
          onError: (err: any) => {
            toast(err.response.data.errorMessage, {
              type: "error",
              autoClose: 2000,
            });
          },
        });
      }}
    >
      {({ open }) => (
        <>
          <div className="relative">
            <ListboxButton className="relative w-full pl-3 pr-8 text-left text-gray-900 bg-white rounded-md cursor-default focus:outline-none sm:text-xs sm:leading-6">
              <span className="block truncate">{userDetails.role}</span>
              {userDetails.role !== "Owner" && (
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronDownIcon
                    className="w-4 h-4 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              )}
            </ListboxButton>
            {userDetails.role !== "Owner" && (
              <Transition
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ListboxOptions className="absolute z-50 w-full py-1 mt-1 overflow-auto text-xs bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
                  {people.map((person) => (
                    <ListboxOption
                      key={person.id}
                      className={({ focus }) =>
                        classNames(
                          focus ? "bg-secondary/5 text-secondary" : "",
                          !focus ? "text-secondary" : "",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={person}
                    >
                      {({ selected, focus }) => (
                        <>
                          <span
                            className={classNames(
                              selected
                                ? "font-semibold text-primary"
                                : "font-normal",
                              "block"
                            )}
                          >
                            {person.name}
                          </span>
                        </>
                      )}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </Transition>
            )}
          </div>
        </>
      )}
    </Listbox>
  );
}
