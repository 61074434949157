import { useMutation, useQuery } from "react-query";
import {
  deleteUserAccess,
  getIndividualNonPricedRoundModel,
  getListOfModelings,
  getListOfNonPricedRoundModel,
  getListOfUsersAccess,
  getModelingDetails,
  getNonPricedRoundModelDetails,
  saveNonPricedRoundModel,
  shareUserAccess,
  updateModelAccess,
} from "../api/modeling";
import {
  randomModelingData,
  randomNonPricedRoundModel,
} from "./randomPlaceholderData";
import { postOnFilterState } from "../pages/dashboardPage/AgGridCache";
import { handleEventForTracking } from "../amplitudeAnalytics";
import { queryClient } from "./client";

export function useGetListOfModeling() {
  return useQuery({
    queryKey: "listofmodeling",
    queryFn: getListOfModelings,
    placeholderData: randomModelingData(),
    enabled: true,
  });
}

export function useListOfNonPricedRoundModel() {
  return useQuery({
    queryKey: "getListOfNonPricedRoundModel",
    queryFn: getListOfNonPricedRoundModel,
    enabled: true,
  });
}

export function useGetIndividualNonPricedRoundModel(modelId: string) {
  return useQuery({
    queryKey: ["getIndividualNonPricedRoundModel", modelId],
    queryFn: getIndividualNonPricedRoundModel,
    enabled: true,
  });
}

export function useGetNonPricedRoundModelDetails() {
  return useQuery({
    queryKey: "getNonPricedRoundModelDetails",
    queryFn: getNonPricedRoundModelDetails,
    enabled: true,
  });
}

export function useSaveNonPricedRoundModel() {
  return useMutation({
    mutationKey: "saveNonPricedRoundModel",
    mutationFn: saveNonPricedRoundModel,
    onSuccess: () => {},
    onError: () => {},
  });
}

export function useGetModelingDetails(modelId: string) {
  return useQuery({
    queryKey: ["modelingDetails", modelId],
    queryFn: getModelingDetails,
    enabled: false,
  });
}

export function useGetListOfUsersAccess(modelId: string) {
  return useQuery({
    queryKey: ["getListOfUsersAccess", modelId],
    queryFn: getListOfUsersAccess,
    enabled: true,
  });
}

export function useShareUserAccess() {
  return useMutation({
    mutationKey: "shareUserAccess",
    mutationFn: shareUserAccess,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "shareUserAccess",
        success: true,
        eventType: "API",
      });
      queryClient.invalidateQueries("getListOfUsersAccess");
    },
    onError: () => {
      handleEventForTracking({
        eventName: "shareUserAccess",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDeleteUserAccess() {
  return useMutation({
    mutationKey: "deleteUserAccess",
    mutationFn: deleteUserAccess,
    onSuccess: () => {
      queryClient.invalidateQueries("getListOfUsersAccess");

      handleEventForTracking({
        eventName: "deleteModelingUserAccess",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "deleteModelingUserAccess",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdateModelAccess() {
  return useMutation({
    mutationKey: "updateModelAccess",
    mutationFn: updateModelAccess,
    onSuccess: () => {
      queryClient.invalidateQueries("getListOfUsersAccess");
      queryClient.invalidateQueries("getIndividualNonPricedRoundModel");
      queryClient.refetchQueries("listofmodeling");
      queryClient.refetchQueries("getListOfNonPricedRoundModel");

      handleEventForTracking({
        eventName: "updateModelAccess",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updateModelAccess",
        success: false,
        eventType: "API",
      });
    },
  });
}
