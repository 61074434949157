import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import _, { union } from "lodash";
import { HStack, VStack, useGetCompanyName } from "../../components/utils";
import {
  useGetInstrumentAndShareHolder,
  useGetQuickRoundPreview,
} from "../../queries/quickRound";
import { CumulativeHoldingQuickRound } from "../../types/CapTable";
import AlertDialog from "../../components/shared/AlertDialog";
import {
  PrimaryTransactionDataModel,
  QuickBonusSecurityModel,
  QuickBonusTransactionDetails,
  SecondaryTransactionDataModel,
} from "../../types/quickRound";
import { sort } from "../../utils/arrays";
import {
  CapTableBarChart,
  ShareHolderCaptableChartModel,
} from "./CapTableBarChart";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import ShareSummary from "../quickRound/ShareSummary";
import ShareTypes, { ShareType } from "../quickRound/ShareTypes";
import Valuation from "../quickRound/Valuation";
import { useAuthStore } from "../../store";
import { ShareHolderCapTableQuickRound } from "./ShareHolderCapTableQuickRound";
import {
  useApproveTransactionPreview,
  useDeleteOnGoingTransaction,
  useDiscardQuickRound,
  useGetOngoingNonPricedRound,
} from "../../queries/transactionRound";
import {
  useQuickBonusTransactionStore,
  useQuickBuybackTransactionStore,
  useQuickRoundTransactionStore,
  useQuickSecondaryTransactionStore,
  useQuickSplitTransactionStore,
} from "../../store/useQuickTransactionStore";
import {
  extractBonusTransaction,
  extractSecondaryTransaction,
} from "./ExtractionUtility";
import QuickSecondariesTransactions from "./QuickSecondariesTransactions";
import QuickBonusPreviewDetails from "./QuickBonusPreviewDetails";
import TransactionDetails from "./TransactionDetails";
import {
  BuybackTransaction,
  QuickRoundTransactionModel,
  SecondaryTransaction,
} from "./RoundTransactionModel";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import QuickBonusSecurityDetails from "./QuickBonusSecurityDetails";
import Loader from "../../shared/Loader";
import { useQuickBonusSecurityStore } from "../../store/QuickBonusSecuritiesStore";
import QuickBuybackPreviewDetails from "./QuickBuybackPreviewDetails";
import QuickBuybackParticipantsDetails from "./QuickBuybackParticipantsDetails";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { queryClient } from "../../queries/client";
import AGGridRoundTransactionTable from "../rounds/RoundsTransactionAgTable";
import { FDBSecurityType } from "../../constants/RoundsConstant";
import { getCurrencySymbol } from "../../utils/currencyFormatter";
import DeleteModal from "../modeling/DeleteModal";
import { ExportImport } from "../Utility/GrantsTable";
import {
  exportAOCReport,
  exportHorizontalShaSsa,
  exportIndividualRoundSummaryReport,
  exportNonPricedRound,
  exportSHA,
  exportSSA,
} from "../../api/Report";
import { Action } from "../../components/shared/Dropdown";
import { downloadExcel } from "../../utils/DownloadFile";
import { useShareAuthStore } from "../../store/useShareAuthStore";
import {
  useSharedGetInstrumentAndShareHolder,
  useSharedGetQuickRoundPreview,
} from "../../queries/sharedResouces";
import AGGridShareholderQuickRoundTable from "./ShareholderAgGridCaptableQuickRound";
import OverrideCloseDatePopup from "./OverrideCloseDatePopUp";

const TransactionPreviewPage = () => {
  const pathName = window.location.href.split("/");

  const [loading, setLoading] = useState(false);
  const companyId = useAuthStore.getState().companyId || "";
  const sharedPage = useMemo(() => pathName[3] === "share", [pathName]);
  const { shareId, shareCompanyId, shareResourceId, shareResourceType } =
    useShareAuthStore();
  const [showShare, setShowShare] = useState(false);
  const roundType: string = sharedPage
    ? (shareResourceType ?? "")?.split(" ")[0].toLowerCase()
    : pathName[4] || "round";
  const {
    state: template,
    setState: setTemplate,
    reset: resetTemplate,
  } = roundType === "round"
    ? useQuickRoundTransactionStore()
    : roundType === "secondary"
    ? useQuickSecondaryTransactionStore()
    : roundType === "bonus"
    ? useQuickBonusTransactionStore()
    : roundType === "buyback"
    ? useQuickBuybackTransactionStore()
    : useQuickSplitTransactionStore();

  const _id = template.eventId || "";
  const bonusSecurityStore = useNewShareholderInstrumentStore();
  const {
    refetch: getQuickRoundPreview,
    isPlaceholderData,
    data: previewQuickRoundData,
  } = sharedPage
    ? useSharedGetQuickRoundPreview(shareResourceId ?? "")
    : useGetQuickRoundPreview(_id);
  const { refetch: discardQuickRound } = useDiscardQuickRound();
  const [showChart, setShowChart] = useState(false);
  const navigate = useNavigate();
  const { mutate: deleteOnGoingTransaction } =
    useDeleteOnGoingTransaction(companyId);
  const {
    refetch: getInstrumentAndShareHolder,
    isPlaceholderData: instrumentShareholderPlaceholder,
    data: instrumentShareholderInfo,
  } = sharedPage
    ? useSharedGetInstrumentAndShareHolder(
        shareId ?? "",
        shareResourceId ?? "",
        shareCompanyId ?? ""
      )
    : useGetInstrumentAndShareHolder(companyId);
  const [roundSecurity, setRoundSecurity] = useState<number>(0);
  const [roundFDBTotalShares, setRoundFDBTotalShare] = useState<number>(0);
  const [roundShares, setRoundShare] = useState<number>(0);
  const [esopShares, setEsopShares] = useState<number>(0);
  const [totalShares, setTotalShare] = useState<number>(0);
  const [shareType, setShareType] = useState<ShareType[]>([]);
  const [roundAmountRaised, setRoundAmountRaised] = useState<number>(0);
  const [transactionData, setTransactionData] = useState<
    PrimaryTransactionDataModel[]
  >([]);
  const [secondary, setSecondary] = useState<SecondaryTransactionDataModel[]>(
    []
  );
  const [chartData, setChartData] = useState<ShareHolderCaptableChartModel[]>(
    []
  );

  const currencySymbol = getCurrencySymbol();

  const [valuationInputValue, setValuationInputValue] = useState<number>(
    previewQuickRoundData?.eventInfo?.valuation || 0
  );
  const [ppsInputValue, setPpsInputValue] = useState<number>(
    previewQuickRoundData?.eventInfo?.pps || 0
  );
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: any;
    name?: "Close Date Popup" | "Delete" | "";
  }>({
    open: false,
  });

  const [bonusSecurityDetails, setBonusSecurityDetails] = useState<
    QuickBonusSecurityModel[]
  >([]);

  const [buyBackParticipantDetails, setBuyBackParticipantDetails] = useState<
    BuybackTransaction[]
  >([]);
  const [secondaries, setSecondaries] = useState<
    SecondaryTransactionDataModel[]
  >([]);
  const instrumentShareholderInfoData = useShareholderInstrumentStore();

  useEffect(() => {
    if (!instrumentShareholderPlaceholder) {
      instrumentShareholderInfoData.setInstrumentClass(
        instrumentShareholderInfo?.instrumentClasses || []
      );
      instrumentShareholderInfoData.setShareholders(
        instrumentShareholderInfo?.shareholders || []
      );
      instrumentShareholderInfoData.setInstrumentClassSubTypes(
        instrumentShareholderInfo?.instrumentClassSubTypes || {}
      );
    }
  }, [instrumentShareholderInfo, instrumentShareholderPlaceholder]);

  const extractTransaction = () => {
    previewQuickRoundData?.transactions?.primaryTransactions?.forEach(
      (transaction) => {
        if (
          !shareType.find(
            (shareDetail) => shareDetail.id === transaction.instrumentClassId
          )
        ) {
          setShareType((state) => [
            ...state,
            {
              id: transaction.instrumentClassId,
              instrumentClass:
                instrumentShareholderInfoData?.instrumentClasses.find(
                  (instrumentDetail) =>
                    instrumentDetail.id === transaction.instrumentClassId
                )?.name || "",
              instrument:
                instrumentShareholderInfoData?.instrumentClasses.find(
                  (instrumentDetail) =>
                    instrumentDetail.id === transaction.instrumentClassId
                )?.name || "",
              conversation:
                instrumentShareholderInfoData?.instrumentClasses.find(
                  (instrumentDetail) =>
                    instrumentDetail.id === transaction.instrumentClassId
                )?.conversionRatio || 0,
              par:
                instrumentShareholderInfoData?.instrumentClasses.find(
                  (instrumentDetail) =>
                    instrumentDetail.id === transaction.instrumentClassId
                )?.parValue || 0,
            },
          ]);
        }
        const convRatio =
          instrumentShareholderInfoData?.instrumentClasses.find(
            (instrumentDetail) =>
              instrumentDetail.id === transaction.instrumentClassId
          )?.conversionRatio ?? 1;

        const securityType =
          instrumentShareholderInfoData?.instrumentClasses.find(
            (instrumentDetail) =>
              instrumentDetail.id === transaction.instrumentClassId
          )?.subType ?? "";
        if (FDBSecurityType.includes(securityType.toUpperCase())) {
          setRoundShare(
            (state) => state + transaction.numberOfShares * convRatio
          );
        }
        setRoundSecurity((state) => state + transaction.numberOfShares);

        setRoundAmountRaised((state) => state + transaction.amount);
        setTransactionData((state) => [
          ...state,
          {
            name:
              instrumentShareholderInfoData?.shareholders.find(
                (shareholders) =>
                  shareholders.id === transaction.companyInvestorId
              )?.name || "",
            investorType:
              instrumentShareholderInfoData?.shareholders.find(
                (shareholders) =>
                  shareholders.id === transaction.companyInvestorId
              )?.type || "",
            security:
              instrumentShareholderInfoData?.instrumentClasses.find(
                (instrumentDetail) =>
                  instrumentDetail.id === transaction.instrumentClassId
              )?.name || "",
            image: "",
            sharePrice: transaction.sharePrice,
            instrumentClassId: transaction.instrumentClassId,

            par: instrumentShareholderInfoData?.instrumentClasses.find(
              (instrumentDetail) =>
                instrumentDetail.id === transaction.instrumentClassId
            )?.parValue,
            conversionRatio: `1: ${
              instrumentShareholderInfoData?.instrumentClasses.find(
                (instrumentDetail) =>
                  instrumentDetail.id === transaction.instrumentClassId
              )?.conversionRatio
            }`,
            investorId: transaction.companyInvestorId,
            numberOfShares: transaction.numberOfShares,
            amountInvested: transaction.amount,
          },
        ]);
      }
    );
  };

  const extractChartData = () => {
    const shareholderChartData: ShareHolderCaptableChartModel[] = [];
    previewQuickRoundData?.holdingDifference?.forEach((shareholder) => {
      shareholderChartData.push({
        name: shareholder.name,
        preHoldingFdbPercentage: shareholder.preHoldingFdbPercentage,
        postHoldingFdbPercentage: shareholder.postHoldingFdbPercentage,
      });
    });
    setChartData(shareholderChartData);
  };

  const onValuationUpdate = (data: number) => {
    setValuationInputValue(data);
  };
  const onPpsUpdate = (data: number) => {
    setPpsInputValue(data);
  };

  const extractTotalRoundShares = (
    transaction: CumulativeHoldingQuickRound[]
  ) => {
    const esopOptions = transaction.find(
      (transaction) => transaction.type === "Options"
    );
    const esopSharesCount =
      (esopOptions?.postHoldingFdbShares || 0) -
      (esopOptions?.preHoldingFdbShares || 0);

    setEsopShares(esopSharesCount);
    setRoundShare((state) => state + esopSharesCount);
  };

  const getTotalData = () => {
    let totalDataLocal = 0;
    previewQuickRoundData?.holdingDifference?.forEach(
      (transaction: CumulativeHoldingQuickRound) => {
        totalDataLocal += transaction.postHoldingFdbShares;
      }
    );
    setTotalShare(totalDataLocal);
  };
  useEffect(() => {
    const shareholders = instrumentShareholderInfoData?.shareholders || [];
    const instrumentClasses =
      instrumentShareholderInfoData?.instrumentClasses || [];
    setShareType([]);
    setChartData([]);
    setTransactionData([]);
    setSecondary([]);
    setRoundShare(0);
    setTotalShare(0);
    getTotalData();
    setRoundAmountRaised(0);
    extractTransaction();
    extractTotalRoundShares(previewQuickRoundData?.holdingDifference || []);
    setValuationInputValue(previewQuickRoundData?.eventInfo?.valuation || 0);
    setPpsInputValue(previewQuickRoundData?.eventInfo?.pps || 0);
    extractChartData();
    if (previewQuickRoundData) {
      const transaction = extractSecondaryTransaction(
        previewQuickRoundData?.transactions?.secondaryTransactions || [],
        shareholders,
        instrumentClasses
      );
      setSecondary(transaction);
    }

    //Extracts the bonus transaction in required format to display in the table;
    const bonusSecurityDetails = extractBonusTransaction(
      previewQuickRoundData?.transactions?.quickBonusTransactions || [],
      instrumentClasses,
      shareholders
    );
    setBonusSecurityDetails(bonusSecurityDetails);

    extractBuybackDetailsFromPreview(
      previewQuickRoundData?.transactions?.quickBuybackParticipants || []
    );
    if (roundType === "secondary")
      extractSecondariesFromPreview(
        (previewQuickRoundData?.transactions as unknown as SecondaryTransaction[]) ||
          []
      );
    else {
      extractSecondariesFromPreview(
        previewQuickRoundData?.transactions?.secondaryTransactions || []
      );
    }

    if (roundType === "split") {
      setShareType([
        {
          instrument:
            previewQuickRoundData?.transactions?.split.securityType || "",
          id: "split",
          ratio: previewQuickRoundData?.eventInfo?.ratio || 1,
        },
      ]);
    } else if (roundType.toLowerCase() === "esop") {
      setShareType([
        {
          instrument: "Options",
          id: "options",
        },
      ]);
    } else if (roundType === "round") {
      const primaryTransactionInstruments = _.uniqBy(
        previewQuickRoundData?.transactions?.primaryTransactions,
        "instrumentClassId"
      );
      const secondaryTransactionInstruments = _.uniqBy(
        previewQuickRoundData?.transactions?.secondaryTransactions,
        "instrumentClassId"
      );
      const primaryInstrumentIds =
        primaryTransactionInstruments.map((ele) => ele.instrumentClassId) || [];
      const secondaryInstrumentIds =
        secondaryTransactionInstruments.map((ele) => ele.instrumentClassId) ||
        [];
      const roundInstrumentId: string[] = Array.from(
        new Set([
          ...primaryInstrumentIds,
          ...(secondaryInstrumentIds as string[]),
        ])
      );
      const shareTypeList: ShareType[] = [];
      instrumentClasses.forEach((element) => {
        if (roundInstrumentId.includes(element.id)) {
          shareTypeList.push({
            instrument: element.type,
            id: element.id,
            instrumentClass: element.name,
            par: element.parValue,
            conversation: element.conversionRatio,
          });
        }
      });
      setShareType(shareTypeList);
    }
  }, [previewQuickRoundData]);

  useEffect(() => {
    getInstrumentAndShareHolder();
    getQuickRoundPreview();
  }, []);

  const displayDiscardQuickRoundPopUp = () => {
    setDialog({
      open: true,
      name: "Delete",
      message: `Do you want to discard the current ongoing quick ${roundType?.toLocaleLowerCase()} ?`,
    });
  };

  const extractBuybackDetailsFromPreview = (
    quickBuybackParticipants: BuybackTransaction[]
  ) => {
    if (quickBuybackParticipants && quickBuybackParticipants.length > 0)
      setBuyBackParticipantDetails(quickBuybackParticipants);
  };

  const extractSecondariesFromPreview = (
    quickSecondaries: SecondaryTransaction[]
  ) => {
    const quickSecondariesData = quickSecondaries?.map((secondary) => {
      const buyer = instrumentShareholderInfoData?.shareholders?.find(
        (shareholder) => shareholder.id === secondary.companyBuyerInvestorId
      );
      const seller = instrumentShareholderInfoData?.shareholders?.find(
        (shareholder) => shareholder.id === secondary.companySellerInvestorId
      );
      const instrumentClass =
        instrumentShareholderInfoData?.instrumentClasses?.find(
          (instrument) => instrument.id === secondary.instrumentClassId
        );
      return {
        buyerName: buyer?.name || "",
        sellerName: seller?.name || "",
        investorType: buyer?.type,
        security: instrumentClass?.name,
        instrumentClassId: secondary.instrumentClassId,
        buyerInvestorId: secondary.companyBuyerInvestorId,
        sellerInvestorId: secondary.companySellerInvestorId,
        numberOfShares: secondary.numberOfShares,
        amountInvested: secondary.amount,
        par: instrumentClass?.parValue,
        conversionRatio: `1:${instrumentClass?.conversionRatio}`,
        date: secondary.date,
        currency: secondary.currency,
        exchangeRate: secondary.exchangeRate,
      };
    });
    if (quickSecondariesData && quickSecondariesData.length > 0)
      setSecondaries(quickSecondariesData as SecondaryTransactionDataModel[]);
  };
  const shareholderStore = useNewShareholderInstrumentStore();
  const handleDiscard = () => {
    setDialog({ open: false });
    discardQuickRound();
    resetTemplate();
    shareholderStore.setShareholder([]);
    bonusSecurityStore.setBonus([]);
    deleteOnGoingTransaction(companyId);
    navigate("/transactions/allTransactions");
  };

  const handleOnSuccessDiscard = () => {
    setDialog({ open: false });
    resetTemplate();
    shareholderStore.setShareholder([]);
    deleteOnGoingTransaction(companyId);
    navigate("/transactions/allTransactions");
  };

  const { mutate: approveTransaction } = useApproveTransactionPreview();
  const initialVal = {
    name: "",
    size: 0,
    type: "",
  };
  const companyName = useGetCompanyName();

  async function handleAction(action: Action) {
    if (action.disabled) return;

    if (action.name === "SSA Report") {
      exportSSA(template.eventId ?? "").then((res) => {
        const fileName = `SSA Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "SHA Report") {
      exportSHA(template.eventId ?? "").then((res) => {
        const fileName = `SHA Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "Horizontal SHA SSA") {
      exportHorizontalShaSsa(template.eventId ?? "").then((res) => {
        const fileName = `Horizontal SHA SSA_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "NonPriced Round") {
      exportNonPricedRound(template.eventId ?? "").then((res) => {
        const fileName = `NonPriced Round_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "Round Summary Report") {
      exportIndividualRoundSummaryReport(template.eventId ?? "").then((res) => {
        const roundTemplate = template as QuickRoundTransactionModel;
        let fileName = `Round Summary_${companyName}`;
        if (roundTemplate?.newRoundDetails?.roundName)
          fileName += `_${roundTemplate?.newRoundDetails?.roundName}`;
        downloadExcel(res, fileName);
      });
    }
  }

  function overrideCloseDatePopUp() {
    setDialog({
      open: true,
      name: "Close Date Popup",
    });
  }

  const { data: nonPricedRoundDetail } = useGetOngoingNonPricedRound();

  return (
    <>
      {previewQuickRoundData && !isPlaceholderData ? (
        <div className="w-full text-[#464E5F] bg-white ">
          <HStack className="items-center justify-between w-full gap-4 px-8 pt-4 pb-4">
            <VStack>
              <p className="text-lg font-semibold text-[#464E5F]">Summary</p>
              <p>Review the summary transaction and approve the round</p>
            </VStack>
            <HStack className="gap-4">
              {!sharedPage && (
                <ExportImport
                  text="Export Reports"
                  actions={
                    previewQuickRoundData.eventInfo?.pricedRound
                      ? [
                          {
                            name: "Round Summary Report",
                          },
                          {
                            name: "SSA Report",
                          },
                          {
                            name: "SHA Report",
                          },
                          {
                            name: "Horizontal SHA SSA",
                          },
                        ]
                      : [
                          {
                            name: "Round Summary Report",
                          },
                          {
                            name: "SSA Report",
                          },
                          {
                            name: "SHA Report",
                          },
                          {
                            name: "Horizontal SHA SSA",
                          },
                          {
                            name: "NonPriced Round",
                          },
                        ]
                  }
                  onAction={(action) => handleAction(action)}
                />
              )}
              <SecondaryCTAButton
                type="reset"
                buttonSize={ButtonSize.SMALL}
                onClick={displayDiscardQuickRoundPopUp}
              >
                Discard
              </SecondaryCTAButton>
            </HStack>
          </HStack>
          <div>
            <VStack className="gap-8">
              {(roundType === "round" ||
                roundType.toLowerCase() === "esop" ||
                roundType === "split") &&
                previewQuickRoundData?.eventInfo && (
                  <div className="flex flex-col gap-8 px-8 md:flex-row">
                    <div className="flex-col gap-8 md:w-2/3">
                      {roundType !== "split" && (
                        <div className="mb-8">
                          <TransactionDetails
                            transactionDetails={
                              previewQuickRoundData?.eventInfo
                            }
                            dilutionPercentage={(
                              (roundShares /
                                (previewQuickRoundData.preEventFdb +
                                  roundShares)) *
                              100
                            ).toFixed(3)}
                            roundAmountRaised={roundAmountRaised}
                            roundShares={roundSecurity}
                            date={previewQuickRoundData.eventDate || ""}
                            totalShares={totalShares || 0}
                            esopShares={esopShares || 0}
                            allotmentType={
                              previewQuickRoundData?.eventInfo.allotmentType
                            }
                            issuanceType={
                              previewQuickRoundData?.eventInfo.issuanceType
                            }
                            paymentType={
                              previewQuickRoundData?.eventInfo.paymentType
                            }
                          />
                        </div>
                      )}

                      <Valuation
                        transactionDetails={
                          roundType === "split"
                            ? previewQuickRoundData?.eventInfo
                            : undefined
                        }
                        date={
                          roundType === "split"
                            ? previewQuickRoundData.eventDate || ""
                            : undefined
                        }
                        valuation={
                          previewQuickRoundData?.eventInfo.valuation || 0
                        }
                        pps={previewQuickRoundData?.eventInfo.pps || 0}
                        preEventvaluation={
                          previewQuickRoundData?.preEventvaluation || 0
                        }
                        preEventPps={previewQuickRoundData?.preEventPps || 0}
                        onValuationUpdate={onValuationUpdate}
                        onPpsUpdate={onPpsUpdate}
                        currencySymbol={currencySymbol}
                        isShared={sharedPage}
                        isPriced={previewQuickRoundData?.eventInfo?.pricedRound}
                      />
                    </div>
                    <div className="flex-col gap-8 md:w-1/3">
                      {roundShares > 0 && totalShares > 0 && (
                        <ShareSummary
                          totalShare={totalShares || 0}
                          issuedShare={Math.floor(roundShares)}
                        />
                      )}
                      <div className="mt-8">
                        <ShareTypes shareType={shareType} />
                      </div>
                    </div>
                  </div>
                )}

              {roundType === "bonus" && (
                <div className="flex-col px-4 md:flex-row">
                  <VStack className="gap-8">
                    <QuickBonusPreviewDetails
                      quickBonusDetails={previewQuickRoundData}
                      instrumentClasses={
                        instrumentShareholderInfoData.instrumentClasses
                      }
                    />
                    {bonusSecurityDetails && (
                      <QuickBonusSecurityDetails
                        readOnly={true}
                        bonusSecurityDetails={bonusSecurityDetails}
                      />
                    )}
                  </VStack>
                </div>
              )}

              {roundType === "buyback" && (
                <div className="flex-col px-4 md:flex-row">
                  <VStack className="gap-8">
                    <QuickBuybackPreviewDetails
                      eventDetails={previewQuickRoundData}
                      instrumentClasses={
                        instrumentShareholderInfoData.instrumentClasses
                      }
                    />
                    <QuickBuybackParticipantsDetails
                      readOnly={true}
                      buyBackParticipantDetails={buyBackParticipantDetails}
                      instrumentClasses={
                        instrumentShareholderInfoData.instrumentClasses
                      }
                      shareholders={
                        instrumentShareholderInfo?.shareholders || []
                      }
                    />
                  </VStack>
                </div>
              )}
              {roundType === "secondary" && (
                <div className="flex-col px-4 md:flex-row">
                  <QuickSecondariesTransactions
                    isPreviewOnly={true}
                    quickSecondaries={secondaries}
                  />
                </div>
              )}

              <Dialog open={dialog.open} maxWidth="md">
                {dialog.name === "Delete" && (
                  <DeleteModal
                    data={dialog.message}
                    onPrimaryAction={handleDiscard}
                    onSecondaryAction={() => setDialog({ open: false })}
                  />
                )}
                {dialog.name === "Close Date Popup" && (
                  <OverrideCloseDatePopup
                    onPrimaryAction={() => {
                      setDialog({
                        open: true,
                        name: "Close Date Popup",
                        data: dialog.data,
                      });
                    }}
                    onSecondaryAction={() =>
                      setDialog({
                        open: false,
                        name: "Close Date Popup",
                      })
                    }
                    onClose={() =>
                      setDialog({
                        open: false,
                        name: "Close Date Popup",
                      })
                    }
                    data={{
                      companyId,
                      eventId: _id,
                      valuation: valuationInputValue,
                      pricePerShare: ppsInputValue,
                    }}
                    handleOnSuccessDiscard={() => handleOnSuccessDiscard()}
                    resetTemplate={resetTemplate}
                    closeDate={
                      previewQuickRoundData.eventDate?.split("T")[0] || ""
                    }
                    mode={""}
                  />
                )}
              </Dialog>

              {previewQuickRoundData?.holdingDifference && (
                <div className="mx-8">
                  <AGGridShareholderQuickRoundTable
                    data={previewQuickRoundData?.holdingDifference || []}
                  />
                </div>
              )}

              {roundType === "round" && (
                <div className="px-4 py-8">
                  <AGGridRoundTransactionTable
                    isInvestorPortal={sharedPage}
                    isPreviewOnly={true}
                    secondaryTransactionProps={secondary}
                    primaryTransactionProps={transactionData}
                  />
                </div>
              )}

              {!sharedPage && (
                <VStack className="w-full pb-2">
                  <HStack className="justify-between px-4">
                    <SecondaryCTAButton
                      type="reset"
                      onClick={() => {
                        queryClient.invalidateQueries(
                          "ongoingTransactionRound"
                        );
                        setTemplate((prev: any) => ({
                          ...prev,
                          stepNo: 1,
                          fileType: initialVal,
                        }));
                      }}
                    >
                      Back
                    </SecondaryCTAButton>
                    <PrimaryCTAButton
                      loading={loading}
                      buttonSize={ButtonSize.SMALL}
                      onClick={() => {
                        if (roundType === "round") overrideCloseDatePopUp();
                        else {
                          setLoading(true);

                          approveTransaction(
                            {
                              companyId,
                              eventId: _id,
                              valuation: valuationInputValue,
                              pricePerShare: ppsInputValue,
                            },
                            {
                              onSuccess: (data) => {
                                handleOnSuccessDiscard();
                                setLoading(false);
                                queryClient.invalidateQueries("getAllEvents");
                                queryClient.invalidateQueries(
                                  "getAllTransactions"
                                );
                                queryClient.invalidateQueries(
                                  "getInstrumentAndShareHolder"
                                );
                                queryClient.invalidateQueries(
                                  "getSecurityOverviewDetails"
                                );
                                queryClient.invalidateQueries(
                                  "getShareholderOverview"
                                );
                                resetTemplate();
                                navigate("/landing/dashboard");
                                toast("Transaction Added Successfully!", {
                                  type: "success",
                                  autoClose: 2000,
                                });
                              },

                              onError: (err: any) => {
                                setLoading(false);
                                toast(err.response.data.reason, {
                                  type: "error",
                                  autoClose: 2000,
                                });
                              },
                            }
                          );
                        }
                      }}
                    >
                      Approve
                    </PrimaryCTAButton>
                  </HStack>

                  {roundType === "round" &&
                    ((template?.roundType as any) === "MANUAL" ||
                      (template?.roundType as any) === "ROUNDMODELING") &&
                    previewQuickRoundData?.eventInfo?.pricedRound &&
                    (nonPricedRoundDetail?.length || 0) > 0 &&
                    (template.newRoundInput?.convertibles?.length || 0) ===
                      0 && (
                      <div className="px-4 py-1 text-right">
                        <p className="text-red-500">
                          Do you want to add this round without converting the
                          pending convertibles?
                        </p>
                      </div>
                    )}
                </VStack>
              )}
            </VStack>
          </div>
        </div>
      ) : (
        <div className="flex justify-center w-full m-auto bg-white h-96">
          <Loader />
        </div>
      )}
    </>
  );
};

export default TransactionPreviewPage;
