import { HStack } from "../components/utils";
import { FilterConditionModel } from "../pages/dashboardPage/AgGridCache";
import CloudSetting from "../pages/rounds/CloudIcon";

const GenericTableHeader = ({
  heading,
  tableId,
  isLoading = false,
  showCloud = false,
  filterCondition,
  setFilterCondition,
  subHeading,
  count,
  className,
  currencytype,
  lastUpdatedDetailsContent,
}: {
  heading: string;
  tableId?: string;
  isLoading?: boolean;
  showCloud?: boolean;
  subHeading: string;
  count?: number;
  className?: string;
  filterCondition?: FilterConditionModel;
  setFilterCondition?: (setFilterCondition: FilterConditionModel) => void;
  currencytype?: string;
  lastUpdatedDetailsContent?: string;
}) => (
  <div className={`flex flex-col px-6 lg:justify-start ${className}`}>
    <p className="md:text-lg text-base font-medium text-headerColor whitespace-nowrap">
      {heading}
    </p>
    <HStack className="mr-4 md:mr-12 items-center gap-2">
      <p className="text-xs1 font-medium text-descriptionColor whitespace-nowrap">
        {count
          ? `${count.toLocaleString(currencytype)} ${subHeading}`
          : `${subHeading}`}
      </p>
      {lastUpdatedDetailsContent && (
        <p className="text-xs1 -ml-2 font-medium text-descriptionColor whitespace-nowrap">
          &nbsp;{lastUpdatedDetailsContent}
        </p>
      )}
      {/* {showCloud && filterCondition && setFilterCondition && tableId && (
        <CloudSetting
          tableId={tableId}
          isLoading={isLoading}
          filterCondition={filterCondition}
          setFilterCondition={setFilterCondition}
        />
      )} */}
    </HStack>
  </div>
);

export default GenericTableHeader;
