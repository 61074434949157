import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router";
import { Popover, Transition } from "@headlessui/react";

import { RoundDetailModel } from "../../types/DashboardModel";
import { genericCardDetails } from "../../types/SecurityModel";
import { hexToRGBA } from "../../utils/colorUtils";
import {
  getCurrencyType,
  getCurrencySymbol,
} from "../../utils/currencyFormatter";
import { FormatNumberSpan } from "../../utils/currencyRoboto";
import { VStack, HStack } from "../utils";
import RoundTag from "../../shared/RoundTag";

import { getDougnutTagColor } from "../../constants/ShareholderConstants";
import HalfDoughnutChart from "../../pages/shareholders/HalfDoughnutChart";
import { ShareholderTag } from "../AllotmentTag";
import { InvestorStatus } from "../../types/GlobalInvestors";
import { useGetGlobalInvestors } from "../../queries/globalInvestors";

type FirstCardComponentProps = {
  name: string;
  type: string;
  percentage: number;
  isPromoter: boolean;
  isInstitutional: boolean;
};

export function FirstCardComponent({
  data,
  isInvestorPortal = false,
}: {
  data: FirstCardComponentProps;

  isInvestorPortal?: boolean;
}) {
  const color = getDougnutTagColor(data.type);
  const rgbaColor = hexToRGBA(color.text, 0.2);

  const seriesChartData = {
    display: false,
    labels: [],
    datasets: [
      {
        label: "% of Holding",
        data: [data.percentage, 100 - data.percentage],
        backgroundColor: [color.border, rgbaColor],
        borderColor: [color.border, rgbaColor],
        borderWidth: 0, // Set the desired border width
        borderRadius: 0,
      },
    ],
  };

  return (
    <div className={`flex-0 w-4/12 rounded-l-lg border shadow-sm bg-white p-4`}>
      <VStack>
        <VStack>
          <p className="text-lg font-semibold text-left">
            {data.name?.toLowerCase()?.includes("esop")
              ? data.name?.replace(/esop/gi, "ESOP")
              : data.name}
          </p>
          <p
            className={`px-4 py-0.5 text-xxs font-medium  w-fit h-fit rounded-sm ${color.bgColor} text-[${color.text}]`}
          >
            {data.type}
          </p>
        </VStack>

        <div className={` flex flex-col items-center`}>
          <HalfDoughnutChart data={seriesChartData} type={data.type} />
          <p className="px-4 py-3 text-xs text-gray-500 ">% of Holding</p>
        </div>
        <VStack className="p-4">
          <HStack className="justify-between">
            <HStack>
              {data?.isPromoter && (
                <ShareholderTag
                  tagName="Promoter"
                  shareholderType={data.type}
                />
              )}
              {data?.isInstitutional && (
                <ShareholderTag
                  tagName="Institutional"
                  shareholderType={data.type}
                />
              )}
            </HStack>
          </HStack>
        </VStack>
      </VStack>
    </div>
  );
}

export function SecondCardComponent({
  cardDetails,
  isInvestorPortal = false,
  showCertificateNumber = false,
  cardType,
  globalInvestorStatus,
  globalInvestorId,
}: {
  cardDetails: genericCardDetails;
  isInvestorPortal?: boolean;
  showCertificateNumber?: boolean;
  cardType: string;
  globalInvestorId?: string;
  globalInvestorStatus?: InvestorStatus;
}) {
  const currency = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const [show, setShown] = useState(false);

  const navigate = useNavigate();
  const { data: globalAccount } = useGetGlobalInvestors();
  const onClickNavigate = (url: string) => navigate(url);
  const [roundName, setRoundName] = useState<RoundDetailModel[]>([]);

  useEffect(() => {
    const roundName: RoundDetailModel[] = [];
    cardDetails.roundIdentifierDetails?.forEach((element) => {
      if (element.roundType === "round") roundName.push(element);
    });
    setRoundName(roundName);
  }, []);

  const ranges: string[] = [];
  for (const range of cardDetails.shareholderDetails?.shareRanges || []) {
    ranges.push(...range.split(","));
  }
  const selectedInvestor = globalAccount?.find((account) =>
    account.investors.some(
      (investor) => investor.globalInvestorId === globalInvestorId
    )
  );

  return (
    <div
      className={`flex-0 w-4/12  rounded-r-lg border shadow-sm bg-[#FBFBFB]`}
    >
      <HStack className="gap-8">
        {cardType === "security" && (
          <HStack className="justify-between flex-1 px-4 py-2 text-xs ">
            <VStack className="gap-3 pt-1">
              <p className="text-gray-500 text-xs2">No. of Securities</p>
              <p className="text-gray-500 text-xs2">No. of Classes</p>
              <p className="text-gray-500 text-xs2">Total Value</p>
              <p className="text-gray-500 text-xs2">Rounds</p>
            </VStack>
            <VStack className="gap-3 pt-2 text-right ">
              <p className="text-xs font-semibold">
                <FormatNumberSpan
                  value={cardDetails.noOfShares}
                  format={currency}
                />
              </p>
              <p className="text-xs font-semibold">
                <FormatNumberSpan
                  value={cardDetails.noOfClassess}
                  format={currency}
                />
              </p>

              <p className="text-xs font-semibold">
                <FormatNumberSpan
                  value={cardDetails.capitalValue}
                  format={currency}
                />
              </p>
              <HStack className="justify-end gap-1 text-xs font-semibold text-right">
                <RoundTag
                  roundNames={roundName || []}
                  navigation-name="Round Tag"
                  navigate={isInvestorPortal ? () => {} : onClickNavigate}
                />
              </HStack>
            </VStack>
          </HStack>
        )}
        {cardType === "shareholder" && (
          <HStack className="flex flex-row justify-between flex-1 px-4 py-2 text-xs">
            <VStack className="gap-3 pt-1">
              <p className="text-gray-500 text-xs2">No.of Securities</p>
              <p className="text-gray-500 text-xs2">
                Current Worth ({currencySymbol})
              </p>
              {globalInvestorStatus && (
                <p className="text-gray-500 text-xs2 whitespace-nowrap">
                  Global Investor Status
                </p>
              )}
              {showCertificateNumber &&
                cardDetails.shareholderDetails?.type !== "Options" && (
                  <p className="text-gray-500 text-xs2 whitespace-nowrap">
                    Share Certificate No.
                  </p>
                )}
            </VStack>
            <VStack className="gap-3 pt-2 text-right ">
              <p className="text-xs font-semibold">
                <FormatNumberSpan
                  value={cardDetails.shareholderDetails?.shares}
                  format={currency}
                />
              </p>
              <p className="text-xs font-semibold">
                <FormatNumberSpan
                  value={cardDetails.shareholderDetails?.netWorth}
                  format={currency}
                />
              </p>
              {globalInvestorStatus && (
                <div className="grid-cols-2 gap-2">
                  {globalInvestorStatus === "pending" && (
                    <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300 border border-yellow-300">
                      Pending
                    </span>
                  )}
                  {globalInvestorStatus === "rejected" && (
                    <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 border border-red-400">
                      Rejected
                    </span>
                  )}
                  {globalInvestorStatus === "approved" && (
                    <Popover className="relative">
                      <Popover.Button
                        onMouseEnter={() => {
                          setShown(true);
                        }}
                        onMouseLeave={() => {
                          setShown(false);
                        }}
                      >
                        <span className="bg-green-100 text-green-800 text-xs font-medium me-2 w-auto px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 border border-green-400">
                          Approved
                        </span>
                      </Popover.Button>

                      <Transition
                        show={show}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          onMouseEnter={() => {
                            setShown(true);
                          }}
                          onMouseLeave={() => {
                            setShown(false);
                          }}
                          className="absolute z-10 flex w-screen mt-5 max-w-max -right-8 "
                        >
                          <div className="flex-auto p-2 overflow-hidden text-sm text-left bg-white rounded-lg shadow-lg w-96">
                            <VStack className="gap-3 pt-1">
                              <HStack>
                                <p className="text-gray-500 text-xs2">
                                  Global Account Name :
                                </p>
                                <span className="">
                                  {selectedInvestor && selectedInvestor.name}
                                </span>
                              </HStack>
                              <HStack>
                                <p className="text-gray-500 text-xs2">
                                  Account Email :
                                </p>
                                <p className="pl-1 text-xs2">
                                  {selectedInvestor && selectedInvestor.email}
                                </p>
                              </HStack>
                              <HStack>
                                <p className="text-gray-500 text-xs2">
                                  Global Investor Id :
                                </p>
                                <p className="pl-1 text-xs2">
                                  {selectedInvestor &&
                                    selectedInvestor.investors[0]
                                      .globalInvestorId}
                                </p>
                              </HStack>
                            </VStack>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  )}
                </div>
              )}
              {showCertificateNumber &&
                cardDetails.shareholderDetails?.type !== "Options" && (
                  <div
                    className={`grid ${
                      ranges.length > 1 ? "grid-cols-2 gap-2" : ""
                    }`}
                  >
                    {ranges.map((range, index) => (
                      <p
                        key={index}
                        className={`text-xs font-semibold text-center cursor-pointer border rounded-lg border-black px-1.5 ${
                          ranges.length === 1 ? "text-center" : ""
                        }`}
                      >
                        {range}
                      </p>
                    ))}
                  </div>
                )}

              <p className="text-xs font-semibold"></p>
            </VStack>
          </HStack>
        )}
      </HStack>
    </div>
  );
}
