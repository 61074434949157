import _ from "lodash";
import { useNavigate } from "react-router";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { limitString } from "../../utils/string";
import { getSecurityColor } from "../../utils/historicUtilities";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import Avatar from "../Utility/Avatar";
import { CaptableTag } from "../../components/AllotmentTag";
import RoundTag from "../../shared/RoundTag";

export function ShareholderNameRender(data: any) {
  if (data.value.name?.toLowerCase()?.includes("esop"))
    data.value.name = data.value.name?.replace(/esop/gi, "ESOP");
  return (
    <td className="flex flex-col text-sm text-left pb-2">
      <div className="flex">
        <div className="text-left pr-2">
          {data.value.name !== "Total" ? (
            <Avatar name={data.value.name || ""} />
          ) : (
            ""
          )}
        </div>
        <div
          className={` ${
            data.value.name === "Total"
              ? "pt-2 cursor-pointer"
              : "ml-2 cursor-pointer"
          } `}
        >
          {data.value.name?.length > 24 ? (
            <p
              data-event-name="captableShareholderDrilling"
              className={`${tableFirstColStyle}`}
            >
              {limitString(data.value.name || "", 24)}
            </p>
          ) : (
            <p
              data-event-name="captableShareholderDrilling"
              className={`${tableFirstColStyle}`}
            >
              {limitString(data.value.name || "", 24)}
            </p>
          )}

          {data.value.name !== "Total" ? (
            <CaptableTag tagName={data.value.type ?? "Others"} />
          ) : (
            ""
          )}
        </div>
      </div>
    </td>
  );
}

export function SecurityTypeRender(data: any) {
  return (
    <td className=" text-sm text-left py-2.5">
      <div className="">
        {_.uniq(data.data.securityType).map((securityType: any) => (
          <p
            key={securityType}
            className={`${getSecurityColor(
              securityType
            )}  text-xs text-center w-24 align-middle font-semibold leading-5 rounded-sm mb-1`}
          >
            {securityType}
          </p>
        ))}
      </div>
    </td>
  );
}

export function HoldingPercentageRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.holdingPercentage || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function NumberOfSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.numberOfShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function AvgPPSRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.avgPPS,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function CurrentValueRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.currentValue,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function RoundsRender(data: any) {
  const navigate = useNavigate();
  const onClickNavigate = (url: string) => navigate(url);
  return (
    <div className="pt-2">
      <RoundTag
        roundNames={data.data.rounds || []}
        navigate={data.data.cin ? () => {} : onClickNavigate}
      />
    </div>
  );
}
