import Box from "@mui/material/Box";
import { XMarkIcon } from "@heroicons/react/24/outline";
import TransactionDocumentsAgGridTable, {
  TransactionDocTypes,
} from "./TransactionDocumentsAgGridTable";
import { VStack } from "../../components/utils";
import { useAuthStore } from "../../store";
import { useGetTransactionDocuments } from "../../queries/transactionRound";

export function TransactionDocPopup({
  eventId,
  onSecondaryAction = () => {},
  onPrimaryAction = () => {},
}: {
  eventId: string;
  onSecondaryAction: () => void;
  onPrimaryAction: () => void;
}) {
  const companyId = useAuthStore().companyId ?? "";
  const { data: transactionData } = useGetTransactionDocuments(
    eventId || "",
    companyId
  );

  return (
    <div>
      <Box className="px-10 text-lg font-medium border-b py-7 w-200">
        <h6 className="flex justify-between">
          {"Transaction Document"}
          <XMarkIcon
            className="h-5 mt-1 font-medium cursor-pointer"
            onClick={() => onSecondaryAction()}
          />
        </h6>
      </Box>
      <VStack className="p-6 gap-9 w-200">
        <TransactionDocumentsAgGridTable
          transactionDocuments={transactionData || []}
          eventId={eventId}
        />
      </VStack>
    </div>
  );
}
