import { memo, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import {
  CellClickedEvent,
  ColDef,
  SideBarDef,
  GridApi,
} from "ag-grid-community";
import { Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { AllTransactionModel } from "../../types/AllTransactionModel";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  getCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { useAuthStore } from "../../store/useAuthStore";
import { Box, HStack, VStack, useGetCompanyName } from "../../components/utils";
import { checkTypeOfRound } from "./RoundsUtility";
import QuickSearch from "./QuickSearch";
import "../../styles/reset.css";
import { ButtonSize, IconCTAButton } from "../quickRound/CTAButtonComponents";
import GenericTableHeader from "../../shared/TableHeader";
import { tableCellStyle } from "../../components/TableComponent";
import {
  exportRoundSummaryReport,
  exportSecondarySummaryReport,
} from "../../api/Report";
import { downloadExcel } from "../../utils/DownloadFile";
import ExportMultidateSheetDetails from "../../modals/ExportMultidateSheetDetails";
import { useAgGridTableState } from "../../store/agGridTableStore";
import {
  PrimaryTransactionDataModel,
  SecondaryTransactionDataModel,
} from "../../types/quickRound";
import {
  AmountInInvestmentRender,
  AmountInvestedRender,
  NumberOfSharesRender,
  SecurityTypeRender,
  SharePriceRender,
  TransactionNameRender,
} from "./RoundsTransactionAgComponent";
import { FormatNumberSpan } from "../../utils/currencyRoboto";
import SearchInput from "../../components/shared/SearchInput";
import { handleEventForTracking } from "../../amplitudeAnalytics";

function AGGridPrimaryRoundTransactionTable({
  isInvestorPortal = false,
  isPreviewOnly = false,
  primaryTransactionProps,
  secondaryTransactionProps,
  isModeling = false,
}: {
  isPreviewOnly: boolean;
  isInvestorPortal?: boolean;
  primaryTransactionProps: PrimaryTransactionDataModel[];
  secondaryTransactionProps: SecondaryTransactionDataModel[];
  isModeling?: boolean;
}) {
  const currencySymbol = getCurrencySymbol();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const companyName = useGetCompanyName();
  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: string;
  }>({
    open: false,
  });
  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.roundPrimarytransactionColumnModel);
      setFilterSetting(data.data?.roundPrimarytransactionFilterModel);
    });
  }, []);
  const { mutate: postOnFilter } = usePostOnFilterState();
  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }
  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }
  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };
  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };
  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        roundPrimarytransactionColumnModel: columnState,
        roundPrimarytransactionFilterModel: filterState,
      },
    });
  };
  const componentsRegistery = useMemo(
    () => ({
      transactionName: memo(TransactionNameRender),
      securityType: memo(SecurityTypeRender),
      sharePrice: memo(SharePriceRender),
      amountInvested: memo(AmountInvestedRender),
      amountInInvestment: memo(AmountInInvestmentRender),
      numberOfShares: memo(NumberOfSharesRender),
    }),
    []
  );
  const navigate = useNavigate();
  const onClickTransactionName = (id: string) => {
    if (!isInvestorPortal) {
      handleEventForTracking({ eventName: `Shareholder Name` });
      navigate(`/shareholders/individual/${id}`);
    }
  };
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Name",
        field: "transactionName",
        filter: "agMultiColumnFilter",
        getQuickFilterText: (params) => params.value.name,
        comparator(valueA, valueB, ..._: any[]) {
          return valueA.name > valueB.name ? 1 : -1;
        },
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Round Name",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.name,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) =>
                  params.getValue("transactionName").name,
              },
            },
            {
              title: "Round Type",
              filter: "agSetColumnFilter",
              display: "subMenu",
              buttons: ["reset"],
              filterParams: {
                buttons: ["reset"],
                keyCreator: (params: any) => {
                  const transactionType = params.value.type;
                  return transactionType;
                },
                valueFormatter: (params: any) => {
                  const transactionType = params.value.type;
                  return transactionType;
                },
              },
            },
          ],
        },
        pinned: "left",
        cellRenderer: "transactionName",
        initialWidth: 250,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipTransactionName",
      },
      {
        headerName: "Security Type",
        field: "securityType",
        cellRenderer: "securityType",
        cellStyle: tableCellStyle,
        sortable: false,
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "No. of Securities",
        field: "numberOfShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberOfShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipNumberOfShares",
      },
      {
        headerName: `Transaction Price (${currencySymbol})`,
        field: "sharePrice",
        filter: "agNumberColumnFilter",
        cellRenderer: "sharePrice",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipSharePrice",
      },
      {
        headerName: `Amount Invested (Company Currency) (${currencySymbol})`,
        field: "amountInvested",
        filter: "agNumberColumnFilter",
        cellRenderer: "amountInvested",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAmountInvested",
      },
      {
        headerName: `Amount Invested`,
        field: "amountInInvestment",
        filter: "agMultiColumnFilter",
        cellRenderer: "amountInInvestment",
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAmountInInvestment",
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Amount in Tranaction Currency",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.investmentAmount,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) =>
                  params.getValue("amountInInvestment").investmentAmount,
              },
            },
            {
              title: "Amount in Round Currency",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.roundAmount,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) =>
                  params.getValue("amountInInvestment").roundAmount,
              },
            },
          ],
        },
      },
    ],
    []
  );
  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };
  async function handleAction(action: Action) {
    if (action.disabled) return;
    if (action.name === "Round Summary Report") {
      exportRoundSummaryReport("").then((res) => {
        const fileName = `Round Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "Secondary Summary Report") {
      exportSecondarySummaryReport().then((res) => {
        const fileName = `Secondary Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "All Transaction Summary Report") {
      setDialog({
        open: true,
        data: "All Transaction Summary Report",
      });
    }
  }
  const currencyType = getCurrencyType();
  const rowData = useMemo(
    () =>
      primaryTransactionProps?.map((template) => ({
        id: template.investorId,
        transactionName: {
          name: template.name,
          type: template.investorType,
        },
        tooltipTransactionName: template.name,
        securityType: template.security,
        sharePrice: template.exchangeRate
          ? !template.exchangeRateForRound
            ? template.sharePrice
            : template.sharePrice *
              template.exchangeRate *
              template.exchangeRateForRound
          : template.exchangeRateForRound
          ? template.sharePrice * template.exchangeRateForRound
          : template.sharePrice,
        tooltipSharePrice: template.exchangeRate
          ? !template.exchangeRateForRound
            ? template.sharePrice.toLocaleString(currencyType)
            : (
                template.sharePrice *
                template.exchangeRate *
                template.exchangeRateForRound
              ).toLocaleString(currencyType)
          : template.exchangeRateForRound
          ? (
              template.sharePrice * template.exchangeRateForRound
            ).toLocaleString(currencyType)
          : template.sharePrice.toLocaleString(currencyType),
        amountInvested: template.amountInvested,
        tooltipAmountInvested:
          template.amountInvested.toLocaleString(currencyType),
        tooltipAmountInInvestment:
          currencyType === getCurrency(template.currency ?? currencyType) ||
          currencyType === getCurrency(template.roundCurrency ?? currencyType)
            ? template.amountInvested.toLocaleString(currencyType)
            : "",
        amountInInvestment: {
          investmentAmount:
            template.amountInvestedInCurrency || template.amountInvested,
          roundAmount:
            template.amountInvestedInCurrency! * template.exchangeRate!,
          roundCurrency: template.roundCurrency ?? currencyType,
          investmentCurrency: template.currency ?? currencyType,
          exchangeRate: template.exchangeRate,
        },
        investorType: template.investorType,
        par: template.par,
        conversionRatio: template.conversionRatio,
        numberOfShares: template.numberOfShares,
        tooltipNumberOfShares:
          template.numberOfShares.toLocaleString(currencyType),
        currencyType,
        currencySymbol,
      })),
    [primaryTransactionProps]
  );
  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (
        cellParams.value.name !== "Total" &&
        cellParams.column.getColId() === "transactionName"
      ) {
        onClickTransactionName(template.id);
      }
    }
  };
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const gridContainerRef = useRef(null);
  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key);
        } else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };
  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );
    const totalShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.numberOfShares,
      0
    );
    const totalAmount = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.amountInvested,
      0
    );
    api.setPinnedBottomRowData([
      {
        transactionName: {
          name: "Total",
          type: null,
        },
        securityType: null,
        sharePrice: null,
        amountInvested: totalAmount,
        amountInInvestment: {
          investmentAmount: null,
          roundAmount: null,
          roundCurrency: null,
          investmentCurrency: null,
          exchangeRate: null,
        },
        tooltipAmountInvested: totalAmount.toLocaleString(currencyType),
        investorType: null,
        par: null,
        consversionRatio: null,
        numberOfShares: totalShares,
        tooltipNumberOfShares: totalShares.toLocaleString(currencyType),
        currencyType,
      },
    ]);
  }
  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);
    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    setPinnedBottomRowData(grid);
    uploadFilterAndColumn();
  };
  return (
    <>
      <HStack className="items-center justify-end py-2 bg-white rounded-md">
        <HStack className="gap-4 px-4 py-2 ">
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          <IconCTAButton
            value={"Columns"}
            buttonSize={ButtonSize.SMALL}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
          <IconCTAButton
            value={"Filters"}
            buttonSize={ButtonSize.SMALL}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${
              (rowData.length >= 10 ? 10 : rowData.length + 3) * 58
            }px`,
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            onRowDataUpdated={setPinnedBottomRowData}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            enableCharts={true}
            enableRangeSelection={true}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed"}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </>
  );
}
export default AGGridPrimaryRoundTransactionTable;
