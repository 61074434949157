import { useEffect, useMemo, useState } from "react";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import { HStack, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { useAuthStore } from "../../store";
import { useOnlineStatus } from "../modeling/isOnline";
import NoInternetConncetion from "../modeling/NoInternetBanner";
import ModelingHeadersStack from "./ModelingHeadersStack";
import { useRoundModelStore } from "../../store/roundModelingStore";
import RoundModelingStepper from "./RoundModelingStepper";
import RoundModelCreationComponent from "./ModelCreationComponent";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import DeleteModal from "../modeling/DeleteModal";
import { queryClient } from "../../queries";
import ExistingInvestorTab from "./ExistingInvestorTab";
import NewInvestorsTab from "./NewInvestorTab";
import SecondaryPageTab from "./SecondaryPageTab";
import ModelSummaryTab from "./ModelSummary";
import Convertible from "../modeling/Convertible";
import ShareModal from "../share/ShareModal";
import { SharedResource } from "../../constants/SharedResource";
import { useGetListOfModeling } from "../../queries/modeling";
import GiveModelAccess from "../share/GiveModelAccess";

function RoundModelTemplate() {
  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: string;
    data?: string;
  }>({
    open: false,
  });
  const roundModelStore = useRoundModelStore();
  const isOnline = useOnlineStatus();
  const isAuthenticated = useAuthStore().isAuthenticated;

  const [matchFound, setMatchFound] = useState<boolean>(false);

  const {
    refetch: getModelList,
    isPlaceholderData,
    data: pricedRoundDetails,
  } = useGetListOfModeling();

  const [showShare, setShowShare] = useState(false);

  useEffect(() => {
    getModelList();
  }, []);

  useEffect(() => {
    const foundMatch = pricedRoundDetails?.find(
      (model) => model.modelId === roundModelStore.roundCreation.modelId
    );
    setMatchFound(!!foundMatch);
  }, [pricedRoundDetails, roundModelStore.roundCreation.modelId]);

  useEffect(() => {
    if (isAuthenticated && !roundModelStore.roundCreation.modelId) {
      roundModelStore.connect();
      roundModelStore.recieveMessage("message");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (roundModelStore.roundCreation.errorMessage && isAuthenticated) {
      toast(roundModelStore.roundCreation.errorMessage, {
        type: "error",
        autoClose: 1000,
      });
    }
  }, []);

  const navigate = useNavigate();

  return (
    <div className="min-h-full">
      {!isOnline && <NoInternetConncetion />}
      <Dialog open={dialog.open} maxWidth="lg">
        <>
          {dialog.mode === "Delete" && (
            <DeleteModal
              onPrimaryAction={() => {
                roundModelStore.reset(roundModelStore.roundCreation);
                setDialog({ open: false });
                queryClient.invalidateQueries("listofmodeling");
                navigate("/modeling/overview");
              }}
              onSecondaryAction={() => setDialog({ open: false })}
              data={
                "Are you sure you want to delete the model? Data will be permanently removed. This action cannot be undone."
              }
            />
          )}
        </>
      </Dialog>
      <HStack className="justify-between">
        <BCHeader
          className={`items-baseline`}
          bcTitle="Round Modeling"
          bcSubTitle={`Model (${
            !roundModelStore.roundCreation.isPublic ? "Private" : "Public"
          })`}
        />
        <HStack className="gap-4">
          {matchFound && roundModelStore.roundCreation.currentTab === 6 && (
            <div className="pl-2">
              <SecondaryCTAButton
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  setShowShare(true);
                }}
              >
                Share
              </SecondaryCTAButton>
            </div>
          )}
          {showShare && (
            <ShareModal
              resourceId={roundModelStore.roundCreation.modelId || ""}
              resourceType={SharedResource.PRICED_ROUND_MODEL}
              close={() => setShowShare(false)}
              showShareModel={true}
            />
          )}
          {(roundModelStore.roundCreation.currentTab === 1 ||
            roundModelStore.roundCreation.currentTab === 6) && (
            <SecondaryCTAButton
              buttonSize={ButtonSize.SMALL}
              className="h-8"
              event-name="Discard Model Button"
              onClick={() => {
                setDialog({
                  open: true,
                  mode: "Delete",
                  data: roundModelStore.roundCreation.modelId,
                });
                queryClient.invalidateQueries("listofmodeling");
              }}
            >
              Discard
            </SecondaryCTAButton>
          )}
        </HStack>
      </HStack>
      {!roundModelStore.roundCreation.modelId ? (
        <VStack className="gap-4 mt-4 loading">
          <HStack className="w-full h-24">
            <div className="flex-1 w-full h-24 placeholderLoader">
              <div className="w-full"></div>
            </div>
          </HStack>
          <HStack className="w-full h-96">
            <div className="w-10 h-96 placeholderLoader">
              <div className="w-10"></div>
            </div>
            <div className="flex-1 w-full h-96 placeholderLoader">
              <div className="w-full"></div>
            </div>
          </HStack>
        </VStack>
      ) : (
        <VStack
          className={`mt-4 ${
            roundModelStore.roundCreation.modelId ? "" : "loading"
          }`}
        >
          {roundModelStore.roundCreation.onGoingRoundHeaders &&
          roundModelStore.roundCreation.currentTab !== 8 ? (
            <ModelingHeadersStack
              currentCompanyDetail={
                roundModelStore.roundCreation.onGoingRoundHeaders
              }
              roundName={
                roundModelStore.roundCreation.roundDetailsTab?.roundName ?? ""
              }
              newPPS={roundModelStore.roundCreation.convertibles?.newPPS}
            />
          ) : (
            <HStack className="w-full h-20 loading">
              <div className="flex-1 w-full h-20 placeholderLoader">
                <div className="w-full"></div>
              </div>
            </HStack>
          )}
          {roundModelStore.roundCreation.modelId && (
            <HStack className="flex-col w-full gap-4 mt-4 lg:flex-row">
              <RoundModelingStepper
                templateNumber={roundModelStore.roundCreation.currentTab ?? 1}
              />
              <div className="w-full">
                {roundModelStore.roundCreation.currentTab === 1 ? (
                  <RoundModelCreationComponent />
                ) : null}
                {roundModelStore.roundCreation.currentTab === 2 ? (
                  <>
                    <Convertible />
                  </>
                ) : null}
                {roundModelStore.roundCreation.currentTab === 3 ? (
                  <ExistingInvestorTab />
                ) : null}
                {roundModelStore.roundCreation.currentTab === 4 ? (
                  <NewInvestorsTab />
                ) : null}
                {roundModelStore.roundCreation.currentTab === 5 ? (
                  <SecondaryPageTab />
                ) : null}
                {roundModelStore.roundCreation.currentTab === 6 ? (
                  <ModelSummaryTab />
                ) : null}
              </div>
            </HStack>
          )}
        </VStack>
      )}
    </div>
  );
}

export default RoundModelTemplate;
