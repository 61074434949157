import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Dialog } from "@mui/material";
import _ from "lodash";
import { Box, HStack, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { useSelectedShareholderFilterStore } from "../../store/selectedShareholderTypeStore";
import { PrimaryCTAButton } from "../quickRound/CTAButtonComponents";
import { useGetShareholderOverview } from "../../queries/shareholders";
import AddShareholderProfile from "../../modals/AddShareholderProfile";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { useCanUserAccess } from "../../store/useRoleGrantStore";
import { ShareholderDataModel } from "../../types/Shareholder";
import { sort } from "../../utils/arrays";
import ShareholderRightsTable from "./ShareholderRights";
import { SwitchDropDownModel } from "../rounds/RoundsAllTransactions";
import { useInternalSwitchStateStore } from "../../store/searchDropDown";
import AGGridShareholderTable from "./ShareholderAgTables";
import PercentageCardDesign from "../../components/PercentageCardDesign";
import { getShareholderLabelAndValue } from "../../constants/ShareholderConstants";
import Loader from "../../components/shared/Loader";
import { handleEventForTracking } from "../../amplitudeAnalytics";

export default function ShareholdersOverviewPage() {
  const currencySymbol = getCurrencySymbol();
  const currencyType = getCurrencyType();
  const shareholderFilter = useSelectedShareholderFilterStore();
  const { isPlaceholderData, data: shareholdersData } =
    useGetShareholderOverview();
  const transactionSwitchStore = useInternalSwitchStateStore();
  const canUserCreateShareholder = useCanUserAccess("create", "shareholder");

  const shareholderTypes = isPlaceholderData
    ? [{ label: "All", value: "All" }]
    : [
        { label: "All", value: "All" },
        ..._.uniq(
          shareholdersData?.map((shareholder) =>
            getShareholderLabelAndValue(shareholder.type)
          )
        ),
      ];
  const securityTypes: string[] = ["All"];

  const [securityTypesList, setSecurityTypeList] = useState<string[]>([]);

  const extractData = () => {
    const dropDownOptions: SwitchDropDownModel[] = [];
    shareholdersData?.forEach((shareholder) => {
      dropDownOptions.push({
        name: shareholder.name,
        type: shareholder.type,
        id: shareholder.id,
      });
      securityTypes.push(...shareholder.securityTypes);
    });
    setSecurityTypeList(securityTypes);
    transactionSwitchStore.setShareholders(dropDownOptions);
  };

  useEffect(() => {
    if (!isPlaceholderData) extractData();
  }, [shareholdersData]);

  const navigate = useNavigate();
  const shareholders = useMemo(() => {
    if (!shareholdersData) return [];
    let shareholdersFilteredList: ShareholderDataModel[] = [];
    if (
      shareholderFilter.shareholderType === "All" &&
      shareholderFilter.securityType === "All"
    )
      shareholdersFilteredList = shareholdersData;
    else if (shareholderFilter.shareholderType === "All") {
      shareholdersFilteredList = shareholdersData?.filter((shareholder) =>
        shareholder.securityTypes.includes(shareholderFilter.securityType)
      );
    } else if (shareholderFilter.securityType === "All") {
      shareholdersFilteredList = shareholdersData?.filter((shareholder) =>
        shareholder.type.includes(shareholderFilter.shareholderType)
      );
    } else {
      shareholdersFilteredList =
        shareholdersData?.filter(
          (shareholder) =>
            shareholder.type.includes(shareholderFilter.shareholderType) &&
            shareholder.securityTypes.includes(shareholderFilter.securityType)
        ) || [];
    }

    const sortResult = sort(
      shareholdersFilteredList,
      "holdingPercentage",
      false
    );
    return sortResult;
  }, [
    shareholdersData,
    shareholderFilter.shareholderType,
    shareholderFilter.securityType,
  ]);

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    mode?: "Add Shareholder Profile";
  }>({
    open: false,
  });

  return (
    <div className={isPlaceholderData ? "loading min-h-full" : "min-h-full"}>
      <VStack className="gap-6">
        <Dialog open={dialog.open} maxWidth="md">
          {dialog.mode === "Add Shareholder Profile" ? (
            <AddShareholderProfile
              onPrimaryAction={() => {
                setDialog({ open: false });
              }}
              onSecondaryAction={() => {
                setDialog({ open: false });
              }}
              mode="Add"
            />
          ) : (
            <div></div>
          )}
        </Dialog>
        <HStack className="justify-between items-center">
          <BCHeader className="items-baseline" bcTitle="Shareholders" />
          {canUserCreateShareholder && (
            <PrimaryCTAButton
              className="text-xs2 h-8 whitespace-nowrap"
              event-name="Add Shareholder Profile"
              onClick={() => {
                setDialog({
                  open: true,
                  mode: "Add Shareholder Profile",
                });
              }}
            >
              New Shareholder
            </PrimaryCTAButton>
          )}
        </HStack>
        <HStack className="overflow-x-scroll scrollbar-hide gap-8">
          {shareholders &&
            shareholders.map((shareholder) => (
              <PercentageCardDesign
                key={shareholder.id}
                data={{
                  name: shareholder.name?.toLowerCase()?.includes("esop")
                    ? shareholder.name?.replace(/esop/gi, "ESOP")
                    : shareholder.name,
                  type: shareholder.type,
                  percentage: shareholder.holdingPercentage,
                  keyValue: [
                    {
                      key: "No. of Securities",
                      value:
                        shareholderFilter.securityType !== "All"
                          ? shareholder.sharesPerSecurity.find(
                              (security) =>
                                security.security ===
                                shareholderFilter.securityType
                            )?.shares || shareholder.shares
                          : shareholder.shares,
                      isAmount: false,
                    },
                    {
                      key: "Net Worth",
                      value: shareholder.networth,
                      isAmount: true,
                    },
                  ],
                }}
                onClick={() => {
                  navigate(`/shareholders/individual/${shareholder.id}`);
                }}
                currencySymbol={currencySymbol}
                currencyType={currencyType}
                type="Shareholder"
              />
            ))}
        </HStack>
        {isPlaceholderData ? (
          <Loader />
        ) : (
          <AGGridShareholderTable
            isPreviewOnly={true}
            shareholderData={shareholders || []}
          />
        )}
      </VStack>
    </div>
  );
}
